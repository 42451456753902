import { create } from "@/d3";
import { EARTH_RADIUS } from "@/modules/rendersources/RenderSource";
import { Coord, UEC } from "@/modules/tile";
import { Vec2 } from "@/modules/vecmat";
import { FormattingService } from "./FormattingService";
import { ScreenPosition } from "./PositionService";
import { Services } from "./Services";

const svgns = "http://www.w3.org/2000/svg";

export function screen_pos_of_uec_and_height_earth_radii(pos: UEC, height: number): Vec2{
    let screen_pos: ScreenPosition = Services.PositionService.UECHeightToScreenPixels(pos, height);
    return new Vec2(screen_pos.x, screen_pos.y);
}

export function create_svg_line(parent: HTMLElement, stroke: string, strokeWidth: string, id = null){
    let element = document.createElementNS(svgns, "line");
    element.setAttributeNS(null, "x1", "300");
    element.setAttributeNS(null, "y1", "300");
    element.setAttributeNS(null, "x2", "400");
    element.setAttributeNS(null, "y2", "300");
    element.style.stroke = stroke;
    element.style.strokeWidth = strokeWidth;
    if(id){
        element.setAttribute("id", id);
    }
    parent.appendChild(element);
    return element;
}

export function create_svg_text(parent: HTMLElement, text: string, stroke: string, id = null, fontsize: string = "20px", font: string = "sans-serif"){
    let element = document.createElementNS(svgns, "text");
    element.innerHTML = text;
    element.setAttributeNS(null, "x", "300");
    element.setAttributeNS(null, "y", "300");
    element.setAttributeNS(null, "text-anchor", "middle");
    element.setAttributeNS(null, "dominant-baseline", "middle");
    element.style.stroke = stroke;
    element.style.fill = stroke;
    element.style.font = fontsize + " " + font;
    if(id){
        element.setAttribute("id", id);
    }
    parent.appendChild(element);
    return element;
}

export function move_svg_line(line: SVGLineElement, start: Vec2, end: Vec2){
    line.setAttributeNS(null, "x1", start.x1.toString());
    line.setAttributeNS(null, "y1", start.x2.toString());
    line.setAttributeNS(null, "x2", end.x1.toString());
    line.setAttributeNS(null, "y2", end.x2.toString());
}

export function move_svg_text(text: SVGTextElement, position: Vec2){
    text.setAttributeNS(null, "x", position.x1.toString());
    text.setAttributeNS(null, "y", position.x2.toString());
}

function axis_name_to_descriptor(name: "x"|"y"|"z"): string{
    switch(name){
        case "x": return "E";
        case "y": return "S";
        case "z": return "Z";
        default: return name;
    }
}

class Axis{
    public center: Vec2;
    public end: Vec2;
    public breaks: Vec2[] = [];
    public orthogonal_unit: Vec2;
    public kind: "x"|"y"|"z";
    private base_line_elem: SVGLineElement;
    private tick_elems: SVGLineElement[] = [];
    private end_tick_elem: SVGLineElement;
    private name_text: SVGTextElement;
    private distance_text: SVGTextElement;

    private current_exponent: number = 1;

    public tick_len = 10;

    constructor(kind: "x"|"y"|"z", parent_svg: HTMLElement, stroke: string){
        this.center = new Vec2(0,0);
        this.end = this.center.add(new Vec2(10,0));
        for(let i = 1; i < 10; i++){
            this.breaks.push(this.center.add(new Vec2(i,0)));
        }
        this.kind = kind;
        this.base_line_elem = create_svg_line(parent_svg, stroke, "3px", kind + "_base");
        this.end_tick_elem = create_svg_line(parent_svg, stroke, "3px", kind + "_end");
        for(let i = 1; i < 10; i++){
            this.tick_elems.push(create_svg_line(parent_svg, stroke, "1px", kind + "_tick_" + i));
        }
        this.name_text = create_svg_text(parent_svg, axis_name_to_descriptor(kind), stroke, kind + "_name_txt");
        this.distance_text = create_svg_text(parent_svg, "1m", stroke, kind + "_distance_txt", "15px");
    }

    private offset_screen_position_by_value_and_exponent(pos: UEC, height: number, value: number, exponent: number): Vec2{
        switch(this.kind){
            case "x":
                return screen_pos_of_uec_and_height_earth_radii(pos.move_by_meters(value * Math.pow(10, exponent), 0), height);
            case "y":
                return screen_pos_of_uec_and_height_earth_radii(pos.move_by_meters(0, value * Math.pow(10, exponent)), height);
            case "z":
                return screen_pos_of_uec_and_height_earth_radii(pos, height + ((value * Math.pow(10, exponent))/ EARTH_RADIUS))
        }
    }

    public recompute(pos: UEC, height: number, exponent: number){
        this.center = screen_pos_of_uec_and_height_earth_radii(pos, height);
        for(let i = 1; i < 10; i++){
            this.breaks[i-1] = this.offset_screen_position_by_value_and_exponent(pos, height,i,exponent);
        }
        this.end = this.offset_screen_position_by_value_and_exponent(pos, height, 10, exponent);
        this.orthogonal_unit = this.end.sub(this.center).norm().orthogonal();
        this.current_exponent = exponent;
    }

    public end_in_bounds(width: number, height: number, margin: number){
        return (
            this.end.x1 > margin
            && this.end.x1 < (width - margin)
            && this.end.x2 > margin
            && this.end.x2 < (height - margin)
        );
    }

    public update_lines(){
        move_svg_line(this.base_line_elem, this.center, this.end);
        move_svg_line(this.end_tick_elem, this.end.sub(this.orthogonal_unit.mul(this.tick_len / 2)), this.end.add(this.orthogonal_unit.mul(this.tick_len / 2)));
        for(let i = 1; i < 10; i++){
            move_svg_line(this.tick_elems[i-1], this.breaks[i-1].sub(this.orthogonal_unit.mul(this.tick_len / 2)), this.breaks[i-1].add(this.orthogonal_unit.mul(this.tick_len / 2)));
        }
        const end_text_distance = 15;
        move_svg_text(this.name_text, this.end.add(this.end.sub(this.center).norm().mul(end_text_distance)));
        let len = FormattingService.num_to_string_unit_si(10 * Math.pow(10, this.current_exponent), "m", 0);
        move_svg_text(this.distance_text, this.end.add(this.end.sub(this.center).norm().mul(end_text_distance * 3)));
        this.distance_text.innerHTML = len;

    }

}

export class ScaleIndicatorService{
    scale_indicator_visible: boolean = false;

    fill_style = "rgba(255,255,255,0.7)";
    margin = 50;

    svg_element: HTMLElement;
    center_circle: SVGCircleElement;

    current_exponent = 1;
    x_axis: Axis;
    y_axis: Axis;
    z_axis: Axis;


    private are_axis_ends_in_bounds(width: number, height: number, margin: number = 50){
        return (
            this.x_axis.end_in_bounds(width,height,margin) 
            && this.y_axis.end_in_bounds(width,height,margin)
            && this.z_axis.end_in_bounds(width,height,margin)
        );
    }

    

    private recompute_ends(center_uec: UEC, center_height: number){
        this.x_axis.recompute(center_uec, center_height,this.current_exponent);
        this.y_axis.recompute(center_uec, center_height,this.current_exponent);
        this.z_axis.recompute(center_uec, center_height,this.current_exponent);
    }

    constructor(){
        this.svg_element = document.getElementById("scale-svg");

        this.x_axis= new Axis("x", this.svg_element, "rgba(255,127,127,0.8)");
        this.y_axis = new Axis("y", this.svg_element, "rgba(127,255,127,0.8)");
        this.z_axis = new Axis("z", this.svg_element, "rgba(127,127,255,0.8)");

        this.center_circle = document.createElementNS(svgns, "circle");
        this.center_circle.setAttributeNS(null, "cx", "300");
        this.center_circle.setAttributeNS(null, "cy", "300");
        this.center_circle.setAttributeNS(null, "r", "5");
        this.center_circle.style.fill = this.fill_style;
        this.svg_element.appendChild(this.center_circle);

        let camera_position = Services.PositionService.getCameraPositionFiltered();
        let uec_camera_pos = UEC.from_Coord(new Coord(camera_position.Latitude, camera_position.Longitude));
        let camera_height = camera_position.VerticalPosition;
        this.recompute_ends(uec_camera_pos, camera_height);

        Services.GLService.addEventListener("FrameDone", () => {
            if(!this.scale_indicator_visible){
                return;
            }
            //Set the center circle
            let frame_bbox = this.svg_element.getBoundingClientRect();
            let camera_position = Services.PositionService.getCameraPositionFiltered();
            let uec_camera_pos = UEC.from_Coord(new Coord(camera_position.Latitude, camera_position.Longitude));
            let camera_height = camera_position.VerticalPosition;
            let pixel_pos: ScreenPosition = Services.PositionService.UECHeightToScreenPixels(uec_camera_pos, camera_height);
            this.center_circle.setAttributeNS(null, "cx", pixel_pos.x.toString());
            this.center_circle.setAttributeNS(null, "cy", pixel_pos.y.toString());
            //Recompute where the ends go
            this.recompute_ends(uec_camera_pos, camera_height);
            //Try out a new exponent
            //shrink to fit
            if(!this.are_axis_ends_in_bounds(frame_bbox.width, frame_bbox.height, this.margin)){
                this.current_exponent --;
                this.recompute_ends(uec_camera_pos, camera_height);
            }
            //try one bigger!
            if(this.current_exponent < 7){
                this.current_exponent ++;
                this.recompute_ends(uec_camera_pos, camera_height);
                if(!this.are_axis_ends_in_bounds(frame_bbox.width, frame_bbox.height, this.margin)){
                    this.current_exponent --;
                    this.recompute_ends(uec_camera_pos, camera_height);
                }
            }


            //Move axes
            this.x_axis.update_lines();
            this.y_axis.update_lines();
            this.z_axis.update_lines();

        });
    }

    set_visibility(visible: boolean){
        this.scale_indicator_visible = visible;
        if(visible){
            this.svg_element.style.opacity="1";
        }else{
            this.svg_element.style.opacity="0";
        }
    }
}