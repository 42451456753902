<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="sourcestatus">
        <table>
            <thead>
                <tr><td>Source</td><td>Status</td></tr>
            </thead>
            <tbody>
                <tr v-for="status in status_list" :key="status.name">
                    <td>{{status.name}}</td>
                    <td>
                        <span v-if="status.status == 'Error'" class="status-error">
                            Error: {{status.error_message}}
                        </span>
                        <span v-else>
                            {{status.status}}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import { FormattingService } from '../services/FormattingService';
import { ServiceBarrier, Services } from '../services/Services';
import {SourceStatus} from '../services/SourceInfoService';
@Component({
    components: {
        SvgIcon
    },
    props:{}
})
export default class ProgramStatusComponent extends Vue{    
    public status_list: SourceStatus[] = [];
    mounted(){
        this.status_list = Services.SourceInfoService.get_all_status_infos();
    }
}
</script>

<style lang="scss" scoped>
    @import '../_guikit.scss';
    .status-error{
        color: red;
    }
</style>