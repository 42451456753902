//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { LayerFilter } from "./RenderFilter";
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { CompositionPipelineFramebuffer } from "../PipelineFramebuffer";
import { Vec3 } from "../vecmat";

export class HillShadingFilter extends LayerFilter{
    constructor(){
        super(Services.GLService.Modules.effects.hill_shading, {
            "light_direction": Services.SettingsService.getSetting("Light Direction"),
            "shading_intensity": new Parameter("Shading Intensity", 0.5, "number", true, false).setShaderName("shading_intensity").setRange(0, 1),
            "shading_falloff": new Parameter("Shading Falloff", 0.0, "number", true, false).setShaderName("shading_falloff").setRange(-1, 1),
            "brightness": new Parameter("Brightness", 1.0, "number", true, false).setShaderName("brightness").setRange(0, 5)
        });
        this.human_readable_name = "Hill Shading";
        this.name = "HillShading";
    }

    execute(context: {[name: string]: WebGLRenderingContext | any}, source: any){ //TODO fix signature
        let tex_unit_index = super.preexecute(context);
        let gl = context.gl;
        if(Services.PositionService.light_locked){
            let cam = Services.PositionService.getCameraPositionFiltered();
            let clat = cam.Latitude / 180 * Math.PI;
            let clon = cam.Longitude / 180 * Math.PI;
            let caz = cam.Azimuth / 180 * Math.PI;

            let w_normal = new Vec3(
                Math.cos(clon) * Math.cos(clat),
                Math.sin(clon) * Math.cos(clat),
                Math.sin(clat)
            );
        
            let w_tangent = new Vec3(
                -Math.cos(clon) * Math.sin(clat),
                -Math.sin(clon) * Math.sin(clat),
                Math.cos(clat)
            );
            
            let w_cotangent = new Vec3(
                -Math.sin(clon),
                Math.cos(clon),
                0
            );
            let w_tangent_2 = w_tangent.mul(Math.cos(caz)).add(w_cotangent.mul(-Math.sin(caz)));
            let w_cotangent_2 = w_tangent.mul(Math.sin(caz)).add(w_cotangent.mul(Math.cos(caz)));

            let ld_param_val = this.parameters["light_direction"].value;
            let light_dir = w_normal.mul(ld_param_val.x1).add(w_tangent_2.mul(ld_param_val.x3)).add(w_cotangent_2.mul(ld_param_val.x2));

            gl.uniform3f(this.shader.uniforms["light_direction"], light_dir.x1, light_dir.x2, light_dir.x3)
        }
        gl.depthFunc(gl.ALWAYS);

        if(this.shader.uniforms["screen_resolution"]){
            gl.uniform2f(this.shader.uniforms["screen_resolution"], context.gl.canvas.width, context.gl.canvas.width);
        }
        if(this.shader.uniforms["value_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.value);
            gl.uniform1i(this.shader.uniforms["value_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["depth_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.depth);
            gl.uniform1i(this.shader.uniforms["depth_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["normal_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.normal);
            gl.uniform1i(this.shader.uniforms["normal_map"], tex_unit_index);
            tex_unit_index++;
        }
        if(this.shader.uniforms["worldPosition_map"]){
            gl.activeTexture(gl.TEXTURE0 + tex_unit_index);
            gl.bindTexture(gl.TEXTURE_2D, source.worldPos);
            gl.uniform1i(this.shader.uniforms["worldPosition_map"], tex_unit_index);
            tex_unit_index++;
        }

        let geometry = Services.GLService.Geometries.quad;
        gl.enableVertexAttribArray(this.shader.attributes["position"]);
        gl.bindBuffer(gl.ARRAY_BUFFER, geometry.buffer);
        gl.vertexAttribPointer(this.shader.attributes["position"], 2, gl.FLOAT, false, 0, 0);
        gl.drawArrays(gl.TRIANGLE_STRIP, geometry.start, geometry.length);
        gl.disableVertexAttribArray(this.shader.attributes["position"]);
        gl.depthFunc(gl.LESS);
    }
}