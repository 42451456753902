//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { SourceLayerInfo } from "../../services/SourceInfoService";
import { Services } from "../../services/Services";
import { Mat4 } from "../vecmat";
import { UECArea } from "../tile";

export const EARTH_RADIUS = 6378000.0;

export type RenderSourceSlotType = "tile" | "points" | "lines";

export class RenderSourceSlot{
    name: string;
    shaderName: string;
    source: SourceLayerInfo;
    type: RenderSourceSlotType = "tile";
    mixing: Mat4;

    constructor(name: string, shaderName:string, source: SourceLayerInfo, type: RenderSourceSlotType, mixing: Mat4){
        this.name = name;
        this.shaderName = shaderName;
        this.source = source;
        this.type = type;
        this.mixing = mixing;
    }
}

export class RenderSource{
    name: string;
    slots: {[name: string]: RenderSourceSlot};
    shaders: {[name: string]: {program:  WebGLProgram, uniforms: {[name: string]: WebGLUniformLocation}, attributes: {[name: string]: number}}};
    shader: {program:  WebGLProgram, uniforms: {[name: string]: WebGLUniformLocation}, attributes: {[name: string]: number}};
    parameters: {[name:string]: Parameter} = {};
    slot_order: string[];

    constructor(){}

    getSourceInfos(): SourceLayerInfo[]{
        let slis = new Map();
        for(let s in this.slots){
            if(this.slots[s].source){
                slis.set(s, this.slots[s].source);
            }
        }
        if(this.slot_order){
            let ordered_slis = [];
            for(let order of this.slot_order){
                if(slis.has(order)){
                    ordered_slis.push(slis.get(order));
                }
            }
            return ordered_slis;
        }else{
            return [... slis.values()];
        }

    }

    getVerticalBoundsWorldSpace(): [number, number]{
        return [1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        return [1, 1];
    }

    getExtent(): UECArea{
        return null;
    }

    getTimeRange(): [number, number]{
        let e_layers: [number, number][] = Object.values(this.slots).filter((v: RenderSourceSlot) =>{
            return v.source && v.source.layer && v.source.layer.timerange;
        }).map(v => v.source.layer.timerange)
        if (e_layers.length) return e_layers.reduce((p: [number, number], v: [number, number]) => {
            return [Math.min(p[0], v[0]), Math.max(p[1], v[1])];
        });
    }

    applyScaling(val: number): number {
        throw("RenderSource.applyScaling must be overridden!");
    }

    applyOffset(val: number): number {
        throw("RenderSource.applyOffset must be overridden!");
    }

    /*
     * Without doing ANY GL stuff, tell the stitchedtilesservice what stitched tiles you will need so they can be made 
     * available before rendering.
     */
    requestStitchedTiles() {
    }

    /*
     * Only put truly universal operations that are shared between ALL rendersources here.
     */
    execute(context: {[name: string]: WebGLRenderingContext | any}) {
        this.shader = this.shaders[Services.PositionService.projection_mode];
        context.gl.useProgram(this.shader.program);
        context.gl.uniformMatrix4fv(this.shader.uniforms["projectionMatrix"], false, Services.PositionService.camera_transform.as_typed());
        context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.as_typed());
        Object.values(this.parameters).forEach(p => {
            let uloc = this.shader.uniforms[p.shader_name];
            if(!uloc)return;
            switch (p.type) {
                case "number": {
                    context.gl.uniform1f(uloc, p.value);
                    break;
                }
                case "boolean": {
                    context.gl.uniform1i(uloc, p.value);
                    break;
                }
                case "vector2D": {
                    context.gl.uniform2f(uloc, p.value.x1, p.value.x2);
                    break;
                }
                case "vector3D": {
                    context.gl.uniform3f(uloc, p.value.x1, p.value.x2, p.value.x3);
                    break;
                }
            }
        });
    };
}