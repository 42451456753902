<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="save-container" @click.stop="">
        <div title="Open Scene..." class="button" @click="uploadLayerConfig"><SvgIcon name="open"></SvgIcon><span>Open Scene File</span></div>
        <input type="file" style="display: none" accept=".json" @change="handleLayerConfig" id="inputlayerfile" />
        <div title="Save Scene..." class="button" @click="downloadLayerConfig"><SvgIcon name="save"></SvgIcon><span>Save Scene File</span></div>
        <hr style="width:80%;">
        <div>Enter a scene name:</div>
        <input type="text" v-model="SceneName" title="A keyword which is to be included in the URL generated">
        <button @click="createLink" title="Create a link to this display configuration">Generate Link</button>
        <a :href="SceneLink" target="_blank" title="A link to the current display configuration">{{SceneLink}}</a>
        <div class="button copy" v-show="SceneLink != ''" title="Copy the link to the clipboard" @click="copyLinkToClipboard" :copySuccessful="CopySuccessful">Copy to Clipboard</div>
        <span style="color: #ff0000" title="Something went wrong. Maybe send this error message to digitalearthviewer@geomar.de?">{{SceneError}}</span>
    </div>
</template>

<style lang="scss" scoped>
@import '../_guikit.scss';
    #save-container{
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    #save-container>.button{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #save-container>.button>.svg-icon-container{
        flex-grow: 0;
        flex-basis: content;
    }

    #save-container>.button>span{
        flex-grow: 1;
        margin-left: 0.25em;
    }

</style>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import ScrollBox from './guikit/scrollbox.vue';
import { Services } from '../services/Services';

@Component({
components: {
    SvgIcon,
    ScrollBox
}
})
export default class SaveDialogue extends Vue{
    downloadLayerConfig(){
        let renderlayers = Services.SerializationService.getSceneString();
        let renderlayertext = "data:application/json;base64," + btoa(renderlayers);
        let a_elem = document.getElementById('downloadlink') as HTMLAnchorElement;
        a_elem.setAttribute("download", "initial_layers.json");
        a_elem.setAttribute("href", renderlayertext);
        a_elem.click();
    }

    uploadLayerConfig(){
        document.getElementById('inputlayerfile').click();
    }

    async handleLayerConfig(e){
        let input: any = document.getElementById('inputlayerfile');
        let file: File = input.files[0];
        if(file){
            let resp = await new Response(file).json();
            try{
                await Services.SerializationService.deserializeScene(resp);
            }catch(e){
                alert("Layer config could not be loaded: " + e);
            }
        }
    }

    //Scene name, link creation
    private SceneName = "scene";
    private SceneError = "";
    private SceneLink = "";
    private CopySuccessful = false;
    async createLink(){
        let renderlayers = Services.SerializationService.getSceneString();
        try{
            let result = await fetch(`create_scene/${encodeURIComponent(this.SceneName)}`, {
                method: "POST",
                body: renderlayers
            }).then(r => r.text());
            this.SceneLink = window.location.origin + "?" + result;
            Services.InitializationService.SceneName = result;
        }catch(e){
            this.SceneError = e.message;
        }
    }
    private copyLinkToClipboard(){
        navigator.clipboard.writeText(this.SceneLink).catch((e) => this.SceneError = e).then(() => {
            this.CopySuccessful = true;
            setTimeout(() => this.CopySuccessful = false, 1000);
        });
    }
}
</script>