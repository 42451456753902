import { SceneConfig } from "./SerializationService";
import { CameraPosition, CameraPositionTargetChangedEvent, PositionService } from "./PositionService";
import { ServiceBarrier, Services } from "./Services";
import { SettingsChangedEvent } from "./SettingsService";
import { CurrentTimeChangedEvent } from "./TimeService";

export interface SynchronizationMessage{
    type: string,
    data: object
}

export interface CameraPositionMessage extends SynchronizationMessage {
    type: "CameraPosition";
    data:{
        position: CameraPosition
    };
}

export interface TimeRangeMessage extends SynchronizationMessage {
    type: "TimeRange",
    data:{
        min: number,
        max: number
    }
}

export interface ReloadMessage extends SynchronizationMessage{
    type: "Reload",
    data: {}
}

export interface JoinedMessage extends SynchronizationMessage{
    type: "Joined",
    data: {}
}

export interface SceneMessage extends SynchronizationMessage{
    type: "Scene",
    data: {
        scene: SceneConfig
    }
}

export interface GetSceneMessage extends SynchronizationMessage{
    type: "GetScene",
    data: {}
}

export interface SettingMessage extends SynchronizationMessage{
    type: "Setting",
    data: {
        name: string,
        value: any
    }
}

export class SynchronizationMessageEvent extends Event{
    public message: SynchronizationMessage;
    constructor(message: SynchronizationMessage){
        super("SynchronizationMessage");
        this.message = message;
    }
}

export class SynchronizationService extends EventTarget{
    websocket: WebSocket = null;
    lastMessagesByType = {
        "Reload": new Date().getTime(),
        "TimeRange": new Date().getTime(),
        "CameraPosition": new Date().getTime(),
        "Joined": new Date().getTime(),
        "Scene": new Date().getTime()
    }
    constructor(){
        super();
        ServiceBarrier.wait().then(() => {
            Services.PositionService.addEventListener("CameraPositionTargetChanged", (pos: CameraPositionTargetChangedEvent) => {
                let message: CameraPositionMessage = {
                    type: "CameraPosition",
                    data: {
                        position: pos.position
                    }
                };
                this.dispatchEvent(new SynchronizationMessageEvent(message));
            });


            Services.TimeService.addEventListener("CurrentTimeChanged", (time_event: CurrentTimeChangedEvent) => {
                if(time_event.external){
                    return;
                }
                let message: TimeRangeMessage = {
                    type: "TimeRange",
                    data: {
                        min: time_event.time_min,
                        max: time_event.time_max
                    }
                };
                this.dispatchEvent(new SynchronizationMessageEvent(message));
            })

            const ALLOWED_SETTINGS = ["DomeTilt", "ClearColor", "Exaggeration", "Light Direction"];
            Services.SettingsService.addEventListener("SettingsChanged", (e: SettingsChangedEvent) => {
                if(e.remote)return;
                if(ALLOWED_SETTINGS.includes(e.parameter.name)){
                    let message: SettingMessage = {
                        type: "Setting",
                        data: {
                            name: e.parameter.name,
                            value: e.parameter.value
                        }
                    };
                    this.dispatchEvent(new SynchronizationMessageEvent(message));
                }
            });

            fetch("synchronized").then(r => r.json()).then(v => {
                if(v == true){
                    console.log("Server says to start sync");
                    setTimeout(() => this.tryStart(), 100);
                }
            });

        });
    }

    public stop(){
        console.log("Stopping sync");
        if(this.websocket != null){
            this.websocket.close();
            console.log("Stopped sync");
        }
    }

    private sendSocket(message: SynchronizationMessage){
        if(this.websocket && this.websocket.readyState == WebSocket.OPEN)
        this.websocket.send(JSON.stringify(message));
    }

    public syncScene(){
        let message: SceneMessage = {
            type: "Scene",
            data: {
                scene: Services.SerializationService.serializeScene()
            }
        };
        //console.log("sync", message);
        this.dispatchEvent(new SynchronizationMessageEvent(message));
    }

    public async handleIncomingMessage(message: SynchronizationMessage, rebroadcast: boolean = false) {
        try{
            //console.log(message);
            let messagetype = message.type;
            //console.log("Received message of type ", messagetype);
            switch (messagetype){
                case "Reload":
                    (()=>{
                        let t = new Date().getTime();
                        console.log("Since last reload: ", t - this.lastMessagesByType["Reload"]);
                        if(t - this.lastMessagesByType["Reload"] > 15000){
                            window.location.reload();
                        }
                    })();
                break;
                case "Joined":
                    (() => {
                        let t = new Date().getTime();
                        if(t - this.lastMessagesByType["CameraPosition"] > 1000){
                            let posmsg: CameraPositionMessage = {
                                type: "CameraPosition",
                                data: {
                                    position: Services.PositionService.getCameraPosition()
                                }
                            };
                            this.sendSocket(posmsg); //These do not need to be thrown as events, but maybe they could be replaced by a master switch?
                        }
                        if(t - this.lastMessagesByType["TimeRange"] > 1000){
                            let trange = Services.TimeService.getCurrentTimeRange();
                            let tmsg: TimeRangeMessage = {
                                type: "TimeRange",
                                data: {
                                    min: trange[0],
                                    max: trange[1]
                                }
                            }
                            this.sendSocket(tmsg);
                        }
                    })();
                break;
                case "GetScene": 
                    (() => {
                        //console.log("Received GetScene request")
                        let t = new Date().getTime();
                        if(t - this.lastMessagesByType["Scene"] > 1000){
                            this.syncScene();
                            this.lastMessagesByType["Scene"] = new Date().getTime();
                        }
                    })();
                break;
                case "CameraPosition":
                    (() => {
                        let positionMessage = message as CameraPositionMessage;
                        Services.PositionService.setCameraPosition(positionMessage.data.position, true);
                        Services.AdaptivePerformanceService.RequestRerender();
                    })();
                break;
                case "TimeRange":
                    (() => {
                        let timeMessage = message as TimeRangeMessage;
                        Services.TimeService.setCurrentTimeRange(timeMessage.data.min, timeMessage.data.max, false, true);
                        Services.AdaptivePerformanceService.RequestRerender();
                    })();
                break;
                case "Scene": {
                    await (async () => {
                        let sceneMessage = message as SceneMessage;
                        console.log("Received new scene", sceneMessage.data);
                        await Services.SerializationService.deserializeScene(sceneMessage.data.scene);
                        console.log("Loaded new scene");
                    })();
                    break;
                }
                case "Setting": {
                    (() => {
                        let settingMessage = message as SettingMessage;
                        console.log("Received setting ", settingMessage.data.name);
                        Services.SettingsService.getSetting(settingMessage.data.name).setValue(settingMessage.data.value, true);
                        Services.AdaptivePerformanceService.RequestRerender();
                    })();
                    break;
                }
                default: 
                    console.warn("Unknown synchronization message", message);
            }
            this.lastMessagesByType[messagetype] = new Date().getTime();
         }catch{}
         if(rebroadcast){
             this.sendSocket(message);
         }
    };

    public tryStart(){
        if(this.websocket != null){
            return;
        }
        if(Services.InitializationService.SceneName != ""){
            let SYNCSERVER_URL = Services.InitializationService.SERVER_URL.replace("http", "ws") + "/sync/" + Services.InitializationService.SceneName;
            console.log("Connecting to ", SYNCSERVER_URL);
            this.websocket = new WebSocket(SYNCSERVER_URL);
            this.websocket.onopen = () => {
                console.info("Synchronization websocket connected");
                let joinmsg: JoinedMessage = {
                    type: "Joined",
                    data: {}
                }
                this.sendSocket(joinmsg); //Not interesting to the recording component
            }

            this.websocket.onclose = () => {
                console.warn("Lost synchronization connection");
                this.websocket == null;
                setTimeout(this.tryStart, 100);
            }

            this.websocket.addEventListener("message", msg => {
                let message: SynchronizationMessage = JSON.parse(msg.data);
                this.handleIncomingMessage(message);
            })

            window.addEventListener("beforeunload", () => {
                let message: ReloadMessage = {
                    type: "Reload",
                    data: {}
                };
                this.sendSocket(message); //useless to throw this as an event, we're unloading anyways
            });

            this.addEventListener("SynchronizationMessage", (event: SynchronizationMessageEvent) => {
                this.sendSocket(event.message);
            });

        }
    }
}