<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="pad-top drop-bounds" v-if="group">
        <div class="layer-row" 
            :draggable="!isDefault"
            @dragstart="startDrag($event, group.id)"
            @dragenter="allowDrop"
            @dragover="allowDrop"
            @drop="drop($event, group.id, expanded)"
        >
            <div class="button symbol continue" @click="expanded = !expanded">
                <SvgIcon :name="expanded?'down':'right'"></SvgIcon>
            </div>
            <div v-if="!isDefault && group.children.length > 0" class="button symbol continue" @click="group.setVisibility(!group.getAnyVisible()); rerender()">
                <SvgIcon v-if="group.getAnyVisible()" name="eye"></SvgIcon>
                <SvgIcon v-else name="no-eye"></SvgIcon>
            </div>
            <div class="button symbol continue" @click="zoomToLayer(group)" @mouseleave="hideExtent()" @mouseenter="showExtent(group)" v-if="group.getExtent()">
                <SvgIcon name="marker"></SvgIcon>    
            </div>
            <input style="width: 60%;" type="text" v-model="group.name" class="layername continue" :key="group.id"/>
            <!--<div class="button symbol continue" @click="layerSettings(getLayer(id))">
                <SvgIcon name="sliders"></SvgIcon>
            </div>-->
            <div class="button symbol continue" @click="createLayer()">
                <SvgIcon name="layer-new"></SvgIcon>
            </div>
            <div class="button symbol continue" @click="createGroup()">
                <SvgIcon name="layer-group-new"></SvgIcon>
            </div>
            <div v-if="!isDefault" class="button symbol continue active-red" @click="groupDelete($event, group)">
                <SvgIcon name="close2"></SvgIcon>
            </div>
        </div>
        <div v-if="group.children.length > 0" v-show="expanded" class="group-children">
        <template v-for="id in group.children">
            <div class="layer-row pad-top drop-bounds" 
                v-bind:key="id" v-if="isLayer(id)"
                draggable="true"
                @dragstart="startDrag($event, id)"
                @dragenter="allowDrop"
                @dragover="allowDrop"
                @drop="drop($event, id)"
            >
                <div class="button symbol continue" @click="getLayer(id).visible = !getLayer(id).visible; rerender();">
                    <SvgIcon v-if="getLayer(id).visible" name="eye"></SvgIcon>
                    <SvgIcon v-else name="no-eye"></SvgIcon>
                </div>
                <div class="button symbol continue" @click="zoomToLayer(getLayer(id))" @mouseleave="hideExtent()" @mouseenter="showExtent(getLayer(id))" v-if="getLayer(id).source.getExtent()">
                    <SvgIcon name="marker"></SvgIcon>    
                </div>
                <input style="width: 60%;" type="text" :is-selected="isSelected(id)" v-model="getLayer(id).name" @click="selectLayer(getLayer(id))" class="layername continue" :key="id" />
                <div class="button symbol continue" @click="layerSettings(getLayer(id))">
                    <SvgIcon name="sliders"></SvgIcon>
                </div>
                <div class="button symbol continue active-red" @click="layerDelete(getLayer(id))">
                    <SvgIcon name="close2"></SvgIcon>
                </div>
            </div>
            <layer-group-component v-else-if="isGroup(id)" v-bind:key="id" :group="getGroup(id)" :isDefault="false"></layer-group-component>
        </template>
        </div>
        <div v-else v-show="expanded" class="layer-placehold" @dragenter="allowDrop" @dragover="allowDrop" @drop="drop($event, group.id, true)">Empty Group</div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { RenderLayer } from '../modules/RenderLayer';
import {LayersChangedEvent, RenderLayerService, SelectedLayerChangedEvent} from '../services/RenderLayerService'
import SvgIcon from './guikit/svgIcon.vue';
import ScrollBox from './guikit/scrollbox.vue';
import { Services } from '../services/Services';
import { Coord, UEC } from '../modules/tile';
import { DialogBox } from '../services/DialogBoxService';
import { RenderGroup } from '../modules/RenderGroup';

@Component({
    components: {
        SvgIcon,
        ScrollBox,
    },
    props: {
        group: RenderGroup,
        isDefault: Boolean
    },
    name: "layer-group-component"
})
export default class LayerGroupComponent extends Vue{
    expanded: boolean = false;

    mounted(){
        if(this.$props.isDefault) this.expanded = true;
        Services.RenderLayerService.addEventListener(
            "LayersChanged",
            (e: LayersChangedEvent) => {
                this.$forceUpdate();
            }
        );
    }

    allowDrop(ev: DragEvent) {
        ev.preventDefault()
    }

    startDrag(ev: DragEvent, id: number) {
        ev.dataTransfer.setData("text/x.plain", id + "");
        ev.dataTransfer.dropEffect = "move";
    }

    
    drop(ev: DragEvent, targetId: number, open: boolean = false) {
        let droppedIdString = ev.dataTransfer.getData("text/x.plain");
        if(!droppedIdString)return;
        let droppedId = parseInt(droppedIdString);

        if(Services.RenderLayerService.isGroup(targetId) && open){

            let source_group: RenderGroup = Services.RenderLayerService.groupForId(droppedId);
            
            if(droppedId == targetId)return;
            if(Services.RenderLayerService.isGroup(droppedId) && Services.RenderLayerService.getGroupById(droppedId).hasChild(targetId))return;
            source_group.remove(droppedId);
            
            let targetGroup: RenderGroup = Services.RenderLayerService.getGroupById(targetId);
            targetGroup.add(droppedId);

        }else{

            let source_group: RenderGroup = Services.RenderLayerService.groupForId(droppedId);
            let targetGroup: RenderGroup = Services.RenderLayerService.groupForId(targetId);
            if(droppedId == targetGroup.id)return;
            if(Services.RenderLayerService.isGroup(droppedId) && Services.RenderLayerService.getGroupById(droppedId).hasChild(targetGroup.id))return;
            source_group.remove(droppedId);

            let target_index = targetGroup.children.indexOf(targetId);

            // @ts-ignore 
            let el = ev.composedPath().find(el => el.classList.contains("drop-bounds"));
            if(el){
                // @ts-ignore 
                let rect = el.getBoundingClientRect();
                let yrel = ev.clientY - rect.y;
                console.log("rel pos: ", yrel);
                if(yrel > rect.height / 2) target_index += 1;
            }

            targetGroup.children.splice(target_index, 0, droppedId);
        }
        Services.RenderLayerService.raiseLayersChanged()
        ev.preventDefault()
    }

    isGroup(id: number): Boolean {
        return Services.RenderLayerService.isGroup(id)
    }

    isLayer(id: number): Boolean {
        return Services.RenderLayerService.isLayer(id)
    }

    getGroup(id: number): RenderGroup {
        return Services.RenderLayerService.groups.get(id)
    }

    getLayer(id: number): RenderLayer {
        return Services.RenderLayerService.layers.get(id)
    }

    isSelected(id: number): Boolean {
        return Services.RenderLayerService.selected_layer_id == id || Services.RenderLayerService.selected_group.id == id
    }

    zoomToLayer(layer: RenderLayer | RenderGroup){

        //BUG: when displacement is larger than data, will likely bound on displacement and not on data
        let extent = layer.getExtent();
        let center = new UEC(extent.position.x + (0.5 * extent.extent.x), extent.position.y + (0.5 * extent.extent.y));
        let coord = Coord.from_UEC(center);
        let position = Services.PositionService.getCameraPosition();
        position.Latitude = coord.lat;
        position.Longitude = coord.lon;
        position.Elevation = 45;
        position.Azimuth = 315;
        position.Distance = Math.max(extent.extent.x * 2 * Math.PI, extent.extent.y * Math.PI); //Works only on 45° fov
        if(position.Distance == 0){
            position.Distance = 0.1;
        }
        let zbounds = layer.getVerticalBoundsNative()
        if(zbounds)
            position.VerticalPosition = (zbounds[0] + zbounds[1]) / 2;
        Services.PositionService.setCameraPosition(position);
    }

    hideExtent(){
        Services.ExtentVisualizationService.hideExtent();
    }

    showExtent(layer: RenderGroup){
        Services.ExtentVisualizationService.showExtent(layer.getExtent(), layer.getVerticalBoundsWorldspace());
    }

    selectLayer(layer: RenderLayer){
        if(Services.RenderLayerService.getSelectedLayer() != layer){
            Services.RenderLayerService.selectLayer(layer);
            Services.AdaptivePerformanceService.RequestRerender();
        }
    }

    layerSettings(layer: RenderLayer){
        this.selectLayer(layer);
        Services.DialogBoxService.try_insert("layer-settings", new DialogBox("LayerComponent", window.innerWidth / 2, window.innerHeight / 2, 32, 32, "Layer Settings", "translate(-50%, -50%)"))
    }

    layerDelete(layer: RenderLayer){
        Services.RenderLayerService.deleteLayer(layer);
        Services.AdaptivePerformanceService.RequestRerender();
    }

    groupDelete(e: MouseEvent, group: RenderGroup){
        if(e.ctrlKey){
            Services.RenderLayerService.deleteGroup(group, true);
        } else {
            Services.RenderLayerService.deleteGroup(group, false);
        }
    }

    createGroup(){
        let newGroup = new RenderGroup("Unnamed Group");
        Services.RenderLayerService.addGroup(newGroup, this.$props.group.id);
    }

    createLayer(){
        Services.RenderLayerService.selectGroup(this.$props.group);
        Services.DialogBoxService.try_insert("New Layer", DialogBox.centered("TypeSelectionDialog", "Select Layer Type"));
    }

    rerender(){
        Services.AdaptivePerformanceService.RequestRerender();
    }
    
}
</script>

<style lang="scss">
@import '../_guikit.scss';

.layer-placehold {
    padding-top: $grid;
    text-align: center;
    opacity: 50%;
}

.group-children {
    margin-left: calc($grid * 1.5);
    padding-top: $grid;
}

.pad-top:not(:first-child) {
    padding-top: $grid;
}

.layer-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

input.layername{
    flex-grow: 1;
}
input.layername[is-selected]{
    color: $hl_color;
}

#add_layer_button{
    font-size: 80%;
}

#layers-button{
    @extend %generic_button;
    position: fixed;
    right: 0.1em;
    top: 12vh;
    z-index: 1000000;
    font-size: 3rem;
    font-weight: bold;
    transition: color 0.25s;
    line-height: 100%;
    border-radius: 0.25em;
    border: none;
    padding: 0;
    width: 1.2em;
    height: 1.2em;
}


#layers-card{
    @extend %generic_box;
    position: fixed;
    right: 0;
    top: 12vh;
    /*height: 50vh;*/
    z-index: 123;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: right 0.5s, opacity 3s;
    overflow-x: hidden;
}

#layers-card-header{
    width: 100%;
    display: block;
    padding-bottom: 0.5em;
    font-size: 150%;
}

#layers-title{
    font-size: 100%;
    line-height: 150%;
    vertical-align: middle;
    font-weight: bold;
}

#layers-card.hidden{
    right: calc(-100%);
}
#layers-card .card-body{
    padding: 0;
}

    #headertext:hover #ears{
        background: linear-gradient(135deg, rgba(0,0,0,0.66), rgba(0,0,0,0.66) 50%, rgba(0,0,0,0) 50.1%, rgba(0,0,0,0));
    }

    #ears{
        position: absolute;
        border-top-left-radius: 0.25rem;
        top: -1px;
        left: -1px;
        width: 10px;
        height: 10px;
        transition: background 0.5s;
        background: linear-gradient(135deg, rgba(0,0,0,0.01), rgba(0,0,0,0.01) 50%, rgba(0,0,0,0) 50.1%, rgba(0,0,0,0));
    }

    .selected-layer{
        color: $hl-color !important;
    }

</style>