//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

export class CameraService {

    constructor(){

    }

    getModelMatrix() {
        return null;
    }

    getCameraMatrix() {
        return null;
    }
}