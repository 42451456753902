//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { RenderGroup } from '@/modules/RenderGroup';
import { UEC, UECArea } from '@/modules/tile';
import {RenderLayer, RenderLayerFactory} from '../modules/RenderLayer';
import { ServiceBarrier, Services } from './Services';
import { SourceLayerInfo } from './SourceInfoService';

export class SelectedLayerChangedEvent extends Event{
    selectedLayer: RenderLayer;
    constructor(layer: RenderLayer){
        super("SelectedLayerChanged");
        this.selectedLayer = layer;
    }
}

export class LayersChangedEvent extends Event{
    layers: RenderLayer[];
    new_layer: RenderLayer;
    changed_layer: RenderLayer;
    deleted_layer: RenderLayer;
    constructor(layers: RenderLayer[]){
        super("LayersChanged");
        this.layers = layers;
    }
}

export class RenderLayerService extends EventTarget{
    public layers: Map<number, RenderLayer> = new Map();
    public groups: Map<number, RenderGroup> = new Map();
    public default_group: RenderGroup;
    public selected_group: RenderGroup;
    public selected_layer_id: number = null;
    public selected_layer: RenderLayer = null;

    private vertical_bounds_world_space: [number, number] = [1, 1];

    constructor(){
        super();
        this.default_group = new RenderGroup("Default", -1);
        this.selected_group = this.default_group;
        this.groups.set(this.default_group.id, this.default_group);
    }

    public addLayer(layer: RenderLayer, group?: RenderGroup){
        if(!group){
            group = this.selected_group;
        }
        if(layer.id == null){
            layer.id = Services.UIDService.getNextTopicId("layers");
        }
        this.layers.set(layer.id, layer);
        group.add(layer.id);
        this.raiseLayersChanged();
    }

    public addGroup(group: RenderGroup, parentGroupId?: number){
        this.groups.set(group.id, group);
        let parent_group = this.selected_group;
        if(parentGroupId){
            parent_group = this.groups.get(parentGroupId) || this.selected_group;
        }
        parent_group.add(group.id);
        this.raiseLayersChanged();
    }

    public replaceLayer(oldLayer: RenderLayer, newLayer: RenderLayer){
        newLayer.id = oldLayer.id;
        this.layers.set(oldLayer.id, newLayer);
        let is_selected = this.getSelectedLayer() == oldLayer;
        this.raiseLayersChanged();
        if(is_selected){
            this.selectLayer(newLayer);
        }
    }

    public selectLayer(layer: RenderLayer){
        this.selected_layer = layer;
        this.selected_layer_id = layer.id;
        this.dispatchEvent(new SelectedLayerChangedEvent(this.selected_layer));
    }
    
    public selectGroup(group: RenderGroup){
        this.selected_group = group;
    }

    public isGroup(id: number): boolean{
        return this.groups.has(id);
    }

    public isLayer(id: number): boolean{
        return this.layers.has(id);
    }

    public getGroups(): RenderGroup[]{
        return [...this.groups.values()];
    }

    public getLayers(): RenderLayer[]{
        return [...this.layers.values()];
    }

    //TODO: calculate actual world space peaks from all layers, ideally only on layer updates
    get_euclidian_global_height_range(): [number, number]{
        let r: [number, number] = this.getLayers().reduce((c, l) => {
            if(l.visible){
                let r = l.getVerticalBoundsWorldspace();
                return[Math.min(c[0], r[0]), Math.max(c[1], r[1])];
            }
            return c;
        }, [Infinity, -Infinity]);
        if(!isFinite(r[0]) || !isFinite(r[1]))return [0.9, 1.1];
        return r;
    }

    getGlobalHeightRanges(): [number, number]{
        let ranges: [number, number] = [Infinity, -Infinity];
        for(let l of this.getLayers()){
            if(l.source.slots.hasOwnProperty("displacement")){
                let slot = l.source.slots["displacement"];
                if(slot && slot.source && slot.source.layer && slot.source.layer.datarange){
                    let slot_zrange = slot.source.layer.datarange;
                    ranges[0] = Math.min(ranges[0], slot_zrange[0]);
                    ranges[1] = Math.max(ranges[1], slot_zrange[1]);
                }
            }else{
                for(let slot of Object.values(l.source.slots)){
                    if(slot && slot.source && slot.source.layer && slot.source.layer.zrange){
                        let slot_zrange = slot.source.layer.zrange;
                        ranges[0] = Math.min(ranges[0], slot_zrange[0]);
                        ranges[1] = Math.max(ranges[1], slot_zrange[1]);
                    }
                }
            }
        }
        return ranges;
        /*let r: [number, number] = this.layers.reduce((c, l) => {
            if(l.visible){
                let r = l.getVerticalBoundsNative();
                return[Math.min(c[0], r[0]), Math.max(c[1], r[1])];
            }
            return c;
        }, [Infinity, -Infinity]);
        if(!isFinite(r[0]) || !isFinite(r[1]))return [0.9, 1.1];
        return r;*/
    }

    getGlobalExtent(): UECArea{
        //let area = new UECArea(new UEC(0,0), new UEC(0,0));
        let area = this.selected_layer.getExtent();
        for(let l of this.getLayers()){
            if(l.getExtent() != null){
                area = UECArea.fromPoints([
                    area.topLeft(),
                    area.bottomRight(),
                    l.getExtent().topLeft(),
                    l.getExtent().bottomRight()
                ]);
            }
        }
        return area;
    }

    get_visible_renderlayers(): RenderLayer[] {
        return this.getLayers().filter(x => x.visible);
    }


    layerDown(layer: RenderLayer){
        this.groupForLayer(layer).down(layer.id);
    }

    layerUp(layer: RenderLayer){
        this.groupForLayer(layer).up(layer.id);
    }
   
    isFirstLayer(layer: RenderLayer){
        return this.groupForLayer(layer).isFirstLayer(layer.id);
    }

    isLastLayer(layer: RenderLayer){
        return this.groupForLayer(layer).isLastLayer(layer.id);
    }

    isSelectedLayer(layer: RenderLayer){
        return layer == this.selected_layer;
    }

    getSelectedLayer(): RenderLayer{
        return this.selected_layer;
    }

    setSourceOnLayer(layer: RenderLayer, source_slot_name: string, source: SourceLayerInfo){
        if(!layer)return;
        //Todo: Serialize layer, change slot path to what is in source, replace layer with deserialized layer.
        let serialized = Services.SerializationService.serializeRenderLayer(layer);
        let slot_index = serialized.slots.findIndex(s => s.slot == source_slot_name);
        if(slot_index >= 0){
            if(!!source){
                serialized.slots[slot_index].path = source.getPath();
            }else{
                serialized.slots.splice(slot_index,1); //Otherwise remove slot from serialized layer
            }
            let deserialized = Services.SerializationService.deserializeRenderLayer(serialized);
            //Reset colormap scaling if unit different
            for(let slot of serialized.slots){
                if(deserialized.source.slots[slot.slot].source?.layer?.unit != layer.source.slots[slot.slot].source?.layer?.unit){
                    let colormapfilter = deserialized.filterPipeline.find(f => f.name == "colormap");
                    if(colormapfilter){
                        colormapfilter.parameters["colormap"].executeAction("Reset");
                    }
                }
            }
            this.replaceLayer(layer, deserialized);
        }else{
            //console.log(`Slot ${source_slot_name} does not exist on layer ${layer.name}, options are`, serialized.slots.map(s => s.slot));
        }
    }

    deleteLayer(layer: RenderLayer){
        this.layers.delete(layer.id);
        if(this.layers.size == 0){
            this.selected_layer = null;
            this.selected_layer_id = null;
            this.dispatchEvent(new SelectedLayerChangedEvent(null));
        } else if(this.selected_layer.id == layer.id){
            this.selected_layer = this.layers[0];
            this.selected_layer_id = 0;
            this.dispatchEvent(new SelectedLayerChangedEvent(this.selected_layer));
        }
        this.groupForLayer(layer)?.remove(layer.id);
        this.raiseLayersChanged();
    }

    deleteGroup(group: RenderGroup, deleteContents: boolean = false){
        let parent = this.groupForId(group.id);
        if(!deleteContents){
            parent.remove(group.id);
            for(let id of group.children){
                parent.add(id);
            }
            this.groups.delete(group.id);
        }else{
            parent.remove(group.id);
            for(let g of group.getGroupInstances()){
                this.deleteGroup(g, true);
            }
            for(let l of group.getLayerInstances()){
                this.deleteLayer(l);
            }
            this.groups.delete(group.id);
        }
        this.raiseLayersChanged();
    }

    groupForLayer(layer: RenderLayer): RenderGroup{
        return this.getGroups().find(g => g.children.some(l => l == layer.id));
    }

    groupForId(id: number): RenderGroup{
        return this.getGroups().find(g => g.children.some(l => l == id));
    }

    getLayerById(id){
        return this.layers.get(id);
    }

    getGroupById(id){
        return this.groups.get(id);
    }

    raiseLayersChanged(){
        this.dispatchEvent(new LayersChangedEvent([...this.layers.values()]));
    }
}
