//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { ArrayData } from "../../services/TileCacheService";
import { UECArea } from "../tile";

//TODO fix coordinate readback

export class VectorPointRenderSource extends RenderSource {

    constructor() {
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.arrowSource;
        this.name = "VectorPointRenderSource";
        this.parameters = {
            "displacement_scale": Services.SettingsService.getSetting("Exaggeration"),
            "displacement_offset": new Parameter("Vertical Offset", 0, "number", true),
            "point_size": new Parameter("Point Size", 10, "number", true),
            "time_range": new Parameter("Time Range", 1 / 24 / 60,"number",true).setStep(1 / 24 / 60).setUnit("d"),
            "value_sizing_enabled": new Parameter("Size by Value", false, "boolean", true),
            "value_sizing_zero": new Parameter("Minimum Size at", 0, "number", true),
            "value_sizing_one": new Parameter("Maximum Size at", 1, "number", true),
            "value_sizing_power": new Parameter("Proportionality", 1, "number", true).setStep(0.1)
        };
        this.parameters["displacement_offset"].shader_name = "displacement_offset";
        this.parameters["point_size"].shader_name = "point_size";
        this.parameters["value_sizing_enabled"].shader_name = "value_sizing_enabled";
        this.parameters["value_sizing_zero"].shader_name = "value_sizing_zero";
        this.parameters["value_sizing_one"].shader_name = "value_sizing_one";
        this.parameters["value_sizing_power"].shader_name = "value_sizing_power";
        this.slots = {
            "points": new RenderSourceSlot(
                "Point Layer",
                "points",
                null,
                "points",
                null
            )
        };
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        if(this.slots["points"]?.source?.layer?.zrange) {
            let min_scaled = this.applyScaling(this.slots["points"].source.layer.zrange[0]);
            let max_scaled = this.applyScaling(this.slots["points"].source.layer.zrange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }

        return[1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        if(this.slots["points"]?.source?.layer?.zrange) {
            let min_scaled = this.applyOffset(this.slots["points"].source.layer.zrange[0]);
            let max_scaled = this.applyOffset(this.slots["points"].source.layer.zrange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }

        return[1, 1];
    }

    getExtent(): UECArea {
        if(this.slots["points"]?.source?.layer){
            return this.slots["points"].source.layer.extent;
        }
    }

    applyScaling(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                * this.parameters["displacement_scale"].value
                / EARTH_RADIUS
    }

    applyOffset(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                / EARTH_RADIUS
    }

    /*
     * Only run this function once the gl context has been prepared. It requires the correct color attachments to be set.
     */
    execute(context: { [name: string]: WebGLRenderingContext | any; }) {
        if(!this.slots["points"]?.source)return;
        
        let tr = Services.TimeService.getCurrentTimeRange();
        let tm = Services.TimeService.getMeanTime();
        let t_radius = ((this.parameters["time_range"].value) * (60 * 60 * 24 * 1000));
        let t_start = Math.min(tr[0], tm - t_radius);
        let t_end = Math.max(tr[1], tm + t_radius);
        let buffs: ArrayData[] = Services.TileCacheService.get_array_data(this.slots["points"].source, t_start, t_end).filter(e => e && context.gl.isBuffer(e.buffer));
        if(buffs.length <= 0) return;

        super.execute(context);
        context.gl.enable(context.gl.DEPTH_TEST);
        context.gl.uniform1f(this.shader.uniforms["time_min"], t_start);
        context.gl.uniform1f(this.shader.uniforms["time_max"], t_end);

        let arrow_geo = Services.GLService.Geometries.arrow;
        context.gl.enableVertexAttribArray(this.shader.attributes["geometry_position"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, arrow_geo.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["geometry_position"], 3, context.gl.FLOAT, false, 0, 0);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["geometry_position"], 0);

        /*context.gl.enableVertexAttribArray(this.shader.attributes["geometry_normal"]);        
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, arrow_normals.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["geometry_normal"], 3, context.gl.FLOAT, false, 0, 0);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["geometry_normal"], 3);
        */

        buffs.forEach(buff => {

            context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 1);
            context.gl.vertexAttribPointer(this.shader.attributes["position"], 3, context.gl.FLOAT, false, 6 * 4, 0);

            context.gl.enableVertexAttribArray(this.shader.attributes["value"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value"], 1);
            context.gl.vertexAttribPointer(this.shader.attributes["value"], 2, context.gl.FLOAT, false, 6 * 4, 3 * 4);

            context.gl.enableVertexAttribArray(this.shader.attributes["time"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time"], 1);
            context.gl.vertexAttribPointer(this.shader.attributes["time"], 1, context.gl.FLOAT, false, 6 * 4, 5 * 4);
           
            context.ANGLE_instanced_arrays.drawArraysInstancedANGLE(context.gl.TRIANGLES, 0, arrow_geo.length, buff.elements);
        });
        

        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["value"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["time"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["geometry_position"]);

    }
}
