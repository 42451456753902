<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div @mousedown="dialog_mousedown"
        class="dialog-box" 
        :ref="'dbox-'+this.$props.box_id"
        :style="{
            'left': this.$props.dbox.pos_x + 'vw',
            'top': this.$props.dbox.pos_y + 'vh',
            'width': this.$props.dbox.size_x + 'rm',
            'height': this.$props.dbox.size_y + 'rm',
            'z-index': this.$props.dbox.pos_z + 2001,
            'transform': this.$props.dbox.referenceTransform
        }">
        <div class="dialog-box-title" @mousedown.stop="move_start">
            <span>
                {{this.$props.dbox.title}}
            </span>
            <div class="dialog-close-button" v-on:click.stop="closeDialog">
                <SvgIcon name="close"></SvgIcon>
            </div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <div class="border-left border-top" @mousedown.stop="(e) => resize_start(e, 5)"></div>
        <div class="horizontal-edge border-top" @mousedown.stop="(e) => resize_start(e, 4)"></div>
        <div class="border-right border-top" @mousedown.stop="(e) => resize_start(e, 6)"></div>
        <div class="border-right vertical-edge" @mousedown.stop="(e) => resize_start(e, 2)"></div>
        <div class="border-right border-bottom" @mousedown.stop="(e) => resize_start(e, 10)"></div>
        <div class="horizontal-edge border-bottom" @mousedown.stop="(e) => resize_start(e, 8)"></div>
        <div class="border-left border-bottom" @mousedown.stop="(e) => resize_start(e, 9)"></div>
        <div class="border-left vertical-edge" @mousedown.stop="(e) => resize_start(e, 1)"></div>

    </div>
</template>
<style lang="scss">
@import '../_guikit.scss';

$dibox_edge: calc($grid / 2);

.dialog-box {
    @extend %generic_box;
    border: 1px solid $hl_color;
    padding: 0;
    display: grid;
    gap: 0;
    position: fixed;
    grid-template-rows: $dibox_edge auto 1fr $dibox_edge;
    grid-template-columns: $dibox_edge auto $dibox_edge;
}

.dialog-box-title {
    grid-row: 2;
    grid-column: 2;
    font-weight: bold;
    font-size: 120%;
    transition: background-color 0.25s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc($grid / 2);
}

.content {
    grid-row: 3;
    grid-column: 2;
    padding: $dibox_edge;
    overflow: hidden;
}
.vertical-edge {
    grid-row-start: 2;
    grid-row-end: 4;
    cursor: ew-resize;
}
.horizontal-edge {
    grid-column: 2;
    cursor: ns-resize;
}
.border-top {
    grid-row: 1;
    height: $dibox_edge;
}
.border-left {
    grid-column: 1;
    width: $dibox_edge;
}
.border-right {
    grid-column: 3;
    width: $dibox_edge;
}
.border-bottom {
    grid-row: 4;
    height: $dibox_edge;
}
.border-top.border-left, .border-bottom.border-right {
    cursor: nwse-resize;
}
.border-top.border-right, .border-bottom.border-left {
    cursor: nesw-resize;
}


.dialog-close-button{
    //@extend %generic_element;
    height: 1em;
    font-size: 120%;
    color: $warn_color;
    padding: 0;
}
</style>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component';
import { Services } from '../services/Services';
import { DialogBox, DraggingOrientation } from '../services/DialogBoxService';
import SvgIcon from './guikit/svgIcon.vue';
@Component({
    props: {
        box_id: {
            type: String,
            required: true
        },
        dbox: {
            type: DialogBox,
            required: true
        }
    },
    components: {
        SvgIcon
    }
})
export default class DialogBoxComponent extends Vue{
    draggingOrientation: DraggingOrientation;
    startpos: DOMRect;
    mousestart: {x: number, y: number};

    mounted() {
        Services.InteractionService.mouseMoveHandlers.set("dialog_"+ this.$props.box_id + "_resize", (e) => {
            if(e.buttons != 1) return;
            let dx = e.clientX - this.mousestart.x;
            let dy = e.clientY - this.mousestart.y;
            let box = this.$el as HTMLDivElement;
            if(this.draggingOrientation & DraggingOrientation.Left) {
                box.style.width = (this.startpos.width - dx) + "px";
                this.$props.dbox.pos_x = (this.startpos.left + dx) / window.innerWidth * 100;
            }
            
            if(this.draggingOrientation & DraggingOrientation.Up) {
                box.style.height = (this.startpos.height - dy) + "px";
                this.$props.dbox.pos_y = (this.startpos.top + dy) / window.innerHeight * 100;
            }
            
            if(this.draggingOrientation & DraggingOrientation.Right) {
                box.style.width = (this.startpos.width + dx) + "px";
            }
            
            if(this.draggingOrientation & DraggingOrientation.Down) {
                box.style.height =( this.startpos.height + dy) + "px";
            }
            this.$forceUpdate();
        })

        Services.InteractionService.mouseMoveHandlers.set("dialog_"+ this.$props.box_id + "_move", (e) => {
            if(e.buttons != 1) return;
            let dx = e.clientX - this.mousestart.x;
            let dy = e.clientY - this.mousestart.y;
            this.$props.dbox.pos_x = (this.startpos.left + dx) / window.innerWidth * 100;
            this.$props.dbox.pos_y = (this.startpos.top + dy) / window.innerHeight * 100;
            this.validate_position();
            this.$forceUpdate();
        })
    }

    dialog_mousedown(e) {
        Services.DialogBoxService.focus(this.$props.id);
        Services.InteractionService.mousedown("dialog_" + this.$props.box_id, e);
    }

    closeDialog(e) {
        Services.DialogBoxService.remove(this.$props.box_id);
    }

    resize_start(e, mode: DraggingOrientation) {
        Services.DialogBoxService.focus(this.$props.box_id);
        this.draggingOrientation = mode;
        this.startpos = this.$el.getBoundingClientRect();
        this.mousestart = {x: e.clientX, y: e.clientY};
        this.$props.dbox.pos_x = this.startpos.left / window.innerWidth * 100;
        this.$props.dbox.pos_y = this.startpos.top / window.innerHeight * 100;
        this.$props.dbox.referenceTransform = "translate(0%, 0%)";
        Services.InteractionService.mousedown("dialog_" + this.$props.box_id + "_resize", e);
    }

    move_start(e) {
        Services.DialogBoxService.focus(this.$props.box_id);
        this.mousestart = {x: e.clientX, y: e.clientY};
        this.startpos = this.$el.getBoundingClientRect();
        this.$props.dbox.pos_x = this.startpos.left / window.innerWidth * 100;
        this.$props.dbox.pos_y = this.startpos.top / window.innerHeight * 100; 
        this.$props.dbox.referenceTransform = "translate(0%, 0%)";
        Services.InteractionService.mousedown("dialog_" + this.$props.box_id + "_move", e);
    }

    validate_position(){
        this.$props.dbox.pos_x = Math.min(Math.max(0, this.$props.dbox.pos_x), 90);
        this.$props.dbox.pos_y = Math.min(Math.max(0, this.$props.dbox.pos_y), 90);
    }
}
</script>