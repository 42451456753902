<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="svg-icon-container">
        <svg viewBox="0 0 12 12" class="svg-icon">
            <use :xlink:href="bloburl + '#' + name"></use>
        </svg>
    </div>
</template>
<!--
How to add & use icons
======================

You can add your own custom icons as symbols in /static/icons.svg.
To make sure they are displayed correctly, ensure that the icons are:

1. Only closed paths. Make sure the icon is correctly displayed without border
and with a fill in the current text color. You can use specific colors
(for example for a logo) but generic icons should not have a style attribute.

In inkskape, you can convert outlines to paths. If you use white elements to cover
part of your icon, use the difference operation to make sure there are transparent holes
in your final icon.

2. Place the icon in the area between (0, 0) and (12, 12). SvgIcon maps this region to
a 1em square. The Icon is later anchored at the center, so make sure yours is centered
around (6, 6) as well.

3. When using inkscape, rename the symbol (id attribute) to a short name that describes it.
Call a chain icon "chain2" if "chain" already exists. The name is put into the "name"
attribute of the SvgIcon component

4. Use the SvgIcon component. The icon will have the parent element's text color and a 1em
square size.

Style Considerations
====================

The icons are currently mostly created from lines with 1.0 width and rounded corners
and ends. There are exceptions in some icons (like "eye"). To separate parts or to
show vertical ordering of elements, an outline of the front element is partially or
wholly subtracted from the elements below (see "no-eye" or "chain"). Make sure your
icon works at as low as 12x12px. 

-->
<style lang="scss">
.svg-icon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: currentColor;
    width: 1em;
    height: 1em;
}

.svg-icon-container{
    position: relative;
    display: inline-block;
    min-width: 1em;
    min-height: 1em;
    height: 100%;
    width: 100%;
}
</style>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Services } from '../../services/Services';
@Component({
    props:{
        name: String
    }
})
export default class SvgIcon extends Vue {
    private bloburl: string = "";

    mounted() {
        Services.StaticToBlobService.getBlobUrl("/static/icons.svg", (b) => {
            this.bloburl = b;
            this.$forceUpdate();
        });
    }
}

</script>