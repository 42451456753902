import { ModelObject } from "@/modules/ModelObject";

export class ModelRepositoryService{
    models: Map<String, ModelObject> = new Map();
    
    public async add_from_urls(name: string, mesh_url: string, texture_url: string){
        let cube_str = await fetch(mesh_url).then(r => r.text());
        let img = new Image();
        img.src = texture_url;
        await img.decode();
        let model = ModelObject.load_from_obj(cube_str, img);
        console.log("Loaded model with " + model.vertexCount + " vertices", model);
        this.models.set(name, model);
    }

    public get_model(name: string): ModelObject{
        return this.models.get(name);
    }
}