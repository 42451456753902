<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
<div class="scaleOverlayContainer">
    <span>Size (m):</span> <NumberInput :min="0.1" :decimals="1" v-model="scale" @input="updateScale()" :unit="'m'"></NumberInput>
</div>
</template>

<style lang="scss" scoped>
    @import '../../_guikit.scss';
    .scaleOverlayContainer{
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        grid-column-gap: calc($grid / 4);
    }
</style>

<script lang="ts">
import Vue from 'vue';
import { Overlay } from "../../services/OverlayService";
import { FormattingService } from "../../services/FormattingService";
import Component from 'vue-class-component';
import { Services } from '../../services/Services';
import { GlobalTimeRangeChangedEvent } from '../../services/TimeService';
import { SourceLayerInfo } from '../../services/SourceInfoService';
import { SamplePoint } from '../../services/PointSamplingService';
import { ComputedLineRenderSource } from '@/modules/rendersources/ComputedLineRenderSource';
//import { MeasurementChangedEvent } from '@/services/MetaLayerService';
import NumberInput from '../guikit/numberinput.vue'
import { ObjectRenderSource } from '@/modules/rendersources/ObjectRenderSource';
import { Parameter } from '@/modules/Parameter';
@Component({
    props:{
        overlay: Overlay
    },
    components:{
        NumberInput
    }
})
export default class ScaleOverlay extends Vue {
    scale: number =  1;
    private source = null;

    mounted(){
        this.recompute_value();
    }

    recompute_value(){
        this.source = this.$props.overlay.layer.source as ObjectRenderSource;
        let scale_param: Parameter = this.source.parameters['scale'];
        this.scale = scale_param.value.x1;
    }

    updateScale(){
        this.source = this.$props.overlay.layer.source as ObjectRenderSource;
        let scale_param = this.source.parameters['scale'];
        scale_param.value.x1 = this.scale;
        scale_param.value.x2 = this.scale;
        scale_param.value.x3 = this.scale;
    }

}
</script>
