//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { LayerFilter } from "./RenderFilter";
import { SourceLayerInfo } from "../../services/SourceInfoService";
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";

export class ColormapFilter extends LayerFilter{
    constructor(sourcelayerinfo: SourceLayerInfo = null){
        let colormap_param = new Parameter("colormap","balance","colormap");
            colormap_param.shader_name = "colormap";
            let colormap_min = new Parameter("min",0,"number");
            colormap_min.shader_name = "colormap_min";
            let colormap_max = new Parameter("max",1,"number");
            colormap_max.shader_name = "colormap_max";
            if(sourcelayerinfo && sourcelayerinfo.layer.datarange){
                colormap_min.value = sourcelayerinfo.layer.datarange[0];
                colormap_max.value = sourcelayerinfo.layer.datarange[1];
                let sup_min = Math.min(Math.abs(sourcelayerinfo.layer.datarange[0]), Math.abs(sourcelayerinfo.layer.datarange[1]));
                let sup_max = Math.max(Math.abs(sourcelayerinfo.layer.datarange[0]), Math.abs(sourcelayerinfo.layer.datarange[1]));
                colormap_min.setUnit(sourcelayerinfo.layer.unit);
                colormap_max.setUnit(sourcelayerinfo.layer.unit);
                colormap_param.registerAction("Symm. (min)", () => {
                    colormap_min.value = -sup_min;
                    colormap_max.value = sup_min;
                });
                colormap_param.registerAction("Symm. (max)", () => {
                    colormap_min.value = -sup_max;
                    colormap_max.value = sup_max;
                });
                colormap_param.registerAction("Reset", () => {
                    colormap_min.value = sourcelayerinfo.layer.datarange[0];
                    colormap_max.value = sourcelayerinfo.layer.datarange[1];
                });
            }
        super(Services.GLService.Modules.effects.colormap, {"colormap": colormap_param, "colormap_min": colormap_min, "colormap_max": colormap_max});
        this.human_readable_name = "Colormap";
        this.name = "colormap";
    }
}