<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="recording">
        <div>Recording: {{isRecording}} ({{recordingEventCount}} actions)</div>
        <div>Playback: {{isPlayingBack}} ({{playbackEventCount}} actions)</div>
       <div id="recording-buttons" class="row">
           <div class="button symbol continue" @click="start()"><SvgIcon name="fast-start"></SvgIcon></div>
           <div class="button symbol continue record" :recording="isRecording" @mousedown.stop="record()"><SvgIcon name="record"></SvgIcon></div>
           <div class="button symbol continue"  @click="playpause()"><span v-if="isPlayingBack"><SvgIcon name="pause"></SvgIcon></span><span v-else><SvgIcon name="play"></SvgIcon></span></div>
           <div class="button symbol continue" @click="end()"><SvgIcon name="fast-end"></SvgIcon></div>
        </div>
        <div>
            <progress style="width: 100%;" :max="recordingEventCount" :value="playbackEventCount"></progress>
        </div>
        <div class="button" @click="frameRecord()">Export TAR of JPEG frames</div><br/>
        <div class="button" @click="exportActions()">Export Actions as JSON</div><br/>
        <div class="button" @click="importActions()">Import Actions as JSON</div><br/>
        <input type="file" id="actionsFile" style="display: none;" @change="loadActionsFile" />
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import { Services } from '../services/Services';
import { PlaybackStateEvent, RecordingStateEvent } from '../services/RecordingService';
@Component({
    components: {
        SvgIcon
    },
    props:{}
})
export default class RecordingPlaybackComponent extends Vue{
    isPlayingBack = false;
    isRecording = false;
    recordingEventCount = 0;
    playbackEventCount = 0;
    usualSpeed: number;

    mounted(){
        Services.InteractionService.mouseDownHandlers.set("selections", () => {});
        Services.RecordingService.addEventListener("RecordingState", (re: RecordingStateEvent) => {
            this.isRecording = re.recording;
            this.recordingEventCount = re.event_number;
        });
        Services.RecordingService.addEventListener("PlaybackState", (pe: PlaybackStateEvent) => {
            this.isPlayingBack = pe.playback;
            this.playbackEventCount = pe.event_number;
        });
    }

    playpause(){
        if(this.isPlayingBack){
            console.log("Stopping pb")
            Services.RecordingService.stopPlayback();
        }else{
            console.log("Starting pb")
            Services.RecordingService.startPlayback();
        }
    }

    frameRecord(){
        if(this.isPlayingBack){
            console.log("Stopping pb")
            Services.RecordingService.stopPlayback();
        }else{
            console.log("Starting pb")
            Services.RecordingService.startPlayback(true);
        }
    }

    record(){
        if(this.isRecording){
            Services.RecordingService.stopRecording();
        }else{
            Services.RecordingService.startRecording();
        }
    }

    start(){
        Services.RecordingService.goStartPlayback();
    }

    end(){
        Services.RecordingService.goEndPlayback();
    }

    exportActions(){
        let json = JSON.stringify(Services.RecordingService.recordingActions, null, 4);
        let bytes = new TextEncoder().encode(json);
        let b = new Blob([bytes]);
        let a_elem = document.getElementById('downloadlink') as HTMLAnchorElement;
        a_elem.setAttribute("download", Services.InitializationService.SceneName.replaceAll(/[^a-zA-Z0-9]/g, "_") + "_actions.json");
        a_elem.setAttribute("href", URL.createObjectURL(b));
        a_elem.click();
    }

    importActions(){
        document.getElementById("actionsFile").click();
    }

    async loadActionsFile(e){
        let resp = new Response(e.target.files[0]);
        let file = await resp.json();
        Services.RecordingService.stopRecording();
        Services.RecordingService.stopPlayback();
        Services.RecordingService.recordingActions = file;
        Services.RecordingService.goStartPlayback();
    }
    
}
</script>

<style lang="scss" scoped>
    @import '../_guikit.scss';

    div.button.record {
        color: $guikit_lightred;
    }
    div.button.record:active, div.button.record[recording]{
        color: $bg_color;
        background-color: $guikit_lightred;
    }


    #playback{
        display: flex;
        flex-direction: column;
        min-width: 16em;
    }

    .row{
        display: flex;
        flex-direction: row;
        justify-content:  center;
        margin-top: $grid;
    }
    .symbol{
        transition: color 0.5s,  background-color 0.5s;
    }

    #selection>div{
        margin: 0.25em;
    }
</style>