//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

/*
Service to format numbers and coordinates as strings. Widely used in the program.
*/

import {UEC, Coord} from '../modules/tile';
import { Vec3 } from '../modules/vecmat';

const nbh_space = "\u202F";
const neg_sign = "\u2212";
const mul_sign = "\u00d7";
const sci_notation_prefix = nbh_space + mul_sign + nbh_space + "10";

//(exponent + 24) / 3 = index
const si_prefixes = [
    "y", "z", "a", "f", "p", "n", "µ", "m", "", "k", "M", "G", "T", "P", "E", "Z", "Y"
];
const sci_notation_exp = [
    sci_notation_prefix + "\u207b\u00b2\u2074",//-24
    sci_notation_prefix + "\u207b\u00b2\u2071",//-21
    sci_notation_prefix + "\u207b\u2071\u2078",//-18
    sci_notation_prefix + "\u207b\u2071\u2075",//-15
    sci_notation_prefix + "\u207b\u2071\u00b2",//-12
    sci_notation_prefix + "\u207b\u2079",//-9
    sci_notation_prefix + "\u207b\u2076",//-6
    sci_notation_prefix + "\u207b\u00b3",//-3
    "",//0
    sci_notation_prefix + "\u00b3",//3
    sci_notation_prefix + "\u2076",//6
    sci_notation_prefix + "\u2079",//9
    sci_notation_prefix + "\u2071\u00b2",//12
    sci_notation_prefix + "\u2071\u2075",//15
    sci_notation_prefix + "\u2071\u2078",//18
    sci_notation_prefix + "\u00b2\u2071",//21
    sci_notation_prefix + "\u00b2\u2074",//24
]

export class FormattingService {
    //Prints a number as a string with a unit attached. If no optional seperator is given, a non-breaking-space-character is injected
    static num_to_string_unit(num: number, unit: string, decimals: number, separator?: string): string {
        if(!separator) separator = nbh_space;
        if(unit)
            return this.number_to_string(num, decimals) + separator + unit;
        else
            return this.number_to_string(num, decimals);
    }

    static num_to_string_unit_si(num: number, unit: string, decimals: number, separator?: string): string {
        if(!separator) separator = nbh_space;
        let magnitude = FormattingService.num_decimals(num);
        let prefix_index = Math.max(Math.min(Math.floor((magnitude + 23) / 3), si_prefixes.length - 1), 0);
        if(unit){
            return this.number_to_string(num / Math.pow(10, prefix_index * 3 - 24), decimals) + separator + si_prefixes[prefix_index] + unit;
        }else{
            if (magnitude <= 0) {
                prefix_index = Math.max(Math.min(Math.floor((magnitude + 24) / 3), si_prefixes.length - 1), 0)
            }
            //console.log("num_to_string_unit_si", num, unit, decimals, magnitude, prefix_index);
            return this.number_to_string(num / Math.pow(10, prefix_index * 3 - 24), decimals) + sci_notation_exp[prefix_index];
        }
    }

    static color_0_1_to_hex(r: number, g: number, b: number, prefix: string = "#"): string {
        return prefix
        + this.leftpad((r * 255 | 0).toString(16), "0", 2)
        + this.leftpad((g * 255 | 0).toString(16), "0", 2)
        + this.leftpad((b * 255 | 0).toString(16), "0", 2);
    }
    static color_vec3_to_hex(colorvec: Vec3, prefix: string = "#"): string{
        return FormattingService.color_0_1_to_hex(colorvec.x1, colorvec.x2, colorvec.x3, prefix);
    }

    static color_hex_to_vec3(hexstr: string): Vec3{
        if(hexstr.startsWith("#")){
            hexstr = hexstr.substr(1);
        }
        let rstr = hexstr.substring(0, 2);
        let gstr = hexstr.substring(2,4);
        let bstr = hexstr.substring(4,6);

        let rvalue = parseInt(rstr, 16);
        let gvalue = parseInt(gstr, 16);
        let bvalue = parseInt(bstr, 16);
        
        return new Vec3(rvalue / 255, gvalue / 255, bvalue / 255);
    }

    static num_decimals(num: number): number {
        if(num == 0)
            return 1;
        else
            return (Math.floor(Math.log10(Math.abs(num))) + 1) | 0;
    }

    static decimals_for_range(min: number, max: number): number {
        let delta = max - min;
        let decs = this.num_decimals(delta);
        return 4 - decs;
    }

    //Formats a latitude as decimal degrees north or south
    static latitude_to_string (lat: number, decimals: number): string {
        if(lat >= 0) return this.number_to_string(lat, decimals) + nbh_space + "°N"; 
        else return this.number_to_string(-lat, decimals) + nbh_space + "°S";
    }

    //Formats a longitude as decimal degrees east or west
    static longitude_to_string (lon: number, decimals: number): string {
        if(lon >= 0) return this.number_to_string(lon, decimals) + nbh_space + "°E";
        else return this.number_to_string(-lon, decimals) + nbh_space + "°W";
    }

    //Formats a set of uec coordinates to a human readable string of decimal degrees
    static UEC_to_coord_string (uec: UEC, decimals: number, separator?: string): string {
        let c = Coord.from_UEC(uec);
        if(!separator) separator = nbh_space;
        return this.coord_to_string(c.lat, c.lon, decimals, separator);
    }

    //Formats a Coordinate object to a human readable string of decimal degrees
    static Coord_to_coord_string (coord: Coord, decimals: number, separator?: string): string {
        if(!separator) separator = nbh_space;
        return this.coord_to_string(coord.lat, coord.lon, decimals, separator);
    }

    //Formats decimal latitude and logitude to a human readable string
    static coord_to_string (lat: number, lon: number, decimals: number, separator?: string): string {
        if(!separator) separator = nbh_space;
        return this.latitude_to_string(lat, decimals) + separator + this.longitude_to_string(lon, decimals);
    }

    //Formats a number to a string with a given accuracy
    static number_to_string(num: number, decimals: number): string {
        if(decimals <= 0) return this.int_to_string(num);
        let sign = num >= 0;
        decimals = decimals | 0;
        let nd = Math.pow(10, decimals);
        let s = this.leftpad(this.int_to_string(Math.abs(Math.round(nd * num))), "0", decimals);
        let n = s.length - decimals;
        let whole = s.substr(0, n);
        if(whole.length == 0)whole = "0";
        let frac = this.leftpad(s.substr(n), "0", decimals);
        return (sign ? "" : neg_sign) + whole + "." + frac;
    }

    //The one. The only. Extends a string with filler characters from the left side until it reaches a certain length.
    static leftpad(s: string, c: string, tlen: number): string {
        if(s.length < tlen){
            return c.repeat(tlen - s.length) + s;
        }else return s;
    }

    //Converts a number to an integer and casts it to a string
    static int_to_string(num: number): string {
        return (num | 0) + "";
    }

    //Converts a timestamp to an ISO8601-String
    static time_to_string(time: number): string {
        return new Date(time || 0).toISOString().replace("T", nbh_space).replace("Z", nbh_space).split(".")[0];
    }

    //Converts a timestamp to shorter ISO8601-alike-String
    static time_to_string_shorter(time: number): string {
        let strdatetime = new Date(time || 0).toISOString()
        let [strdate, strtime] = strdatetime.split("T");
        strtime = strtime.substr(0,strtime.indexOf("Z"));
        let [hour, minute, second] = strtime.split(":");
        second = second.split(".")[0];
        let result = strdate;
        if(hour && hour != "00"){
            result += nbh_space + hour;
            if(minute && minute != "00"){
                result += ":" + minute;
                if(second && second != "00"){
                    result += ":" + second;
                }
            }else{
                result += "h";
            }
        }
        return result;
    }

    static duration_map = [
            {name: 'd', length: 24 * 60 * 60 * 1000},
            {name: 'h', length: 60 * 60 * 1000},
            {name: 'm', length: 60 * 1000},
            {name: 's', length: 1 * 1000},
            {name: 'w', length: 7 * 24 * 60 * 60 * 1000},
            {name: 'y', length: 365.25 * 24 * 60 * 60 * 1000}
    ];

    static duration_milliseconds_to_string(duration: number): string{
        let result = "";

        for(let unit of FormattingService.duration_map){
            if(duration >= unit.length){
                let unit_value = Math.floor(duration / unit.length);
                duration = duration - (unit_value * unit.length);
                result += unit_value + unit.name;
            }
        }
        return result;
    }

    static duration_string_to_milliseconds(str: string): number{
        str = str.trim();
        let result = 0;
        let current_number_string = "";
        const numbers = "0123456789";
        for(let char of str){
            //Check if it's a space
            if(char == " "){
                continue;
            }
            //Check if it's an number
            if(numbers.indexOf(char) != -1){
                current_number_string += char;
            }else{
                //Check if it's a duration letter
                let duration_map_entry = this.duration_map.find(entry => entry.name == char);
                if(duration_map_entry != undefined){
                    let current_number = parseInt(current_number_string);
                    //Check if the number parsed correctly
                    if(!isNaN(current_number)){
                        result += current_number * duration_map_entry.length;
                    }else{
                        return null;
                    }
                    current_number_string = "";
                }else{
                    return null;
                }
                //Reset the counter
            }
        }
        if(current_number_string.length > 0){
            return null;
        }
        return result;
    }
}