<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->

<template>
    <div id="inner">
        <a style="display:none" id="downloadlink" href="#" download="initial_layers.json"></a>
        <threedee></threedee>
        <div id="ui" v-show="ui_visible">
            <timeslider></timeslider>
            <compass></compass>
            <overlays></overlays>
            <dialogboxes></dialogboxes>
            <scale></scale>
            <loadingindicator></loadingindicator>
            <menucomponent></menucomponent>
            <logo></logo>
        </div>
    </div>
</template>

<script>

import styles from './global.scss';
import Vue from 'vue'

import threedee from "./components/threedee.vue";
import timeslider from './components/timeslider.vue';
import compass from './components/compass.vue';
import overlays from './components/overlays.vue';
import scale from './components/scale.vue';
import loadingindicator from './components/loadingindicator.vue';
import menucomponent from './components/menucomponent.vue';
import dialogboxes from './components/dialogBoxes.vue';
import logo from './components/logocomponent.vue';

import { Services } from './services/Services';
import { DialogBox } from './services/DialogBoxService';

export default {
    components: {
        threedee,
        timeslider,
        compass,
        overlays,
        scale,
        loadingindicator,
        dialogboxes,
        menucomponent,
        logo
    },
    data: function(){
        return{
            ui_visible: true
        }
    },
    mounted: async function(){
        if(window.localStorage.getItem("license_displayed") != "true"){
            Services.DialogBoxService.insert("About", DialogBox.centered("AboutDialogue", "About the Digital Earth Viewer"));
        }
        await Services.SourceInfoService.source_barrier.wait();
        await Services.InitializationService.initialize();

        Services.GLService.addEventListener("FrameDone", () => {
            this.ui_visible = Services.SettingsService.getValueOrDefault("UIVisible", true);
        });

        Services.InteractionService.addEventListener("KeyDown", (k) => {
            if(Services.InteractionService.getTarget() == "threedee"){
                if (k.key == "h"){
                    Services.SettingsService.getSetting("UIVisible").value = !Services.SettingsService.getSetting("UIVisible").value;
                    Services.AdaptivePerformanceService.RequestRerender();
                }
            }
        });
    }
}
</script>

<style scoped>
    body {
        font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    #inner{
        width: 100%;
        height: 100%;
        overflow:hidden;
    }
</style>