<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="manual-container" @click.stop="">
        <div id="manual-toc">
            <strong style="font-size: 125%; text-decoration: underline;">Table of Contents</strong>
            <a :class="{'toc-item': true, 'selected': selected_article == 'home'}" @click="selectArticle('home')">Home</a>
            <a v-for="item in table_of_contents" :key="item[0] + item[1]" :class="{'toc-item': true, 'selected': selected_article == item[0] + item[1]}" @click="selectArticle(item[0] + item[1])">
                <span class="toc-prefix">{{item[0].replaceAll("-", " ")}}</span>
                <span class="toc-name">{{item[1].replaceAll("-", " ")}}</span>
            </a>
        </div>
        <div id="manual-text"></div>
    </div>
</template>

<style lang="scss">
@use "sass:color";
@import '../_guikit.scss';
    #manual-container{
        display: flex;
        flex-direction: row;
        gap: $grid * 2;
        height: 100%;
        max-height: 80vh;
    }

    #manual-toc{
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        font-size: 90%;
        padding-right: 0.5em;
        border-right: 1px solid #535353;
    }

    #manual-text{
        flex-grow: 1;
        color: white;
        user-select: text;
        overflow: auto;
        overflow-wrap: break-word;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
        line-height: 150%;
    }

    #manual-text table{
        min-width: 50ex;
        max-width: 100%;
    }

    #manual-text p{
        width: calc(min(80ex, 100%));
        text-align: justify;
    }

    #manual-text a{
        cursor: pointer;
    }

    #manual-text code {
        background-color: $control_color;
        color: $guikit_lightblue;
        padding: $grid / 4 $grid / 2;
        border-radius: $grid / 4;
        text-align: left;
    }

    #manual-text a code {
        color: $hl_color;
    }

    #manual-text a code:hover {
        text-decoration: underline;
    }

    #manual-text pre {
        width: 80ex;
    }

    #manual-text pre code {
        overflow-x: auto;
        display: block;
        border-radius: $grid / 2;
    }

    #manual-text img {
        max-width: 100%;
        vertical-align: top;
    }

    #manual-text table{
        max-width: 80ex;
        text-align: justify;
        border-collapse: collapse;
    }

    #manual-text thead{
        background-color: color.adjust($bg_color, $lightness: +10%);
    }

    #manual-text td, #manual-text th{
        padding: 0.5 * $grid $grid;
    }

    #manual-text td{
        vertical-align: top;
    }

    #manual-text td:first-child, #manual-text th:first-child{
        border-radius: 0.5 * $grid 0 0 0.5 * $grid;
    }

    #manual-text td:last-child, #manual-text th:last-child{
        border-radius: 0 0.5 * $grid 0.5 * $grid 0;
    }

    #manual-text tr:nth-of-type(even) {
        background-color: color.adjust($bg_color, $lightness: +5%);
    }

    #manual-text tr:nth-of-type(even) code{
        background-color: color.adjust($bg_color, $lightness: +10%);
    }

    .toc-item{
        text-decoration: none;
        color: #ddd;
        cursor: pointer;
    }

    .toc-item:hover{
        text-decoration: underline;
    }

    .toc-prefix{
        color: gray;
        font-weight: normal;
    }

    .selected{
        color: $guikit_lightblue;
    }

</style>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import ScrollBox from './guikit/scrollbox.vue';
import { Services } from '../services/Services';
const marked = require('marked');

@Component({
components: {
    SvgIcon,
    ScrollBox
}
})
export default class ManualComponent extends Vue{
    public table_of_contents: [string, string][] = [];
    public selected_article: string = "home";
    public selected_article_html: string = "";
    index: string[] = [];
    allowed_files: string[] = [];
    async mounted(){
        this.index = await fetch("static/wiki/index.json").then(r => r.json());
        this.index = this.index.filter(i => i != "home.md");
        this.allowed_files = await fetch("static/wiki/allowed_files.json").then(r => r.json());
        this.table_of_contents = this.index.map(i => {
            i = i.replace('.md', '');
            let last_slash_index = i.lastIndexOf('/');
            if(last_slash_index != -1){
                return [i.substring(0, last_slash_index+1), i.substring(last_slash_index + 1)];
            }else{
                return ["", i];
            }
        });
        this.selectArticle(this.selected_article);
    }

    async selectArticle(item: string){
        this.selected_article = item;
        //Request article
        let article_raw = await fetch("static/wiki/" + item + ".md").then(r => r.text());
        //Parse article
        let article_md = marked.parse(article_raw);
        let manual_text = document.getElementById('manual-text');
        manual_text.innerHTML = article_md;
        //Fix links
        let links = document.querySelectorAll('#manual-text a');
        for(let link of links){
            let target = link.getAttribute('href');
            if(!target.startsWith("http")){
                if(this.index.indexOf(target + ".md") != -1){
                    link.removeAttribute("href");
                    link.addEventListener('click', () => this.selectArticle(target));
                }else{
                    target = "https://git.geomar.de/digital-earth/digital-earth-viewer/-/wikis/" + target;
                    link.setAttribute('href', target);
                }
            }
        }
        //Fix images
        let images = document.querySelectorAll('#manual-text img');
        for(let image of images){
            let target = image.getAttribute('src');
            if(!target.startsWith("http")){
                if(this.allowed_files.indexOf("target") != -1){
                    target = "static/wiki/" + target;
                    image.setAttribute("src", target);
                }else{
                    target = "https://git.geomar.de/digital-earth/digital-earth-viewer/-/wikis/" + target;
                    image.setAttribute('src', target);
                }
            }
        }
    }
}
</script>