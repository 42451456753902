//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { FormattingService } from "@/services/FormattingService";

export class TarBall {
    static gen_header(name: string, size: number): ArrayBuffer {
        let buf = new Uint8Array(512);
        let tenc = new TextEncoder();
        // filename
        tenc.encode(name).forEach((c, i) => {
            if(i < 99){
                buf[i] = c;
            }
        });
        // permissions
        tenc.encode("0100777").forEach((c, i) => {
            if(i < 7){
                buf[i + 100] = c;
            }
        });
        // user & group
        tenc.encode("0000000").forEach((c, i) => {
            if(i < 7){
                buf[i + 108] = c;
                buf[i + 116] = c;
            }
        });
        // file size
        tenc.encode(FormattingService.leftpad(size.toString(8), "0", 11)).forEach((c, i) => {
            if(i < 11){
                buf[i + 124] = c;
            }
        });
        // file creation date (now)
        tenc.encode(FormattingService.leftpad(((Date.now() / 1000) | 0).toString(8), "0", 11)).forEach((c, i) => {
            if(i < 11){
                buf[i + 136] = c;
            }
        });
        // 8 spaces as placeholder for the header checksum
        tenc.encode("        ").forEach((c, i) => {
            if(i < 8){
                buf[i + 148] = c;
            }
        });
        // type = 0
        buf[156] = 48;
        // magic
        tenc.encode("ustar").forEach((c, i) => {
            if(i < 5){
                buf[i + 257] = c;
            }
        });
        // version
        tenc.encode("00").forEach((c, i) => {
            if(i < 2){
                buf[i + 263] = c;
            }
        });
        // actual checksum
        let checksum = 0;
        buf.forEach(c => {
            checksum += c;
        });
        // set all checksum bits to 0x00
        for(var i = 148; i < 156; i++){
            buf[i] = 0;
        }
        // overwrite with string 
        tenc.encode(checksum.toString(8)).forEach((c, i) => {
            if(i < 7){
                buf[i + 148] = c;
            }
        });
        return buf.buffer;
    }
}