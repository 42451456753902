<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="playback">
       <div class="datetime-label" id="datetime-label">{{time}}</div>
       <div class="datetime-label" id="datetime-label-end" v-show="time2">{{time2}}</div>
       <div id="playback-buttons" class="row">
           <div class="button symbol continue" @click="start()"><SvgIcon name="fast-start"></SvgIcon></div>
           <div class="button symbol continue" @mousedown.stop="reverse($event)"><SvgIcon name="fast-reverse"></SvgIcon></div>
           <div class="button symbol continue" :stalled="stalled" @click="playpause()"><span v-if="playbackState"><SvgIcon name="pause"></SvgIcon></span><span v-else><SvgIcon name="play"></SvgIcon></span></div>
           <div class="button symbol continue" @mousedown.stop="forward($event)"><SvgIcon name="fast-forward"></SvgIcon></div>
            <div class="button symbol continue" @click="end()"><SvgIcon name="fast-end"></SvgIcon></div>
        </div>
        <div id="playback-speed" class="row">
            <input type="text" style="margin-right: 0.2em;" id="speed-input" :value_broken="!internal_speed_ok" v-model="internal_speed_str" @change="speedChanged" />&nbsp; per second
        </div>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import { FormattingService } from '../services/FormattingService';
import { PlaybackStateChangedEvent } from '../services/PlaybackService';
//import { SelectionChangedEvent, SelectionMode, SelectionState } from '../services/SelectionService';
import { ServiceBarrier, Services } from '../services/Services';
@Component({
    components: {
        SvgIcon
    },
    props:{}
})
export default class PlaybackComponent extends Vue{
    playbackState = false;
    time: string= "Out of time";
    time2: string = "blubber";
    
    internal_speed_ok = true;
    internal_speed_str = "1d";

    stalled = false;

    speedChanged(){
        let speed_milliseconds = FormattingService.duration_string_to_milliseconds(this.internal_speed_str);
        if(speed_milliseconds != null){
            Services.PlaybackService.setSpeed(speed_milliseconds);
            this.internal_speed_ok = true;
        }else{
            this.internal_speed_ok = false;
        }
    }

    usualSpeed: number;

    mounted(){
        Services.InteractionService.mouseDownHandlers.set("selections", () => {});
        this.updateTime();
        Services.TimeService.addEventListener("CurrentTimeChanged", () => this.updateTime());
        Services.PlaybackService.addEventListener("PlaybackStateChanged", (e: PlaybackStateChangedEvent) => this.playbackState = e.state);
        Services.InteractionService.mouseUpHandlers.set("playback-fast-forward", (e) => {
            Services.PlaybackService.playback_overridden = false;
            Services.PlaybackService.begin_playing(1);
        });
        Services.InteractionService.mouseUpHandlers.set("playback-fast-reverse", (e) => {
            Services.PlaybackService.playback_overridden = false;
            Services.PlaybackService.begin_playing(1);
        });
    }

    private updateTime(){
        let tr = Services.TimeService.getCurrentTimeRange();
        this.stalled = Services.PlaybackService.stalled;
        if(tr[1] == tr[0]){
            this.time = FormattingService.time_to_string(Services.TimeService.getMeanTime());
            this.time2 = null;
        }else{
            this.time = FormattingService.time_to_string(tr[0]);
            this.time2 = FormattingService.time_to_string(tr[1]);
        }
    }

    playpause(){
        Services.PlaybackService.togglePlaying();
        this.stalled = Services.PlaybackService.stalled;
    }

    reverse(e){
        Services.InteractionService.mousedown("playback-fast-forward", e);
        Services.PlaybackService.playback_overridden = true;
        Services.PlaybackService.begin_playing(-16);
    }

    forward(e){
        Services.InteractionService.mousedown("playback-fast-reverse", e);
        Services.PlaybackService.playback_overridden = true;
        Services.PlaybackService.begin_playing(16);
    }

    start(){
        let pbs = this.playbackState;
        Services.PlaybackService.setPlaybackState(false);
        Services.TimeService.moveTimeStart();
        Services.PlaybackService.setPlaybackState(pbs);
    }

    end(){
        let pbs = this.playbackState;
        Services.PlaybackService.setPlaybackState(false);
        Services.TimeService.moveTimeEnd();
        Services.PlaybackService.setPlaybackState(pbs);
    }
    
}
</script>

<style lang="scss" scoped>
    @import '../_guikit.scss';

    #playback{
        display: flex;
        flex-direction: column;
        min-width: 16em;
    }

    .row{
        display: flex;
        flex-direction: row;
        justify-content:  center;
        margin-top: $grid;
    }

    #speed-input{
        width: 8em;
        text-align: right;
        transition: color 0.5s;
    }

    #speed-input[value_broken]{
        color: $warn_color !important;
    }
    .symbol[stalled]{
        color: $bg_color;
        background-color: $warn_color;
    }
    .symbol{
        transition: color 0.5s,  background-color 0.5s;
    }

    .datetime-label{
        text-align: center;
        font-size: 150%;
        font-family: monospace;
    }

    #selection>div{
        margin: 0.25em;
    }
</style>