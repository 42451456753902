<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="menubar" @mousedown="register_mousedown">
        <div class="button-group">
            
            <div title="Save Scene" class="button symbol continue" @click="showSaveMenu"><SvgIcon name="save"></SvgIcon></div>

            <div title="Settings" class="button symbol continue" @click="showSettings"><SvgIcon name="gear"></SvgIcon></div>
            <div title="Playback" class="button symbol continue" @click="showPlayback"><SvgIcon name="playpause"></SvgIcon></div>
        </div>
        
        <div class="button-group">
            <div title="Move Camera" :is-selected="interactionStateMove" class="button symbol continue" @click="modeMove"><SvgIcon name="movement"></SvgIcon></div>
            <div title="Place Pins..." :is-selected="interactionStateOverlay" class="button symbol continue" @click="modePin"><SvgIcon name="marker"></SvgIcon></div>
            <div title="Measure Distances..." :is-selected="interactionStateMeasure" class="button symbol continue" @click="modeMeasure"><SvgIcon name="distance"></SvgIcon></div>

        </div>

        <div class="button-group">
            <div title="Toggle Scale Indicator Visibility..." :is-selected="is_scale_indicator_visible" class="button symbol continue" @click="toggleScaleIndicatorVisibility"><SvgIcon name="grid"></SvgIcon></div>
        </div>

        <div class="button-group">
            <div title="Layer Configuration..." class="button symbol continue" @click="layerSettings"><SvgIcon name="layer-sliders"></SvgIcon></div>
        </div>
        <div class="button-group">
            <div title="User Manual..." class="button symbol continue" @click="showManual"><SvgIcon name="book-help"></SvgIcon></div>
        </div>

        <div class="button-group">
            <div title="Program Status Information" class="button symbol continue" @click="showProgramStatus"><SvgIcon name="graph"></SvgIcon></div>
        </div>
<!--

        <div class="button-group">
            <div title="Debug Information" class="button symbol continue" @click="showDebug">DEBUG</div>
        </div>-->
    </div>
</template>
<style lang="scss" scoped>
@import '../_guikit.scss';

div.button.record {
    color: $guikit_lightred;
}
div.button.record:active{
    color: $bg_color;
}

div.button-group {
    display: flex;
}

.button[is-selected] {
    background-color: $hl_color;
    color: $bg_color;
}

div.button-group:not(:last-child) {
    margin-right: $grid;
}

.menubar {
    display: flex;
    position: fixed;
    top: $grid;
    left: $grid;
    font-size: 120%;
    z-index: 10000;
}

.button {
    margin: 0;
}
</style>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import {Services} from '../services/Services';
import { DialogBox } from '../services/DialogBoxService';
import { InteractionState, InteractionStateChangedEvent } from '../services/InteractionService';

@Component({
    components: {
        SvgIcon
    },
    props:{}
})
export default class menucomponent extends Vue{

    private istate: InteractionState = InteractionState.Move;

    private is_scale_indicator_visible = false;

    register_mousedown(e) {
        Services.InteractionService.mousedown("menucomponent", e);
    }    

    get interactionStateMove () {
        return this.istate == InteractionState.Move;
    }

    get interactionStateOverlay () {
        return this.istate == InteractionState.Overlay;
    }

    get interactionStateMeasure () {
        return this.istate == InteractionState.Measure;
    }

    mounted() {
        Services.InteractionService.addEventListener("InteractionStateChanged", (e: InteractionStateChangedEvent) => {this.istate = e.state;});
    }

    showSettings(e){
        Services.DialogBoxService.try_insert("Settings", new DialogBox("Settings", 128, 128, 32, 32, "Settings"));
    }

    addLayer(e){
        Services.DialogBoxService.try_insert("New Layer", DialogBox.centered("TypeSelectionDialog", "Select Layer Type"));
    }

    layerSettings(e){
        Services.DialogBoxService.try_insert("Layer Settings", DialogBox.centered("LayerStack", "Layers"))
    }

    modeMove(e){
        Services.InteractionService.setInteractionState(InteractionState.Move);
        //Services.MeasurementService.set_visibility(false);
    }

    modePin(e){
        Services.InteractionService.setInteractionState(InteractionState.Overlay);
        //Services.MeasurementService.set_visibility(false);
    }

    modeMeasure(e){
        Services.InteractionService.setInteractionState(InteractionState.Measure);
    }

    graph(){
        let graphid = Services.UIDService.getNextTopicId("graph") + 1;
        Services.DialogBoxService.try_insert("Graph " + graphid, new DialogBox("GraphComponent", 256, 256, 32, 32, "Graph " + graphid));
    }
    
    showPlayback(e){
        Services.DialogBoxService.try_insert("Playback Controls", new DialogBox("PlaybackComponent", 128, 128, 32, 32, "Playback Controls"));
    }

    showDebug(e){
        Services.DialogBoxService.try_insert("Debug", new DialogBox("DebugComponent", 128, 128, 32, 32, "Debug"));
    }

    showProgramStatus(e){
        Services.DialogBoxService.try_insert("ProgramStatus", new DialogBox("ProgramStatusComponent", 128, 128, 32, 32, "Program Status"));
    }

    showRecording(e){
        Services.DialogBoxService.try_insert("Recording", new DialogBox("RecordingPlaybackComponent", 128, 128, 32, 32, "Recording"));
    }

    showSaveMenu(e){
        Services.DialogBoxService.try_insert("Save/Load", new DialogBox("SaveDialogue", 256, 128, 32, 32, "Save/Load"));
    }

    showManual(e){
        Services.DialogBoxService.try_insert("Manual", new DialogBox("ManualComponent", 256, 128, 32, 32, "Manual"));
    }

    toggleScaleIndicatorVisibility(e){
        this.is_scale_indicator_visible = ! this.is_scale_indicator_visible;
        Services.ScaleIndicatorService.set_visibility(this.is_scale_indicator_visible);
        Services.AdaptivePerformanceService.RequestRerender();
    }

}
</script>