<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div @mousedown="mousedown_evt" class="layer-component-container" :key="layer.changeId">
            <fold-group name="Source" :expanded="true">
                <div class="layer-component-source-slots">
                    <template v-for="[name,slot] in Object.entries(layer.source.slots).filter(s => !s[1].name =='')" >
                        <span :key="'sourcename_' + name" class="first-col">{{slot.name}}</span>
                        <span :key="'sourceslot_' + name" class="double-col" @click="selectSource(layer, slot, name)">
                            <SourceLayerBadge :source="layer.source.slots[name] || null" :layer=layer :slotname=name></SourceLayerBadge>
                        </span>
                    </template>    
                </div>
            </fold-group>
            <fold-group name="General Options">
                <div class="layer-component-source-parameters">
                    <template v-for="(parameter, index) in layer.source.parameters">
                        <ParameterComponent v-if="parameter.userVisible" :key="'source-parameter-' + index + '_' + layer.changeId" :param="parameter" />
                    </template>
                </div>
            </fold-group>
            
            <template v-for="(filter, index) in layer.filterPipeline">
                <fold-group :name="filter.human_readable_name" :key="'filter-label-'+index">
                    <template v-for="(parameter, pindex) in filter.parameters">
                        <ParameterComponent v-if="parameter.userVisible" :key="'filter-' + index + '-parameter-' + pindex + '_' + layer.changeId" :param="parameter" />
                    </template>
                </fold-group>                
            </template>

            <fold-group name="Composition">
                <div class="layer-component-source-parameters">
                    <template v-for="(parameter, index) in layer.compositionFilter.parameters">
                        <ParameterComponent v-if="parameter.userVisible" :key="'source-composition-parameter-' + index + '_' + layer.changeId" :param="parameter" />
                    </template>
                </div>
            </fold-group>

            <fold-group name="Attribution">
                <div class="triple-col layer-component-attribution" style="max-width: 40ch; user-select: text;" v-html="attribution()"></div>
            </fold-group>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {SourceLayerInfo, Source} from '../services/SourceInfoService';
import {RenderLayer} from '../modules/RenderLayer';
import SourceLayerBadge from './sourcelayerbadge.vue';
import {FormattingService} from '../services/FormattingService';
import {Services} from '../services/Services';
import ParameterComponent from './parametercomponent.vue';
import { RenderSourceSlot } from '../modules/rendersources/RenderSource';
import { DialogBox } from '../services/DialogBoxService'; 
import { LayersChangedEvent } from '../services/RenderLayerService';
import ScrollBox from './guikit/scrollbox.vue';
import FoldGroup from './guikit/foldGroup.vue';
//import * as marked from 'marked';
const marked = require('marked');
@Component({
    components: {
        SourceLayerBadge,
        ParameterComponent,
        ScrollBox,
        FoldGroup
    },
    props:{}
})
export default class LayerComponent extends Vue{
    layerCanBeDeleted: boolean = false;

    stupid = true;

    layer: RenderLayer;

    attribution(){
        return Object.values(this.layer.source.slots)
        .filter((v: RenderSourceSlot) => v.source != null)
        .map((v: RenderSourceSlot) => {
            let attribution = v.source.source.attribution;
            let max_dois = 10; //Just to prevent this update taking forever
            let doi_counter = 0;
            while(/[^\[]doi\:([a-zA-Z0-9-/.]+)/.test(attribution) && doi_counter < max_dois){
                doi_counter ++;
                let doi = attribution.match(/doi\:([a-zA-Z0-9-/.]+)/);
                if(doi && doi.length && doi.length >= 2){
                    attribution = attribution.replace(doi[0], `[${doi[0]}](https://doi.org/${doi[1]})`);
                }
            }
            return "<h4>" + v.name + ":</h4>" + marked.parse(attribution);
        }).join("");
    }

    change(){
        Services.AdaptivePerformanceService.RequestRerender();
    }

    created(){
        this.layer = Services.RenderLayerService.getSelectedLayer();
    }

    changeHandler(e: LayersChangedEvent) {
            this.stupid =!this.stupid;
            this.layer = Services.RenderLayerService.getSelectedLayer();
            this.$forceUpdate()
    }

    mounted(){
        this.layerCanBeDeleted = Services.RenderLayerService.getLayers().length >= 2;
        Services.RenderLayerService.addEventListener("LayersChanged", this.changeHandler)
        Services.RenderLayerService.addEventListener("SelectedLayerChanged", this.changeHandler)
    }

    t2s(t: number): string {
        return FormattingService.time_to_string(t);
    }

    selectSource(layer: RenderLayer, slot: RenderSourceSlot, name: string, filter){
        if(!filter){
            if(slot.source){
                filter = slot.source.layer.layer_type;
            }else if(slot.shaderName == "displacement"){
                filter = "ScalarTiles";
            }else if(slot.type == 'points'){
                filter = "ScalarPoints";
            }
        }

        Services.DialogBoxService.insert("Select Source", DialogBox.centered("SourceSelectionDialog", `Select ${slot.name} Layer`, {
            currentSource: slot.source,
            sourceSelectionCallback: (sli: SourceLayerInfo) => {
            Services.RenderLayerService.setSourceOnLayer(layer, name, sli);
            }, typeFilter: filter
        }));
    }

    filterFromLayerAndIndex(layer: RenderLayer, index: number): string{
        try{
            return layer.source.slots[index].source.layer.layer_type;
        }catch{
            return "";
        }
    }

    selectLayer(){
        Services.RenderLayerService.selectLayer(this.$props.layer);
    }

    getColormapNames(){
        return Services.ColormapService.getColormapNames();
    }

    getCSSGradient(name){
        return Services.ColormapService.get_CSSGradient(name);
    }

    mousedown_evt(e){
        Services.InteractionService.mousedown("layercomponent");
    }
}
</script>

<style lang="scss">
    @import '../_guikit.scss';

   option{
        color: black !important;
    }

    .layer-component-label{
        font-size: 120%;
        font-weight: bold;
        border-bottom: 1px solid $fg_color;
        grid-column-start: 1;
        grid-column-end: span 3;
    }

    .layer-component-container{
        min-width: 22em;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

    .layer-component-source-slots{
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 0.5em;
        column-gap: 0.2em;
    }

    .layer-component-source-slots>*{
        text-align: left;
    }

    .triple-col{
        grid-column-start: 1;
        grid-column-end: span 3;
    }

    div.layer-component-attribution>p{
        margin: 0;
    }

</style>