//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { LayerFilter } from "./RenderFilter";
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";

export class DomeLightingFilter extends LayerFilter{
    constructor(){
        super(Services.GLService.Modules.effects.dome_lighting, {
            "shading_intensity": new Parameter("Shading Intensity", 1, "number", true, false).setShaderName("shading_intensity").setRange(0, 1),
            "shading_falloff": new Parameter("Shading Falloff", 0.5, "number", true, false).setShaderName("shading_falloff").setRange(0, 1),
            "spread": new Parameter("Coverage Size", 2, "number", true, false).setShaderName("spread").setRange(1, 10),
            "depth_falloff": new Parameter("Depth Falloff", 5, "number", true, false).setShaderName("depth_falloff").setRange(1, 25),
            "brightness": new Parameter("Brightness", 1, "number", true, false).setShaderName("brightness").setRange(0, 5),
            "near_ratio": new Parameter("near_ratio", 0.025 / 3, "number", false).setShaderName("near_ratio")
        });
        this.human_readable_name = "Eyedome Lighting";
        this.name = "DomeLighting";
    }
}