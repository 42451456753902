<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="foldgroup-container">
        <div class="foldgroup-header" :foldedOut="foldedOut" @click="toggleFold">
            <SvgIcon class="foldgroup-icon" :name="foldedOut?'down':'right'"></SvgIcon>
            <span class="foldgroup-title">{{name}}</span>
        </div>
        <div class="foldgroup-content" :style="{display: foldedOut? 'block' : 'none'}">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss">
    @import '../../_guikit.scss';
    .foldgroup-container{
        @extend %generic_box;
        border: 1px solid $control_color;
        padding: 0;
    }
    .foldgroup-header{
        @extend %generic_button;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        font-size: 112%;

        padding: 0.5 * $grid;
        margin: 0;
    }
    .foldgroup-header[foldedOut]{
        margin-bottom: calc(0.5 * $grid);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .foldgroup-content{
        padding: 0.5 * $grid;
    }
    .foldgroup-icon{
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
    }
</style>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './svgIcon.vue';
@Component({
    props:{
        name: String,
        expanded: {
            type: Boolean,
            default: false
        }
    },
    components:{
        SvgIcon
    }
})
export default class FoldGroup extends Vue {
    private foldedOut: boolean = false;

    toggleFold(){
        this.foldedOut = !this.foldedOut;
    }

    mounted() {
       this.foldedOut = this.$props.expanded;
    }
}

</script>