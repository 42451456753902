//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { Services } from './Services';
import { SourceLayerInfo } from './SourceInfoService';
import { glenv } from './RenderService';

export class Particles {
    private particle_data_0: WebGLTexture; //even indices
    private particle_data_1: WebGLTexture; //odd indices
    private framebuffer_0: WebGLFramebuffer; //even indices
    private framebuffer_1: WebGLFramebuffer; //odd indices
    public frames_unused: number = 0;
    private index: number = 0;
    public origin: ParticlesRequest;
    
    public speed: number;
    public height: number;
    public time: number;
    public lifetime: number;

    constructor(env: glenv, origin: ParticlesRequest, size: number, depth_rb: WebGLRenderbuffer){
        let gl: WebGLRenderingContext = env.gl;
        this.origin = origin;
        let extent = origin.sourceu.layer.extent;
        let random_array = new Float32Array(4 * size * 8 * size);
        for(var i = 0; i < random_array.length; i+= 4){
            random_array[i] = Math.random();
            //Correct density for smaller differential area near the poles
            random_array[i + 1] = 1 - Math.acos(2.0 * Math.random() - 1.0) / Math.PI;
            random_array[i + 2] = origin.lifetime * (Math.random() + 0.5);
        }
        this.particle_data_0 = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.particle_data_0);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, size * 8, size, 0, gl.RGBA, gl.FLOAT, random_array);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        this.framebuffer_0 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer_0);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.particle_data_0, 0);
        gl.bindRenderbuffer(gl.RENDERBUFFER, depth_rb);
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, depth_rb);
        this.particle_data_1 = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.particle_data_1);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, size * 8, size, 0, gl.RGBA, gl.FLOAT, random_array);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        this.framebuffer_1 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer_1);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.particle_data_1, 0);
        gl.bindRenderbuffer(gl.RENDERBUFFER, depth_rb);
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, depth_rb);
        this.frames_unused = 0;
        this.origin = origin;
    }

    //Gets the last frame's particle locations, this frame's target framebuffer and toggles the flag. Call only once per frame.
    get_fb_data_pingpong(): {
            target: WebGLFramebuffer,
            targetOffset: number,
            source: WebGLTexture
            sourceOffset: number,
        } {
        let sourceOffset = Math.floor(this.index / 2);
        this.index += 1;
        this.index %= 16;
        let targetOffset = Math.floor(this.index / 2);
        return this.index & 1 ? {
            //targetOffset: 0,
            target: this.framebuffer_1,
            targetOffset: targetOffset,
            //sourceOffset: 0,
            source: this.particle_data_0,
            sourceOffset: sourceOffset
        } : {
            target: this.framebuffer_0,
            //targetOffset: 0,
            targetOffset: targetOffset,
            source: this.particle_data_1,
            //sourceOffset: 0,
            sourceOffset: sourceOffset
        };
    }

    //Only gets the texture without changing state
    get_data(): {source: WebGLTexture, sourceOffset: number} {
        return this.index & 1 ? {
            source: this.particle_data_1,
            sourceOffset: Math.floor(this.index / 2)
            //sourceOffset: 0
        } : {
            source: this.particle_data_0,
            sourceOffset: Math.floor(this.index / 2)
            //sourceOffset: 0
            }
    }

    remove(env: glenv){
        env.gl.deleteTexture(this.particle_data_0);
        env.gl.deleteTexture(this.particle_data_1);
        env.gl.deleteFramebuffer(this.framebuffer_0);
        env.gl.deleteFramebuffer(this.framebuffer_1);
    }
}

class ParticlesRequest {
    sourceu: SourceLayerInfo;
    sourcev: SourceLayerInfo;
    time: number;
    height: number;
    speed: number;
    lifetime: number;

    constructor(sourceu: SourceLayerInfo, sourcev: SourceLayerInfo, time: number, height: number, speed: number, lifetime: number) {
        this.sourceu = sourceu;
        this.sourcev = sourcev;
        this.time = time;
        this.height = height;
        this.speed = speed;
        this.lifetime = lifetime;
    }
}

export class ParticlesService {

    private particle_sets: Map<string, Particles>;
    private requested_particle_sets: ParticlesRequest[] = [];

    private shared_depth_buffer: WebGLRenderbuffer;

    private global_noise_texture: WebGLTexture;

    private gl: glenv;

    private particle_count_sqr: number = 256;


    constructor(gl: glenv){
        this.particle_count_sqr = Math.sqrt(Services.GLService.Geometries.tracers.length);
        this.gl = gl;
        this.particle_sets = new Map();

        let gl_: WebGLRenderingContext = gl.gl;
        this.shared_depth_buffer = gl_.createRenderbuffer();
        gl_.bindRenderbuffer(gl_.RENDERBUFFER, this.shared_depth_buffer);
        gl_.renderbufferStorage(gl_.RENDERBUFFER, gl_.DEPTH_COMPONENT16, this.particle_count_sqr * 8, this.particle_count_sqr);
    
        this.global_noise_texture = gl_.createTexture();
        gl_.bindTexture(gl_.TEXTURE_2D, this.global_noise_texture);

        let random_array = new Float32Array(4 * this.particle_count_sqr * this.particle_count_sqr);
        for(var i = 0; i < random_array.length; i++){
            random_array[i] = Math.random();
        }

        gl_.texImage2D(gl_.TEXTURE_2D, 0, gl_.RGBA, this.particle_count_sqr, this.particle_count_sqr, 0, gl_.RGBA, gl_.FLOAT, random_array);
        gl_.texParameteri(gl_.TEXTURE_2D, gl_.TEXTURE_MAG_FILTER, gl_.NEAREST);
        gl_.texParameteri(gl_.TEXTURE_2D, gl_.TEXTURE_MIN_FILTER, gl_.NEAREST);
        gl_.texParameteri(gl_.TEXTURE_2D, gl_.TEXTURE_WRAP_S, gl_.CLAMP_TO_EDGE);
        gl_.texParameteri(gl_.TEXTURE_2D, gl_.TEXTURE_WRAP_T, gl_.CLAMP_TO_EDGE);

    }

    prepareParticles() {
        let gl = this.gl.gl;
        gl.disable(gl.DEPTH_TEST);
        gl.viewport(0, 0, this.particle_count_sqr * 8, this.particle_count_sqr);
        let shader = Services.GLService.Modules.particles.particles;
        gl.useProgram(shader.program);

        this.particle_sets.forEach((particles, path) => {
            particles.frames_unused += 1;
            if(particles.frames_unused > 32){
                particles.remove(this.gl);
                this.particle_sets.delete(path);
            }
        });
        this.particle_sets.forEach((particles) => {
            this.calculateOneParticleSet(particles);
        });
        this.requested_particle_sets.forEach((req) => {
            let path = this.particlesPath(req.sourceu, req.sourcev);
            let new_particles = new Particles(this.gl, req, this.particle_count_sqr, this.shared_depth_buffer);
            this.calculateOneParticleSet(new_particles);
            this.particle_sets.set(path, new_particles);
        });
        this.requested_particle_sets = [];
        gl.enable(gl.DEPTH_TEST);
    }

    calculateOneParticleSet(particles: Particles) {
        Services.AdaptivePerformanceService.RequestRerender();
        let gl = this.gl.gl;
        let shader = Services.GLService.Modules.particles.particles;
        let buff = Services.GLService.Geometries.quad;

        let stitched = Services.StitchedTilesService.getStitchedVector2Tiles(particles.origin.sourceu, particles.origin.sourcev, particles.time, particles.height);
        if(!stitched)return;

        let fb_data = particles.get_fb_data_pingpong();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb_data.target);
        gl.uniform1f(shader.uniforms["target_offset"], fb_data.targetOffset);
        //gl.bindFramebuffer(gl.FRAMEBUFFER, null);

        let texi = 0;

        gl.enableVertexAttribArray(shader.attributes["position"]);
        gl.bindBuffer(gl.ARRAY_BUFFER, buff.buffer);
        gl.vertexAttribPointer(shader.attributes["position"], 2, gl.FLOAT, false, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT);

        gl.activeTexture(gl.TEXTURE0 + texi);
        gl.bindTexture(gl.TEXTURE_2D, fb_data.source);
        gl.uniform1i(shader.uniforms["particles"], texi);
        gl.uniform1f(shader.uniforms["source_offset"], fb_data.sourceOffset);
        texi++;

        gl.activeTexture(gl.TEXTURE0 + texi);
        gl.bindTexture(gl.TEXTURE_2D, stitched.texture);
        gl.uniform1i(shader.uniforms["vector_field"], texi);
        gl.uniform2f(shader.uniforms["vector_field_coord_offset"], stitched.coord_offset[0], stitched.coord_offset[1]);
        gl.uniform2f(shader.uniforms["vector_field_coord_scale"], stitched.coord_scale[0], stitched.coord_scale[1]);
        texi++;

        gl.activeTexture(gl.TEXTURE0 + texi);
        gl.bindTexture(gl.TEXTURE_2D, this.global_noise_texture);
        gl.uniform1i(shader.uniforms["global_random"], texi);

        gl.uniform1f(shader.uniforms["particle_speed"], particles.speed);
        gl.uniform1f(shader.uniforms["particle_lifetime"], particles.lifetime);
        gl.uniform2f(shader.uniforms["frame_random"], Math.random(), Math.random());
        gl.drawArrays(gl.TRIANGLE_STRIP, buff.start, buff.length)


        // Debugging
        /*
        let dp = Services.GLService.Modules.viewport.copy;

        gl.useProgram(dp.program);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);

        gl.uniform1i(dp.uniforms["color_map"], 0);
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, particles.get_data());

        gl.enableVertexAttribArray(shader.attributes["position"]);
        gl.bindBuffer(gl.ARRAY_BUFFER, buff.buffer);
        gl.vertexAttribPointer(shader.attributes["position"], 2, gl.FLOAT, false, 0, 0);

        gl.drawArrays(gl.TRIANGLE_STRIP, buff.start, buff.length);
        */
    }

    getParticleCount() {
        return this.particle_count_sqr * this.particle_count_sqr;
    }

    getParticles(sourceu: SourceLayerInfo, sourcev: SourceLayerInfo, time: number, height: number, speed: number, lifetime: number): Particles {
        if(sourceu.layer.layer_type != "ScalarTiles" || sourcev.layer.layer_type != "ScalarTiles")  return;
        let p = this.particlesPath(sourceu, sourcev);
        if(this.particle_sets.has(p)){
            let t = this.particle_sets.get(p);
            t.frames_unused = 0;
            t.speed = speed;
            t.time = time;
            t.height = height;
            t.lifetime = lifetime;
            return t;
        }
        this.requested_particle_sets.push(new ParticlesRequest(sourceu, sourcev, time, height, speed, lifetime));
    }

    particlesPath(sourceu: SourceLayerInfo, sourcev: SourceLayerInfo): string {
        return sourceu.instance_name + "/" + sourceu.layer_name + "/" + sourcev.instance_name + "/" + sourcev.layer_name;
    }
}