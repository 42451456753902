<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
<div>
    <div id="orb-container" @mousedown.stop="mousedown">
        <svg viewBox="-1 -1 2 2">
            <g>
            <circle cx="0" cy="0" r="0.8" fill="transparent" id="outer"/>
            <!--<circle cx="0" cy="0" r="0.8" transform="scale(1.0, 0.1)" />-->
            <path id="inner" :d="`
                M -0.8 0
                A 0.8 0.8 0 0 1 0.8 0
                A 0.8 ${0.8 * Math.abs(value.x1 / value.abs())} 0 0 ${value.x1 > 0 ? 1 : 0} -0.8 0
                Z
            `"
            :transform="`rotate(${180 / Math.PI * Math.atan2(value.x2, value.x3)})`"
            />
            </g>
        </svg>
        <div v-show="value.x1 > -0.1" class="light-marker" :style="{left: sun_left, top: sun_top}">
            <SvgIcon class="icon-outline" style="position: absolute" name="sun"></SvgIcon>
            <SvgIcon style="position: absolute" name="sun"></SvgIcon>
        </div>
        <div v-show="value.x1 < 0.1" class="light-marker" :style="{left: moon_left, top: moon_top}">
            <SvgIcon class="icon-outline" style="position: absolute" name="moon"></SvgIcon>
            <SvgIcon style="position: absolute" name="moon"></SvgIcon>
        </div>
    </div>
</div>
</template>
<style scoped lang="scss">
    @import '../../_guikit.scss';

    $orb_size: 10 * $grid;

    svg {
        stroke: transparent;
        fill: transparent;
        height: 100%;
        width: 100%;
    }
    #orb-container {
        @extend %generic_box;
        background-color: $control_color;
        position: relative;
        height: $orb_size;
        width: $orb_size;
        transform: translate(50%, 0);
    }
    circle#outer {
        stroke-width: calc(0.25rem / $orb_size);
        stroke: $fg_color;
    }
    path#inner {
        fill: $fg_color;
    }

    .icon-outline {
        stroke-width: 1.5px;
        stroke: $control_color;
    }

    .light-marker {
        position: absolute;
        transform: translate(-0.5em, -0.5em);
        top: 0;
        left: 0;
        font-size: 1.75rem;
    }
</style>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Vec2, Vec3, Mat3 } from '../../modules/vecmat';
import { Services } from '../../services/Services';
import SvgIcon from './svgIcon.vue';
@Component({
    components: {
        SvgIcon
    },
    props:{
        value: {
            type: Vec3,
            default: new Vec3(1, 1, 1)
        }
    }
})
export default class VectorInput extends Vue{
    uid;
    start_value: Vec3;
    start_mouse: Vec2;

    get sun_top() {
        return (1 - this.$props.value.norm().x3 * 0.8) * 50 + "%";
    }

    get sun_left() {
        return (1 + this.$props.value.norm().x2 * 0.8) * 50 + "%";
    }

    get moon_top() {
        return (1 + this.$props.value.norm().x3 * 0.8) * 50 + "%";
    }

    get moon_left() {
        return (1 - this.$props.value.norm().x2 * 0.8) * 50 + "%";
    }

    mounted(){
        this.uid = Services.UIDService.getUid();
        Services.InteractionService.mouseMoveHandlers.set(this.uid, this.mousemove);
        Services.InteractionService.mouseUpHandlers.set(this.uid, this.mouseup);
    }

    destroyed(){
        Services.InteractionService.mouseMoveHandlers.delete(this.uid);
        Services.InteractionService.mouseUpHandlers.delete(this.uid);
    }

    setValue(v){
        this.$emit("input", v);
    }

    mousedown(e: MouseEvent){
        Services.InteractionService.mousedown(this.uid, e);
        this.start_value = this.$props.value;
        this.start_mouse = new Vec2(e.clientX, e.clientY);
    }

    mousemove(e: MouseEvent) {
        if(e.buttons == 1){
            let delta = new Vec2(e.clientX, e.clientY).sub(this.start_mouse);
            let r_axis = new Vec3(0.0, delta.x2, delta.x1);
            let r_amount = delta.abs() / 100;
            this.setValue(Mat3.rot(r_amount, r_axis).mul_vec3(this.start_value).norm());
        }
    }

    mouseup(e: MouseEvent) {

    }
}
</script>
