//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

/*
This service loads and distributes the colormaps that have been aggregated into the colormap file.
*/
import {colormaps as maps} from '../colormaps/colormap';

export class Colormap {
    
}

export class ColormapService{
    constructor(gl: WebGLRenderingContext){
        this.initColormaps(gl);
    }

    //Colormaps get stored internally as WebGLTextures, so the samplers can easily use them
    private colormaps: {[s: string]: WebGLTexture} = {};
    private precomputed_gradients = {};


    //Get the names of all colormaps as an array of string
    getColormapNames(): string[]{
        return Object.keys(this.colormaps);
    }

    //Get the webgl texture for a certain colormap by it's name (from the list from getColormapNames())
    getColormapByName(name: string): WebGLTexture{
        return this.colormaps[name];
    }

    //Upload the colormaps from the maps object to the GPU as textures
    private initColormaps(gl: WebGLRenderingContext){
        this.colormaps = {};
        maps.forEach(m => {
            let t = gl.createTexture();
            gl.bindTexture(gl.TEXTURE_2D, t);
            gl.texImage2D(
                gl.TEXTURE_2D,
                0,
                gl.RGB,
                m.tex_buffer.length / 3,
                1,
                0,
                gl.RGB,
                gl.UNSIGNED_BYTE,
                m.tex_buffer);
            if(m.linear_interpolation){
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
            }else{
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
            }
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.bindTexture(gl.TEXTURE_2D, null);
            this.colormaps[m.name] = t;
            this.precomputed_gradients[m.name] = m.c3g_string;
        });
    }



    //Create a CSS gradient (i.e. for the colormap picker) from one of the maps. The direction can also be given and defaults to "right"
    //If the name doesn't match any colormaps, it defaults to a black background
    get_CSSGradient(name: string, direction="right"): string{
        if(this.precomputed_gradients[name]){
            return `linear-gradient(to ${direction}, ${this.precomputed_gradients[name]})`;
        }else{
            return "black"
        }
    }

    getNextColormap(currentName: string): string{
        let keys = this.getColormapNames();
        let index = keys.indexOf(currentName);
        if(index >= keys.length - 1){
            return keys[0];
        } else {
            return keys[index + 1];
        }
    }
    
    getPreviousColormap(currentName: string): string{
        let keys = this.getColormapNames();
        let index = keys.indexOf(currentName);
        if(index <= 1){
            return keys[keys.length - 1];
        } else {
            return keys[index - 1];
        }
    }
}