<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
<span style="user-select: default">
    <span class="color-dot" :style="{backgroundColor: color}"></span>{{value}}
</span>
</template>

<style>
    .color-dot{
        display: inline-block;
        width: 1em;
        height: 1em;
        border-radius: 1em;
        margin-right: .5em;
    }
</style>

<script lang="ts">
import Vue from 'vue';
import { Overlay } from "../../services/OverlayService";
import { FormattingService } from "../../services/FormattingService";
import Component from 'vue-class-component';
@Component({
    props:{
        overlay: Overlay
    }
})
export default class ScalarOverlay extends Vue {
    get value() {
        let info = this.$props.overlay.information;
        return FormattingService.color_0_1_to_hex(info[0], info[1], info[2], "#");
    }

    get color(): string{
        let info = this.$props.overlay.information;
        return "rgba(" + info[0] * 255 + "," + info[1] * 255 + "," + info[2] * 255 + "," + info[3] + ")";
    }
}
</script>