<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="parameter">
        <span class="first-col" :key="'pf_' + param.name">{{param.name}}</span>
        <template v-if="param.type == 'number'">
            <NumberInput class="double-col" :key="'pn_' + param.name" v-model="param.value" :min="min" :max="max" :step="param.step" :unit="param.unit" @input="change();"/>
        </template>
        <template v-else-if="param.type == 'vector3D'">
            <VectorInput class="double-col" :key="'pn_' + param.name" v-model="param.value" @input="change();"/>
        </template>
        <template v-else-if="param.type == 'boolean'">
            <input type="checkbox" v-model="param.value" class="second-col" :key="'pc_' + param.name" @input="change();" />
        </template>
        <template v-else-if="param.type=='colormap'">
            <button
                class="dropdown-button double-col"
                :style="{background: getCSSGradient(param.value), color: 'white', textShadow: '0px 0px 4px black'}"
                @click="toggle_dropdown()"
                @keydown="arrow_key_interaction($event)"
            >
                <span class="dd-label">
                    <span class="dd-icon">
                        <SvgIcon name="down"></SvgIcon>
                    </span>
                    {{ param.value }}
                </span>
                <div class="colormap-dropdown" v-show="dropdown_open">
                    <div class="colormap-dropdown-entry" 
                    v-for="colormap_name in getColormapNames()" 
                    :key="colormap_name"
                    :style="{background: getCSSGradient(colormap_name), color: 'white', textShadow: '0px 0px 4px black'}"
                    @click="set_value(colormap_name)"
                >{{colormap_name}}</div>
                </div>
            </button>

        <!--
            <select class="double-col" v-model="param.value" :style="{background: getCSSGradient(param.value), color: 'white', textShadow: '0px 0px 4px black'}" @input="change();">
                <option 
                    v-for="colormap_name in getColormapNames()" 
                    :key="colormap_name"
                >{{colormap_name}}</option>
            </select>
        -->
        </template>
        <template v-else-if="param.type=='select'">
            <select class="double-col" v-model="param.value" @input="change();">
                <option 
                    v-for="option in param.options" 
                    :key="option"
                >{{option}}</option>
            </select>
        </template>
        
        <template v-else>
            <input type="text" v-model="param.value" class="second-col" :key="'pe_' + param.name" @input="change();" @change="change();" />
            <span v-if="param.unit" class="third-col" :key="'pe2_' + param.name">{{param.unit}}</span>
        </template>
        <div v-if="param.Actions.length > 0" class="triple-col actions">
            <button @click="triggerAction(action)" v-for="action in param.Actions" :key="action.Name">{{action.Name}}</button>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Parameter, ParameterAction } from '../modules/Parameter';
import { Services } from '../services/Services';
import NumberInput from './guikit/numberinput.vue';
import VectorInput from './guikit/vectorinput.vue';
import SvgIcon from './guikit/svgIcon.vue';

@Component({
    components: {
        NumberInput,
        VectorInput,
        SvgIcon
    },
    props:{
        param: Parameter
    }
})
export default class ParameterComponent extends Vue{
[x: string]: any;
    min = null;
    max = null;

    dropdown_open = false;

    toggle_dropdown(){
        this.dropdown_open = !this.dropdown_open;
    }
    set_value(cn){
        this.dropdown_open = true;
        this.$props.param.value = cn;
        this.change();
    }
    arrow_key_interaction(e){
        if(e.key=="ArrowDown"){
            this.$props.param.value = Services.ColormapService.getNextColormap(this.$props.param.value);
        }else if(e.key=="ArrowUp"){
            this.$props.param.value = Services.ColormapService.getPreviousColormap(this.$props.param.value);
        }
        this.change();
    }

    mounted(){
        if(this.$props.param.range){
            this.min = this.$props.param.range[0];
            this.max = this.$props.param.range[1];
        }
    }

    triggerAction(action: ParameterAction){
        action.Action();
        Services.AdaptivePerformanceService.RequestRerender();
        if(this.$props.refreshRequester){
            this.$props.refreshRequester();
        }
    }

    getColormapNames(){
        return Services.ColormapService.getColormapNames();
    }

    getCSSGradient(name){
        return Services.ColormapService.get_CSSGradient(name);
    }

    change(){
        Services.AdaptivePerformanceService.RequestRerender();
        this.$emit("change");
    }



}
</script>

<style lang="scss">
    @import '../_guikit.scss';

    .dd-icon {
        display: inline-block;
        width: 1em;
    }
    .dd-label {
        display: grid;
        grid-template-columns: 1em auto;
        justify-items: center;
    }

    .dropdown-button {
        @extend %generic_box_shadowed;
        text-align: center;
    }
    .dropdown-button:hover
    .colormap-dropdown-entry:hover {
        box-shadow: 0px 0px calc($grid * 3) calc($grid * 3) rgba(255, 255, 255, 0.05) inset;
    }
    .dropdown-button:focus{
        box-shadow: 0px 0px calc($grid / 3) calc($grid / 6) $hl_color inset;
    }

    .colormap-dropdown{
        @extend %generic_box;
        display: grid;
        grid-template-columns: auto;
        gap: $grid;
        position: fixed;
        z-index: 1000000000;
        height: 50 * $grid;
        overflow-y: auto;
        width: 66%;
        transform: translate(-2 * $grid, $grid);
    }
    .colormap-dropdown-entry{
        @extend %generic_button;
    }

    .parameter{
        //width: 100%;
        display: grid;
        grid-template-columns: 33% 56% 10%;
        //position: relative;
        gap: 0.5em; /*TODO unify */
        overflow: visible;
        align-items: center;
        align-content: center;
        margin-top: 0.2em;
        margin-bottom: 0.2em;
    }

    .first-col{
        grid-column: 1;
        min-width: calc(2 * $grid);
    }

    .second-col{
        grid-column: 2;
        min-width: calc(2 * $grid);
    }

    .third-col{
        grid-column: 3;
        min-width: calc(2 * $grid);
    }

    .double-col{
        grid-column-start: 2;
        grid-column-end: span 2;
    }

    .triple-col{
        grid-column-start: 1;
        grid-column-end: span 3;
    }

    .actions{
        display: flex;
        align-content: space-between;
        justify-content: space-around;
    }

</style>