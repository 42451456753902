<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="dialog-host">
        <DialogBoxComponent v-for="[id, dbox] in dialogBoxes" :key="id" :box_id="id" :dbox="dbox">
            <component :is="dbox.containedComponent" :data="dbox.data"></component>
        </DialogBoxComponent>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import {ServiceBarrier, Services} from '../services/Services';
import {DialogBox} from '../services/DialogBoxService';
import Settings from './settingscomponent.vue';
import AboutDialogue from './aboutdialogue.vue';
import DialogBoxComponent from './dialogbox.vue';
import TypeSelectionDialog from './typeselectiondialog.vue';
import LayerStack from './layerstack.vue';
import SourceSelectionDialog from './sourceselectiondialog.vue';
//import GraphComponent from './graphcomponent.vue';
import PlaybackComponent from './playbackcomponent.vue';
import LayerComponent from './layercomponent.vue';
import DebugComponent from './debugcomponent.vue';
import RecordingPlaybackComponent from './recordingplayback.vue';
import ProgramStatusComponent from './programstatuscomponent.vue';
import SaveDialogue from './savedialogue.vue';
import ManualComponent from './manualcomponent.vue';

@Component({
    components: {
        SvgIcon,
        Settings,
        AboutDialogue,
        DialogBoxComponent,
        TypeSelectionDialog,
        SourceSelectionDialog,
        LayerStack,
        //GraphComponent,
        PlaybackComponent,
        LayerComponent,
        DebugComponent,
        RecordingPlaybackComponent,
        ProgramStatusComponent,
        SaveDialogue,
        ManualComponent
    },
    props:{}
})
export default class dialogboxes extends Vue{

    dialogBoxes: Map<String, DialogBox> = new Map();
    startpos: DOMRect;


    async mounted() {
        await ServiceBarrier.wait();
        this.dialogBoxes = Services.DialogBoxService.dialogBoxes;
        let update_handler = () => {
            this.$forceUpdate();
        }
        Services.DialogBoxService.addEventListener("DialogBoxOpen", update_handler);
        Services.DialogBoxService.addEventListener("DialogBoxClose", update_handler);
        Services.DialogBoxService.addEventListener("DialogBoxFocus", update_handler);

    }

    closeDialog(id) {
        Services.DialogBoxService.remove(id);
    }

    dialog_mousedown(e, id) {
        Services.InteractionService.mousedown("dialog_" + id, e);
        let box_el = this.$refs["dbox-"+id][0];
        this.startpos = box_el.getBoundingClientRect();
    }

}
</script>