<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="display"
        @wheel="wheel_evt"
        @mousedown="mousedown_evt"
        @mouseup="mouseup_evt"
        @mouseleave="mouseout_evt"
        @touchstart="touchstart_evt"
        @dblclick="doubleclick_evt"
    >
        <canvas id="canvas" style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            "
        ></canvas>       
        <svg id="scale-svg" viewBox="0 0 1 1" style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        ">
        </svg>
        <svg id="measure-svg" viewBox="0 0 1 1" style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        ">
        </svg>
    </div>
</template>
<style lang="scss">
.display {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    user-select: none;
}
</style>
<script lang="ts">
import Vue from 'vue';
import { viewer_settings } from "./../settings.js";
import Component from 'vue-class-component';
import { Coord } from '../modules//tile';
import { ServiceBarrier, Services } from '../services/Services';



var world_pixel_size_vertical = (level) => {return Math.PI / Math.pow(2, level) / viewer_settings.threedee.tile_tex_res;}

@Component({})
export default class SourceComponent extends Vue{
    private gl_context: WebGLRenderingContext;
    private hostcanvas: HTMLCanvasElement;
    private width: number;
    private height: number;
    private max_tile_calculation_duration: number = 0;
    private last_async_time = 0;

    mounted() {
        Services.PositionService.forceFilteredToTarget();
        
        this.hostcanvas = this.$el.getElementsByTagName("canvas")[0];
        this.width = this.$el.clientWidth;
        this.height = this.$el.clientHeight;
        this.hostcanvas.width = this.width;
        this.hostcanvas.height = this.height;

        let bb = this.hostcanvas.getBoundingClientRect();
        Services.PositionService.setScreenDimensions(bb.width, bb.height);

        Services.AdaptivePerformanceService.SetClientDimensions(this.width, this.height);

        this.gl_context = this.hostcanvas.getContext("webgl");
        Services.initGL(this.gl_context);

        this.hostcanvas.oncontextmenu = function() {return false;};

        let svg_resize_handler = () => {
            let scale_svg = document.getElementById("scale-svg");
            let svg_size = scale_svg.getBoundingClientRect();
            let svg_height = svg_size.height;
            let svg_width = svg_size.width;
            scale_svg.setAttribute("viewBox", `0 0 ${svg_width} ${svg_height}`);
            let measure_svg = document.getElementById("measure-svg");
            svg_size = measure_svg.getBoundingClientRect();
            svg_height = svg_size.height;
            svg_width = svg_size.width;
            measure_svg.setAttribute("viewBox", `0 0 ${svg_width} ${svg_height}`);

        };
        svg_resize_handler();
        window.addEventListener("resize", svg_resize_handler);

        Services.RenderService.loop();

}

    wheel_evt(e) {
            Services.PositionService.zoomCamera(Math.sign(e.deltaY));
            if(e.ctrlKey)e.preventDefault();
    }
    
    mousedown_evt(e: MouseEvent) {
        Services.InteractionService.mousedown("threedee", e);
    }

    touchstart_evt(e: TouchEvent){
        Services.InteractionService.touchstart("threedee",e);
    }

    doubleclick_evt(e) {
        if(Services.LayerMetadataService.isUpToDate){
            let coords = Coord.from_UEC(Services.LayerMetadataService.coord);
            let vertical = 
                Services.RenderLayerService.getSelectedLayer().source.applyOffset(
                        Services.LayerMetadataService.z
                )
            
            let old_position =  Services.PositionService.getCameraPosition();
            Services.PositionService.setCameraPosition({
                Latitude: coords.lat,
                Longitude: coords.lon,
                Elevation: old_position.Elevation,
                Azimuth: old_position.Azimuth,
                Distance: old_position.Distance,
                VerticalPosition: vertical
            });
        }
    }
    
    mouseup_evt(e) {

    }
        
    mouseout_evt(e) {

    }

}
</script>