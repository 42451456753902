import { ColormapFilter } from '@/modules/renderfilters/ColormapFilter';
import { DomeLightingFilter } from '@/modules/renderfilters/DomeLightingFilter';
import { HillShadingFilter } from '@/modules/renderfilters/HillShadingFilter';
import { Filter, LayerFilter } from '@/modules/renderfilters/RenderFilter';
import { RenderGroup, SerializedRenderGroup } from '@/modules/RenderGroup';
import { RenderLayer, RenderLayerFactory } from '@/modules/RenderLayer';
import { UEC } from '@/modules/tile';
import { Vec2, Vec3 } from '@/modules/vecmat';
import { SerializedOverlay } from '@/services/OverlayService';
import { DialogBox, SerializedDialog } from './DialogBoxService';
import { CameraPosition } from './PositionService';
import { Services } from './Services';

const SERIALIZATION_VERSION = 1.0;

export type SceneConfig = {
    timerange: [number, number],
    selectedLayerName: string,
    layers: SerializedRenderLayer[],
    groups?: SerializedRenderGroup[],
    default_group_id?: number,
    selected_group_id?: number,
    dialogs: SerializedDialog[],
    cameraPosition: CameraPosition,
    version?:number
};

export type SerializedRenderLayer = {
    id: number,
    slots: {"slot": string, "path": string}[],
    overlays: SerializedOverlay[],
    source_params: {"parameter": string, "value": any}[],
    filters: SerializedRenderFilter[],
    composition_parameters: {"parameter": string, "value": any}[],
    info: {
        "visible": boolean,
        "name": string,
        "typeHint": string
    };
}

export type SerializedRenderFilter = {
    name: string,
    human_readable_name: string,
    parameters: {"parameter": string, "value": any}[]
}

export class SerializationService{
    public getSceneString(): string{
        return JSON.stringify(this.serializeScene(), null, 4);
    }

    public async deserializeScene(scene: SceneConfig){
        console.log("Deserializing Scene", scene);
        if(!scene.version){
            await this.deserializeScene_legacy(scene);
        }else if(scene.version == 1.0){
            await this.deserializeScene_v1(scene);
        }
        Services.AdaptivePerformanceService.RequestRerender();
    }

    public async deserializeScene_v1(config: SceneConfig){
        //Extract layer paths and wait for the sources to become available
        let paths = config.layers.flatMap(l => l.slots.map(s => s.path)).filter(s => !!s);
        await Services.SourceInfoService.wait_for_layers_to_be_available(paths);
        console.log("Layers available");

        //Clear existing layers
        let layers = Services.RenderLayerService.getLayers();
        for (let l of layers){
            Services.RenderLayerService.deleteLayer(l);
        }

        //Create the layers from the config
        for(var layer of config.layers){
            let new_layer = this.deserializeRenderLayer(layer, 1.0);
            Services.RenderLayerService.addLayer(new_layer);
            //If the layer was previously selected, select it now
            if(new_layer.name == config.selectedLayerName){
                Services.RenderLayerService.selectLayer(new_layer);
            }
            if(layer.overlays)for(let o of layer.overlays){
                Services.OverlayService.addOverlay(new UEC(o.location[0], o.location[1]), o.location[2], new_layer, o.information);
            }
        }
        if(config.layers?.length > 0){
            let max_id = config.layers.map(l => l.id).reduce((a,b) => Math.max(a,b), 0);
            Services.UIDService.advanceTopicIdTo("layers", max_id + 1);
        }

        if(config.groups){
            Services.RenderLayerService.groups = new Map();
            for(let serialized_group of config.groups){
                let render_group = RenderGroup.deserialize(serialized_group);
                Services.RenderLayerService.groups.set(render_group.id, render_group);
            }
            if(config.default_group_id != null){
                let default_group = Services.RenderLayerService.groups.get(config.default_group_id);
                Services.RenderLayerService.default_group = default_group;
            }
            if(config.selected_group_id != null){
                let selected_group = Services.RenderLayerService.groups.get(config.selected_group_id);
                Services.RenderLayerService.selected_group = selected_group;
            }
            if(config.groups.length > 0){
                let max_id = config.groups.map(g => g.id).reduce((a,b) => Math.max(a,b), 0);
                Services.UIDService.advanceTopicIdTo("layers", max_id + 1);
            }
        }

        if(config.dialogs)
            for(var dialog of config.dialogs){
                Services.DialogBoxService.insert(dialog.name, DialogBox.fromSerialized(dialog))
            }

        //Set the camera position
        Services.PositionService.setCameraPosition(config.cameraPosition);
        //Set the time range
        Services.TimeService.setCurrentTimeRange(config.timerange[0], config.timerange[1]);
    }

    private async deserializeScene_legacy(config: SceneConfig){
        //Extract layer paths and wait for the sources to become available
        let paths = config.layers.flatMap(l => l.slots.map(s => s.path)).filter(s => !!s);
        await Services.SourceInfoService.wait_for_layers_to_be_available(paths);
        console.log("Layers available");

        //Clear existing layers
        let layers = Services.RenderLayerService.getLayers();
        for (let l of layers){
            Services.RenderLayerService.deleteLayer(l);
        }

        //Create the layers from the config
        for(var layer of config.layers){
            let new_layer = this.deserializeRenderLayerLegacy(layer);
            Services.RenderLayerService.addLayer(new_layer);
            //If the layer was previously selected, select it now
            if(new_layer.name == config.selectedLayerName){
                Services.RenderLayerService.selectLayer(new_layer);
            }
            if(layer.overlays)for(let o of layer.overlays){
                Services.OverlayService.addOverlay(new UEC(o.location[0], o.location[1]), o.location[2], new_layer, o.information);
            }
        }
        if(config.layers?.length > 0){
            let max_id = config.layers.map(l => l.id).reduce((a,b) => Math.max(a,b), 0);
            Services.UIDService.advanceTopicIdTo("layers", max_id + 1);
        }

        if(config.groups){
            Services.RenderLayerService.groups = new Map();
            for(let serialized_group of config.groups){
                let render_group = RenderGroup.deserialize(serialized_group);
                Services.RenderLayerService.groups.set(render_group.id, render_group);
            }
            if(config.default_group_id != null){
                let default_group = Services.RenderLayerService.groups.get(config.default_group_id);
                Services.RenderLayerService.default_group = default_group;
            }
            if(config.selected_group_id != null){
                let selected_group = Services.RenderLayerService.groups.get(config.selected_group_id);
                Services.RenderLayerService.selected_group = selected_group;
            }
            if(config.groups.length > 0){
                let max_id = config.groups.map(g => g.id).reduce((a,b) => Math.max(a,b), 0);
                Services.UIDService.advanceTopicIdTo("layers", max_id + 1);
            }
        }

        if(config.dialogs)
            for(var dialog of config.dialogs){
                Services.DialogBoxService.insert(dialog.name, DialogBox.fromSerialized(dialog))
            }

        //Set the camera position
        Services.PositionService.setCameraPosition(config.cameraPosition);
        //Set the time range
        Services.TimeService.setCurrentTimeRange(config.timerange[0], config.timerange[1]);
    }

    public serializeScene(): SceneConfig{
        return {
            cameraPosition: Services.PositionService.getCameraPosition(),
            layers: Services.RenderLayerService.getLayers().map(l => this.serializeRenderLayer(l)),
            groups: Services.RenderLayerService.getGroups().map(g => g.serialize()),
            default_group_id: Services.RenderLayerService.default_group.id,
            selected_group_id: Services.RenderLayerService.selected_group.id,
            dialogs: Services.DialogBoxService.serialize(),
            selectedLayerName: Services.RenderLayerService.selected_layer.name,
            timerange: Services.TimeService.getCurrentTimeRange(),
            version: SERIALIZATION_VERSION
        }
    }

    public serializeRenderLayer(layer: RenderLayer): SerializedRenderLayer{
        let serialized: any = {}; //TODO better type signature
        //0. Store id
        serialized.id = layer.id;
        //1. Store sources
        serialized.slots = [];
        for(var s in layer.source.slots){
            if(layer.source.slots[s]){
                if(layer.source.slots[s].source){
                    serialized.slots.push({
                        "slot": s,
                        "path": layer.source.slots[s].source.getPath()
                    });
                }else{
                    serialized.slots.push({
                        "slot": s,
                        "path": null
                    });
                }
                
            }
        }
        serialized.overlays = [];
        for(let o of Services.OverlayService.overlays){
            if(o.layer == layer){
                serialized.overlays.push(o.serialize());
            }
        }

        //2. Store source parameters
        serialized.source_params = [];
        for(var p in layer.source.parameters){
            serialized.source_params.push({
                "parameter": p,
                "value": layer.source.parameters[p].value
            })
        }
        //TODO maybe serialize the pipeline here?
        //3. store filter pipeline paramters
        serialized.filters = [];
        for(let f of layer.filterPipeline){
            let filter: SerializedRenderFilter = {
                name: f.name,
                human_readable_name: f.human_readable_name,
                parameters: []
            };

            for(let p in f.parameters){
                let param = f.parameters[p];
                filter.parameters.push({
                    parameter: p,
                    value: param.value
                });
            }
            serialized.filters.push(filter);
        }
        //4. store composition filter paramters
        serialized.composition_parameters = [];
        for(var p in layer.compositionFilter.parameters){
            serialized.composition_parameters.push({
                "parameter": p,
                "value": layer.compositionFilter.parameters[p].value
            });
        }
        //5. store additional information
        serialized.info = {
            "visible": layer.visible,
            "name": layer.name,
            "typeHint": layer.typeHint
        };
        //6. return serialized result
        return serialized;
    }

    public deserializeRenderLayer(serialized: SerializedRenderLayer, version = SERIALIZATION_VERSION): RenderLayer{
        if(version == null){
            return this.deserializeRenderLayerLegacy(serialized);
        }else if(version == 1.0){
            return this.deserializeRenderLayer_v1(serialized);
        }
    }

    public deserializeRenderLayer_v1(serialized: SerializedRenderLayer): RenderLayer{
        //1. Create layer
        let layer: RenderLayer;
        switch(serialized.info.typeHint){
            case "ColormapScalar": 
                layer = RenderLayerFactory.createColormapScalarLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data0")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path));
                break;
            case "ColormapDifference":
                layer = RenderLayerFactory.createColormapDifferenceLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data0")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data1")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path));
                break;
            case "Image":
                layer = RenderLayerFactory.createImageLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data0")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path));
                break;
            case "Points":
                console.log("Deprecated layer type: Points");
                break;
            case "ScalarPointCloud":
                layer = RenderLayerFactory.createPointCloudLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "points")?.path),
                );
                break;
            case "ColorPointCloud":
                layer = RenderLayerFactory.createColorPointCloudLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "points")?.path),
                );
                break;
            case "PointsHACK":
                console.log("Deprecated layer type: PointsHACK");
                break;
            case "Vec2Points":
                layer = RenderLayerFactory.createVec2PointsLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "points")?.path)
                );
                break;
            case "Vec2PointsHack":
                console.log("Deprecated layer type: Vec2PointsHack");
                break;
            case "Vector2":
                console.log("Deprecated layer type: Vector2");
                break;
            case "Vector2Arrow":
                    layer = RenderLayerFactory.createVector2ArrowLayer(
                        Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "datau")?.path),
                        Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "datav")?.path),
                        Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path)
                    );
                    break;
            case "Lines":
                layer = RenderLayerFactory.createLinesLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "lines")?.path)
                )
                break;
            case "Ocean":
                console.log("Deprecated layer type: Ocean");
                break;
            case "Atmosphere":
                console.log("Deprecated layer type: Atmosphere");
                break;
            case "PieChartPoints":
                let extra_slis = [];
                console.log(serialized);
                for(let i = 2; i <= 8; i++){
                    let source_info = Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "attribute" + i)?.path);
                    if(source_info){
                        extra_slis.push(source_info);
                    }
                }
                let attribute1 = Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "attribute1")?.path);
                if(!attribute1){
                    if(extra_slis.length > 0){
                        attribute1 = extra_slis.pop();
                    }
                }
                console.log("Extra slis: ", extra_slis);
                layer = RenderLayerFactory.createPieChartPointsLayer(
                    attribute1,
                    extra_slis
                );
                break;
            default:
                throw "No layer typeHint registered for " + serialized.info.typeHint;
        }
        //2. Set source params
        for(let p of serialized.source_params){
            layer.source.parameters[p.parameter].value = p.value;
        }

        
        //3. Set filter pipeline
        layer.filterPipeline = [];
        for(let f of serialized.filters){
            let filter_instance: LayerFilter = null;
            if(f.name == "colormap"){
                filter_instance = new ColormapFilter(layer.source.slots["data"]?.source);
            }else if(f.name == "DomeLighting"){
                filter_instance = new DomeLightingFilter();
            }else if(f.name == "HillShading"){
                filter_instance = new HillShadingFilter();
            }else{
                console.error("Unknown filter: " + f.name);
            }
            filter_instance['human_readable_name'] = f.human_readable_name;

            for(let p of f.parameters){
                //Hydrate value if necessary
                let value = p.value;
                let typeClass = filter_instance.parameters[p.parameter].type;
                if(typeClass == "vector3D"){
                    value = new Vec3(value.x1, value.x2, value.x3);
                }else if (typeClass == "vector2D"){
                    value = new Vec2(value.x1, value.x2);
                }
                filter_instance.parameters[p.parameter].value = value;
            }

            layer.filterPipeline.push(filter_instance);
        }
        //4. Set composition params
        for(let p of serialized.composition_parameters){
            layer.compositionFilter.parameters[p.parameter].value = p.value;
        }
        //5. Set additional info
        layer.name = serialized.info.name;
        layer.visible = serialized.info.visible;
        layer.id = serialized.id;
        return layer;
    }

    //@ts-ignore
    public deserializeRenderLayerLegacy(serialized: any): RenderLayer{
        //1. Create layer
        let layer: RenderLayer;
        switch(serialized.info.typeHint){
            case "ColormapScalar": 
                layer = RenderLayerFactory.createColormapScalarLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data0")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path));
                break;
            case "ColormapDifference":
                layer = RenderLayerFactory.createColormapDifferenceLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data0")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data1")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path));
                break;
            case "Image":
                layer = RenderLayerFactory.createImageLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "data0")?.path),
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path));
                break;
            case "Points":
                console.log("Deprecated layer type: Points");
                break;
            case "PointCloud":
                layer = RenderLayerFactory.createPointCloudLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "points")?.path),
                );
                break;
            case "PointsHACK":
                console.log("Deprecated layer type: PointsHACK");
                break;
            case "Vec2Points":
                layer = RenderLayerFactory.createVec2PointsLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "points")?.path)
                );
                break;
            case "Vec2PointsHack":
                console.log("Deprecated layer type: Vec2PointsHack");
                break;
            case "Vector2":
                console.log("Deprecated layer type: Vector2");
                break;
            case "Vector2Arrow":
                    layer = RenderLayerFactory.createVector2ArrowLayer(
                        Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "datau")?.path),
                        Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "datav")?.path),
                        Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "displacement")?.path)
                    );
                    break;
            case "Lines":
                layer = RenderLayerFactory.createLinesLayer(
                    Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "lines")?.path)
                )
                break;
            case "Ocean":
                console.log("Deprecated layer type: Ocean");
                break;
            case "Atmosphere":
                console.log("Deprecated layer type: Atmosphere");
                break;
            case "PieChartPoints":
                let extra_slis = [];
                console.log(serialized);
                for(let i = 2; i <= 8; i++){
                    let source_info = Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "attribute" + i)?.path);
                    if(source_info){
                        extra_slis.push(source_info);
                    }
                }
                let attribute1 = Services.SourceInfoService.get_source_layer_info_by_path(serialized.slots.find(s => s.slot == "attribute1")?.path);
                if(!attribute1){
                    if(extra_slis.length > 0){
                        attribute1 = extra_slis.pop();
                    }
                }
                console.log("Extra slis: ", extra_slis);
                layer = RenderLayerFactory.createPieChartPointsLayer(
                    attribute1,
                    extra_slis
                );
                break;
            default:
                throw "No layer typeHint registered for " + serialized.info.typeHint;
        }
        //2. Set source params
        for(let p of serialized.source_params){
            layer.source.parameters[p.parameter].value = p.value;
        }
        //3. Set filter params
        for(let p of serialized.filter_pipeline_params){
            //Hydrate value if necessary
            let value = p.value;
            let typeClass = layer.filterPipeline[parseInt(p.filter)].parameters[p.parameter].type;
            if(typeClass == "vector3D"){
                value = new Vec3(value.x1, value.x2, value.x3);
            }else if (typeClass == "vector2D"){
                value = new Vec2(value.x1, value.x2);
            }
            layer.filterPipeline[parseInt(p.filter)].parameters[p.parameter].value = value;
        }
        //4. Set composition params
        for(let p of serialized.composition_parameters){
            layer.compositionFilter.parameters[p.parameter].value = p.value;
        }
        //5. Set additional info
        layer.name = serialized.info.name;
        layer.visible = serialized.info.visible;
        layer.id = serialized.id;
        return layer;
    }
}