<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="compass-container">
    <div class="fadein" id="compass-box" @click="compass_click" :style="{'transform': `rotateX(${angleX}deg)`}">
    <svg id="compass-needle" ref="compass" width="75px" height="75px" viewBox="-10,-50,20,100" :style="{'transform': `rotate(${angleZ}deg)`}">
        <path id="cneedle" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" d="M -10,0 L 0,-50 L 10,0 L 0,50 Z"></path>
        <path id="cneedle_point" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" d="M -10,0 L 0,-50 L 10,0 L 0,-10 Z"></path>
    </svg>
    </div>
    <div v-show="valid_coords" id="compass-coords">{{coordstring}}<br>{{valuestring}}&nbsp;@&nbsp;{{heightstring}}</div>
    <div id="compass-lock" :class="{checked: rotation_locked}" @click="toggle_rotation_lock">
        <SvgIcon v-if="rotation_locked" name="lock"></SvgIcon>
        <SvgIcon v-else name="lock-open"></SvgIcon>
    </div>
    <div id="light-lock" :class="{checked: light_locked}" @click="toggle_light_lock">
        <SvgIcon name="sun"></SvgIcon>
    </div>
    </div>
</template>

<style lang="scss">
    @import "../_guikit.scss";
    #cneedle {
        fill: $guikit_lightgrey;
        stroke: $guikit_lightgrey;
    }

    #cneedle_point {
        fill: $guikit_lightred;
        stroke: $guikit_lightred;
    }

    #compass-needle{
        transition: border 0.2s;
        padding: 3px;
    }

    #compass-container{
        position: fixed;
        right: 0.2em;
        bottom: 0.2em;
        margin: 0;
        padding: 0;
        z-index: 10000;
    }

    #compass-coords{
        position: absolute;
        text-align: right;
        top: -2.5em;
        right: 0;
        padding: 0.2em;
        padding-right: 3px;
        font-size: 0.8em;
        color:  rgba(255,255,255,0.7);
    }

    #compass-lock{
        @extend %generic_button;
        position: absolute;
        width: 1.2em;
        height: 1.2em;
        font-size: 125%;
        top: 0;
        right: 0;
        padding: 0;
    }

    #light-lock{
        @extend %generic_button;
        position: absolute;
        width: 1.2em;
        height: 1.2em;
        font-size: 125%;
        top: 0;
        left: 0;
        padding: 0;
    }
    
    #compass-box{
        margin: 0;
        padding: 0;
        border-radius: 50%;
        background: rgba(0,0,0,0.25);
        border: 3px solid transparent;
    }

    #compass-box:active{
        padding-bottom: 0px;
        padding-right: 0px;
        border: 3px solid rgba(255,255,255,0.7);
    }
</style>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Services } from '../services/Services';
import { CameraPositionChangedEvent } from '../services/PositionService';
import { MouseMoveEvent } from '../services/InteractionService';
import { FormattingService } from '../services/FormattingService';
import SvgIcon from './guikit/svgIcon.vue';
@Component({
    components: {
        SvgIcon
    }
})
export default class Compass extends Vue{
    private angleZ = 0;
    private angleX = 0;
    private coordstring = "";
    private valuestring = "";
    private heightstring = "";
    private valid_coords = false;
    private rotation_locked = true;
    private light_locked = true;

    compass_click(e){
        let pos = Services.PositionService.getCameraPosition();
        if(e.ctrlKey){
            pos.Elevation = 90;
        }
        pos.Azimuth = 0;
        Services.PositionService.setCameraPosition(pos);
        Services.InteractionService.mousedown("compass");
    }

    toggle_rotation_lock(e){
        this.rotation_locked = !this.rotation_locked;
        Services.PositionService.rotation_locked = this.rotation_locked;
    }

    toggle_light_lock(e){
        this.light_locked = !this.light_locked;
        Services.PositionService.light_locked = this.light_locked;
    }

    async mounted(){
        Services.PositionService.addEventListener("CameraPositionChanged", (e: CameraPositionChangedEvent) => {
            this.angleZ = e.position.Azimuth;
            this.angleX = 60 - (e.position.Elevation / 3 * 2);
            }
        );
    Services.PositionService.addEventListener("RotationLockChanged", () => {
        this.rotation_locked = Services.PositionService.rotation_locked;
    });

        Services.InteractionService.addEventListener("MouseMove", (e: MouseMoveEvent) => {
            this.updateInspectLabel();
            this.valid_coords = Services.LayerMetadataService.isUpToDate;
        });
        this.updateInspectLabel();
       
    }

    updateInspectLabel(){
        this.coordstring = FormattingService.UEC_to_coord_string(Services.LayerMetadataService.coord, 3);
            let value = null;

            let filter;
            if(Services.RenderLayerService.selected_layer && (filter =Services.RenderLayerService.selected_layer.filterPipeline.find(x => x.name == 'colormap'))){
                let v = Services.LayerMetadataService.value.x1;
                value = FormattingService.num_to_string_unit_si(v, filter.parameters["colormap_max"].unit, 3);
            }

            this.heightstring = FormattingService.num_to_string_unit_si(Services.LayerMetadataService.z, "m", 3);

            if(value){
                this.valuestring = value;
            }
    }
}
</script>