<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="layers-card-content">
        <layer-group-component :group="default_group" :isDefault="true"></layer-group-component>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { RenderLayer } from '../modules/RenderLayer';
import {LayersChangedEvent, RenderLayerService, SelectedLayerChangedEvent} from '../services/RenderLayerService'
import LayerGroupComponent from './layergroupcomponent.vue';
import SvgIcon from './guikit/svgIcon.vue';
import ScrollBox from './guikit/scrollbox.vue';
import { Services } from '../services/Services';
import { Coord, UEC } from '../modules/tile';
import { DialogBox } from '../services/DialogBoxService';
import { RenderGroup } from '../modules/RenderGroup';

@Component({
    components: {
        SvgIcon,
        ScrollBox,
        LayerGroupComponent
    }
})
export default class LayerStack extends Vue{

    default_group: RenderGroup = Services.RenderLayerService.default_group;

    mounted(){
        Services.RenderLayerService.addEventListener(
            "LayersChanged",
            (e: LayersChangedEvent) => {
                this.default_group = Services.RenderLayerService.default_group;
                this.$forceUpdate();
            }
        );
    }
    
}
</script>

<style lang="scss">
@import '../_guikit.scss';

#layers-card-content {
    overflow-y: auto;
    height: 100%;
}
</style>