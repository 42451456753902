<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="box" v-show="selectedLayer && selectedLayer.filterPipeline.some(x => x.name == 'colormap')">
        <div id="colormap" :style="{background: getCurrentColormapCss()}">
        </div>
        <div v-for="tick in ticks" :key="tick.break" class="scale-label-anchor" :style='{"transform": `translateY(calc(-30vh * ${tick.break}))`}'>
            <div class="scale-label-pointer"></div>
            <div class="scale-label">
                <span>{{tick.label}}</span>
            </div>
        </div>
    </div>
</template>



<style lang="scss">
    @import '../_guikit.scss';

    #box {
        position: fixed;
        bottom: $grid;
        left: $grid;
        height: 30vh;
        //width: 1.5 * $grid;
        width: 10vh;
        z-index: 10000;
    }

    #colormap {
        top: calc(30vh);
        transform-origin: left top;
        position: absolute;
        height: calc(1.5 * $grid);
        width: 30vh;
        transform: rotate(-90deg);
        background-color: #f00;
        border-radius: $box_radius;
    }

    .scale-label-anchor {
        position: absolute;
        left: calc(1.5 * $grid);
        bottom: 0;
        width: 0;
        height: 0;
    }

    .scale-label{
        @extend %generic_box;
        font-size: 90%;
        position: absolute;
        left: calc($grid / 2);
        top: 0;
        transform: translateY(-50%);
    }

    .scale-label-pointer{
        @extend %pointer_left;
    }
</style>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { FormattingService } from '../services/FormattingService';
import {ColormapService} from '../services/ColormapService';
import { RenderLayer} from '../modules/RenderLayer';
import {SelectedLayerChangedEvent} from '../services/RenderLayerService';
import { Services } from '../services/Services';

@Component
export default class Scale extends Vue{
    selectedLayer: RenderLayer = null;
    boxheight = 1;
    labels= [];
    selectedValue = "";
    selectedIndex = -1;
    ticks: {break: number, label: string}[] = [];

    mounted(){
        this.selectedLayer = Services.RenderLayerService.getSelectedLayer();
        Services.RenderLayerService.addEventListener("SelectedLayerChanged", (e: SelectedLayerChangedEvent) => {
            this.selectedLayer = e.selectedLayer;
            if(this.selectedLayer?.ticksHint && Object.keys(this.selectedLayer.ticksHint).length > 0){
                this.ticks = [];
                for(let b of Object.keys(this.selectedLayer.ticksHint)){
                    this.ticks.push({
                        "break": this.break_from_value(parseFloat(b)),
                        "label": this.selectedLayer.ticksHint[b],
                    });
                }
            }else{
                //the old 4 labels
                let breaks = [0.05, (0.9 / 3 + 0.05), (1.8 / 3 + 0.05), 0.95];
                this.ticks = breaks.map(b => ({"break": b, "label": this.range(b)})); 
            }
        });
        setTimeout(() => {
            this.boxheight = document.getElementById('colormap').getBoundingClientRect().height;
        },100);
    }

    break_from_value(v): number{
        let filter;
        if(this.selectedLayer && (filter = this.selectedLayer.filterPipeline.find(x => x.name == 'colormap'))){
            let vmax = filter.parameters["colormap_max"].value;
            let vmin = filter.parameters["colormap_min"].value;
            return 1.0 - ((v - vmin) / (vmax - vmin));
        }else{
            return 0;
        }
    }

    getCurrentColormapCss(): string {
        let filter;
        if(this.selectedLayer && (filter = this.selectedLayer.filterPipeline.find(x => x.name == 'colormap'))){
            return Services.ColormapService.get_CSSGradient(filter.parameters["colormap"].value);
        }else{
            return "black";
        }
    }

    range(pos): string {
        let filter;
        if(this.selectedLayer && (filter = this.selectedLayer.filterPipeline.find(x => x.name == 'colormap'))){
            let vmax = filter.parameters["colormap_max"].value;
            let vmin = filter.parameters["colormap_min"].value;
            let v = (vmax * pos) + (vmin * (1 - pos));
            return FormattingService.num_to_string_unit_si(v, filter.parameters["colormap_max"].unit, 3);
        }
    }

    mousedown(e){
        Services.InteractionService.mousedown("threedee");
    }

}
</script>