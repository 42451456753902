//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

export class ParameterChangedEvent extends Event{
    public parameter: string;
    public group: string;
    public value: any;
    constructor(parameter: string, group: string, value: any){
        super("ParameterChanged");
        this.parameter = parameter;
        this.group = group;
        this.value = value;
    }

}

export class ParameterBroadcastService extends EventTarget{
    public parameterChanged(parameter: string, group: string, value: any){
        this.dispatchEvent(new ParameterChangedEvent(parameter,group,value));
    }
}