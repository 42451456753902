//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { Mat4 } from "../vecmat";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { ArrayData } from "../../services/TileCacheService";
import { Coord, Tile, UEC, UECArea } from "../tile";

export class PointCloudRenderSource extends RenderSource {

    constructor() {
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.scalarPointSource;
        this.name = "PointCloudRenderSource";
        this.parameters = {
            "displacement_scale": Services.SettingsService.getSetting("Exaggeration"),
            "displacement_offset": new Parameter("Vertical Offset", 0, "number", true),
            "point_size": new Parameter("Point Size", 8, "number", true),
            "time_range": new Parameter("Time Range", 1,"number",true).setStep(1 / 24 / 60).setUnit("d"),
            "value_sizing_enabled": new Parameter("Size by Value", false, "boolean", true),
            "value_sizing_zero": new Parameter("Minimum Size at", 0, "number", true),
            "value_sizing_one": new Parameter("Maximum Size at", 1, "number", true),
            "value_sizing_power": new Parameter("Proportionality", 1, "number", true).setStep(0.1)
        };
        this.parameters["displacement_offset"].shader_name = "displacement_offset";
        this.parameters["point_size"].shader_name = "point_size";
        this.parameters["value_sizing_enabled"].shader_name = "value_sizing_enabled";
        this.parameters["value_sizing_zero"].shader_name = "value_sizing_zero";
        this.parameters["value_sizing_one"].shader_name = "value_sizing_one";
        this.parameters["value_sizing_power"].shader_name = "value_sizing_power";
        this.slots = {
            "points": new RenderSourceSlot(
                "Point Layer",
                "points",
                null,
                "points",
                null
            )
        };
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        if(this.slots["points"]?.source?.layer?.zrange) {
            let min_scaled = this.applyScaling(this.slots["points"].source.layer.zrange[0]);
            let max_scaled = this.applyScaling(this.slots["points"].source.layer.zrange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }
        return[1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        if(this.slots["points"]?.source?.layer?.zrange) {
            let min_scaled = this.applyOffset(this.slots["points"].source.layer.zrange[0]);
            let max_scaled = this.applyOffset(this.slots["points"].source.layer.zrange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }
        return[1, 1];
    }

    getExtent(): UECArea {
        if(this.slots["points"]?.source?.layer){
            return this.slots["points"].source.layer.extent;
        }
    }

    applyScaling(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                * this.parameters["displacement_scale"].value
                / EARTH_RADIUS
    }

    applyOffset(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                / EARTH_RADIUS
    }

    /*
     * Only run this function once the gl context has been prepared. It requires the correct color attachments to be set.
     */
    execute(context: { [name: string]: WebGLRenderingContext | any; }) {
        if(!(this.slots["points"]?.source)){
            //console.log("no source");
            return;
        }
        let src = this.slots["points"].source;
        let tr = Services.TimeService.getCurrentTimeRange();
        let tm = Services.TimeService.getMeanTime();
        let t_radius = ((this.parameters["time_range"].value) * (60 * 60 * 24 * 1000));
        let t_start = Math.min(tr[0], tm - t_radius);
        let t_end = Math.max(tr[1], tm + t_radius);

        //console.log("time computed");

        let req_tiles: Tile[];
        let layer_extent = this.getExtent() || new UECArea(new UEC(0, 0), new UEC(1, 1));
        req_tiles = Services.RequiredTilesService.getRequiredTilesBounded(layer_extent, this.getVerticalBoundsWorldSpace());
        //extra gymnastics to avoid spamming the server with requests for tiles that are too deep tm.
        let actual_tiles: Set<string> = new Set();
        req_tiles.forEach(t => actual_tiles.add(t.path.substring(0, src.layer.max_zoom_level)));
        req_tiles = [];
        actual_tiles.forEach(p => req_tiles.push(Tile.from_tilepath(p)));

        let buffs: [ArrayData, Tile][] = [];
        for(let tile of req_tiles){
            let temp = Services.TileCacheService.get_array_data_tiled(this.slots["points"].source, t_start, t_end, tile).filter(e => !!e);
            for(let b of temp){
                buffs.push([b, tile]);
            }
        }

        //TODO: GetArrayDataTiled now returns parents of tiles as well. There are bounds of the original source attached. These need to be evaluated to avoid drawing the same point twice

        if(buffs.length <= 0) return;

        super.execute(context);
        context.gl.enable(context.gl.DEPTH_TEST);

        if(this.slots["points"]?.source?.layer.timerange){
            context.gl.uniform1f(this.shader.uniforms["time_min"], tr[0]);
            context.gl.uniform1f(this.shader.uniforms["time_max"], tr[1]);
        } else {
            context.gl.uniform1f(this.shader.uniforms["time_min"], 0);
            context.gl.uniform1f(this.shader.uniforms["time_max"], 0);
        }

        let cam_position = Services.PositionService.getCameraPositionFiltered();
        let cam_uec = UEC.from_Coord(new Coord(cam_position.Latitude, cam_position.Longitude));

        buffs.forEach(x => {
            let buff = x[0];
            let tile = x[1];
            switch (Services.PositionService.projection_mode) {
                case "EQUIRECT": {
                    context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(1, 0, 0, buff.referencePoint.x, 0, 1, 0, buff.referencePoint.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                    break;
                }
                case "SPHERE": {
                    break;
                }
                case "POLAR": {
                    break;
                }
            }
            let clip_min_x = tile.position.x - buff.referencePoint.x;
            let clip_min_y = tile.position.y - buff.referencePoint.y;
            let clip_max_x = clip_min_x + tile.size.x;
            let clip_max_y = clip_min_y + tile.size.y;

            context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.gl.vertexAttribPointer(this.shader.attributes["position"], 3, context.gl.FLOAT, false, 5 * 4, 0);
        
            context.gl.enableVertexAttribArray(this.shader.attributes["value"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.gl.vertexAttribPointer(this.shader.attributes["value"], 1, context.gl.FLOAT, false, 5 * 4, 3 * 4);

            context.gl.enableVertexAttribArray(this.shader.attributes["time"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.gl.vertexAttribPointer(this.shader.attributes["time"], 1, context.gl.FLOAT, false, 5 * 4, 4 * 4);

            context.gl.uniform2f(this.shader.uniforms["reference_position"], buff.referencePoint.x, buff.referencePoint.y);
            context.gl.uniform2f(this.shader.uniforms["reference_relative_position"], buff.referencePoint.x - cam_uec.x, buff.referencePoint.y - cam_uec.y);

            //Adaptive point size for fallbacks
            context.gl.uniform1f(this.shader.uniforms["point_size"], this.parameters["point_size"].value * Math.min(4, Math.sqrt(1 / buff.coord_scale[0] / buff.coord_scale[1])));
            context.gl.uniform2f(this.shader.uniforms["clip_min"], clip_min_x, clip_min_y);
            context.gl.uniform2f(this.shader.uniforms["clip_max"], clip_max_x, clip_max_y);

            context.gl.drawArrays(context.gl.POINTS, 0, buff.elements);

            context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
            context.gl.disableVertexAttribArray(this.shader.attributes["value"]);
            context.gl.disableVertexAttribArray(this.shader.attributes["time"]);
         
        });
    }
}
