import { Services, ServiceBarrier } from "./Services";

export class FlexibleTimeBaseService{

    private tracked_rt = Date.now();
    private internal_time = this.tracked_rt;

    private framerate = 30;

    private locked_framerate = false;

    public constructor() {
    }

    count_frame(valid_frame: boolean = true){
        let rt_now = Date.now();

        let delta = rt_now - this.tracked_rt;

        this.tracked_rt = rt_now;

        if(this.locked_framerate) {
            if(valid_frame){
                this.internal_time += 1000/this.framerate;
            }
        }else{
            this.internal_time += delta;
        }
    }

    set_lock_state(locked: boolean){
        this.locked_framerate = locked;
        if(this.locked_framerate){
            console.log("locked");
        } else {
            console.log("unlocked");
        }
    }

    now(): number {
        return this.internal_time;
    }

}