<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="source-selection-container" @click.stop="">
        <div class="source-title" v-if="title != null">{{title}}:</div>
        <div class="source-row" v-for="category in categories.filter((c) => sources_for_category(c).length > 0)" :key="category" @click.stop="toggle_category(category)">
            <div :class="{'category-row-label': true, expanded: expanded_category==category}">
                 <span style="display: inline-block;"><SVGIcon :name="expanded_category==category?'down':'right'"></SVGIcon></span> 
                {{category}}
            </div>
            <div v-if="expanded_category==category">
                <div v-for="source in sources_for_category(category).filter((s) => rows_for_source(s).length > 0)" :key="source" @click.stop="toggle_source(source)">
                    <div :class="{'source-row-label': true, expanded: expanded_source==source}">
                        <span style="display: inline-block;"><SVGIcon :name="expanded_source==source?'down':'right'"></SVGIcon></span> 
                        {{source}}
                    </div>
                    <div v-if="expanded_source==source">
                        <div v-for="row in rows_for_source(source)" :key="row.path" @click.stop="return_source(row.sli)" :class="{'layer-row-label': true, expanded: highlighted_layer == row.sli.layer_name}">
                            {{row.layer}}
                            <span class="layer-row-unit" v-if="row.unit">
                                ({{row.unit}})
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../_guikit.scss';
    #source-selection-popup{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: transparentize($color: $bg-color, $amount: 0.5);
        z-index: 1000010;
    }

    .expanded{
        color: $hl_color;
    }

    #source-selection-container{
        color: $fg-color;
        background-color: transparentize($color: $bg-color, $amount: 0.1);
        height: 85vh;
        width: 66vw;
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    .source-title{
        font-size: 150%;
        font-weight: bold;
        width: 100%;
        text-align: left;
        margin: 0.5em;
        margin-bottom: 0.5em;
        
    }

    .source-row{
        @extend %generic_box;
        margin-bottom: 0.5em;
        
    }

    .category-row-label{
        @extend %generic_button;
        font-weight: bold;
        font-size: 1.5rem;
    }

    .source-row-label{
        @extend %generic_button;
        font-weight: bold;
        font-size: 1.25rem;
        margin-left: 3em;
        margin-top: 0.5em;
    }

    .layer-row-label{
        @extend %generic_button;
        font-weight: bold;
        font-size: 1.0rem;
        margin-left: 6em;
        margin-top: 0.5em;
    }

    .layer-row-unit{
        font-weight: normal;
    }

    .overflow-scroll {
        overflow-y: scroll;
    }

</style>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component';
import { SourceLayerInfo} from '../services/SourceInfoService';
import SourceLayerBadge from './sourcelayerbadge.vue';
import { Services } from '../services/Services';
import SVGIcon from './guikit/svgIcon.vue';

interface row {
    source: string, 
    layer: string, 
    unit: string, 
    layertype: string,
    original_layertype: string,
    sli: SourceLayerInfo,
    category: string
};

@Component({
    components: {
        SourceLayerBadge,
        SVGIcon
    },
    props: {
        data: {
            type: Object,
            default: {}
        }
    }
})
export default class SourceSelectionDialog extends Vue{
    expanded_category = "";
    expanded_source = ""
    highlighted_layer = "";
    filtered_sources: row[] = [];
    sources: row[] = [];
    filter_term = "";
    type_filter = "";
    title: string = null;

    get categories(){
        return [... new Set(this.filtered_sources.map(f => f.category || "Other")),].sort();
    }

    sources_for_category(category){
        return [... new Set(this.filtered_sources.filter(r => r.category == category).map(f => f.source))].sort();
    }

    rows_for_source(source){
        return this.filtered_sources.filter(r => r.source == source).sort();
    }

    get filter(){
        return this.filter_term;
    }

    set filter(value){
        this.filter_term = value.toLowerCase();
        this.filtered_sources = this.sources.filter((s: row) => {
            return (this.type_filter == "" || !this.type_filter || s.original_layertype == this.type_filter) && (this.filter_term == "" || (
                   s.source.toLowerCase().includes(this.filter_term) 
                || s.layer.toLowerCase().includes(this.filter_term)
                || s.unit.toLowerCase().includes(this.filter_term)
                || s.layertype.toLowerCase().includes(this.filter_term))
            );
        });
    }

    toggle_category(category: string){
        if(this.expanded_category == category){
            this.expanded_category = "";
        }else{
            this.expanded_category = category;
        }
    }

    toggle_source(source: string){
        if(this.expanded_source == source){
            this.expanded_source = "";
        }else{
            this.expanded_source = source;
        }
    }

    return_source(sli: SourceLayerInfo){
        //Remove yourself first since you may be recreated immediately in the next step!!!
        Services.DialogBoxService.remove("Select Source");
        this.$props.data.sourceSelectionCallback(sli);
    }

    sort_filtered_by(key: string){
        this.filtered_sources.sort((a,b) => a[key].localeCompare(b[key]));
    }

    mounted(){
        this.type_filter = this.$props.data.typeFilter;
        let current_source: SourceLayerInfo = this.$props.data.currentSource;
        if(!!current_source){
            this.expanded_category = current_source.source.category;
            this.expanded_source = current_source.instance_name;
            this.highlighted_layer = current_source.layer_name;
        }
        this.refresh_sources();
    }

    refresh_sources(){
        this.sources = Services.SourceInfoService.get_all_source_layer_infos().map((sli: SourceLayerInfo) => {
            return ({
                source: sli.instance_name || "",
                layer: sli.layer.long_name || sli.layer_name || "",
                unit: sli.layer.unit || "",
                layertype: sli.layer.layer_type || "",
                original_layertype: sli.layer.layer_type,
                sli: sli,
                category: sli.source.category
            });
        });
       this.filter = "";
    }

}
</script>