//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

export class StaticToBlobService {
    private blobs:  Map<string, string>;

    constructor() {
        this.blobs = new Map();
    }

    insert(url: string, cb?) {
        fetch(url).then((r) => r.blob()).then((b) => {
            let blob_url = URL.createObjectURL(b);
            this.blobs.set(url, blob_url);
            if(cb) cb(blob_url);
        });

    }

    getBlobUrl (url: string, cb) {
        if(this.blobs.has(url))
            cb(this.blobs.get(url));
        else
            this.insert(url, cb);
    }
}