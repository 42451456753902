//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { Parameter } from "../modules/Parameter";
import { FlexibleTimeBaseService } from "./FlexibleTimeBaseService";
import { CameraPosition } from "./PositionService";
import { ServiceBarrier, Services } from "./Services";

export class PlaybackStateChangedEvent extends Event{
    state: boolean = false;
    constructor(state: boolean){
        super("PlaybackStateChanged");
        this.state = state;
    }
}

export class PlaybackService extends EventTarget{

    playback_state: boolean = false;

    public playback_overridden = false;

    public stalled = false;

    speed_seconds_per_second: number = 86400 * 1000;

    interval_handle = null;

    playback_start: number;
    playback_end: number;

    a: number;
    b: number;

    time_min: number;
    time_max: number;
    time_range_length: number;
    time_range_end: number;

    p0: CameraPosition;

    constructor(){
        super();
        ServiceBarrier.wait().then(() => {
            Services.SettingsService.initializeSetting(new Parameter("WaitForLoad", true, "boolean"));
        });
        /*ServiceBarrier.wait().then(() =>
        Services.GLService.addEventListener("FrameDone", () => {
            if(this.playback_state || this.playback_overridden){
                Services.AdaptivePerformanceService.RequestRerender();
                this.setTimerangeToCorrectPosition();
            }
        }));*/
    }
    public update() {
        if(this.playback_state || this.playback_overridden){
            Services.AdaptivePerformanceService.RequestRerender();
            this.setTimerangeToCorrectPosition();
            if(!this.playback_overridden){
                if(Services.TileCacheService.loading && Services.SettingsService.getSetting("WaitForLoad").value){
                    let ratio = Services.TileCacheService.ratio;
                    this.stalled = true;
                    this.begin_playing(Math.pow(ratio[0]/ratio[1], 2));
                }else{
                    this.stalled = false;
                    this.begin_playing();
                }
            }
        }
    }

    private setTimerangeToCorrectPosition(){
        let now = Services.FlexibleTimeBaseService.now();

        let left_time = this.a * now + this.b;

        let gtr = Services.TimeService.getOverallTimeRange();

        if(!this.playback_overridden && left_time + this.time_range_length > gtr[1]){
            this.setPlaybackState(false);
            Services.TimeService.moveTimeStart();
        }else{
            left_time = Math.max(Math.min(left_time, gtr[1] - this.time_range_length), gtr[0]);
            Services.TimeService.setCurrentTimeRange(
                left_time,
                left_time + this.time_range_length
            );
        }
    }

    public setSpeed(speed_seconds_per_second: number){
        this.speed_seconds_per_second = speed_seconds_per_second;
        this.begin_playing();
    }

    public togglePlaying(){
        this.setPlaybackState(!this.playback_state);
    }

    public setPlaybackState(playback_state: boolean){
        if(this.playback_state != playback_state){
            this.playback_state = playback_state;
            if(playback_state){
                this.begin_playing();
            }else{
                this.stalled = false;
            }
            this.dispatchEvent(new PlaybackStateChangedEvent(playback_state));
        }
    }

    begin_playing(speed: number = 1){
        let tr = Services.TimeService.getOverallTimeRange();
        let ctr = Services.TimeService.getCurrentTimeRange();
        let now = Services.FlexibleTimeBaseService.now();

        this.a = this.speed_seconds_per_second * speed / 1000;
        this.b = ctr[0] - this.a * now;
        this.time_range_length = ctr[1] - ctr[0];

        this.time_range_end = tr[1];
    }

    stop_playing(){
        this.setPlaybackState(false);
    }

    public start_cam_snap(){
        this.p0 = Services.PositionService.getCameraPosition();
    }

    public format_cam_snap(){
        let p0 = this.p0;
        let p1 = Services.PositionService.getCameraPosition();
        return `lat0 = ${p0.Latitude}
lon0 = ${p0.Longitude}
d0 = ${p0.Distance}
elev0 = ${p0.Elevation}
az0 = ${p0.Azimuth}
v0 = ${p0.VerticalPosition}

lat1 = ${p1.Latitude}
lon1 = ${p1.Longitude}
d1 = ${p1.Distance}
elev1 = ${p1.Elevation}
az1 = ${p1.Azimuth}
v1 = ${p1.VerticalPosition}`
    }


}