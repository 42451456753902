<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
        <div id="about-container" @click.stop="">
            <h1 style="margin-top: 0;">Digital Earth Viewer</h1>
            <div>
                The <a href="https://git.geomar.de/digital-earth/digital-earth-viewer">Digital Earth Viewer</a> is a project to develop a modern geospatial data viewer as part of the <a href="https://www.digitalearth-hgf.de">Digital Earth</a> initiative.
            </div>
            <div>
                <b>Project Lead: </b> <a href="https://www.geomar.de/en/jgreinert">Prof. Dr. Jens Greinert</a>
                <br/>
                <b>Developers:</b> 
                <a href="https://www.geomar.de/vbuck">Valentin Buck</a>, 
                <a href="https://www.geomar.de/fstaebler">Flemming Stäbler</a>, 
                <a href="https://www.geomar.de/egonzalez">Everardo González</a>
            </div>

            <div>
                <b>Contact</b>: <a href="mailto:digitalearthviewer@geomar.de">digitalearthviewer@geomar.de</a>
            </div>
            <ScrollBox style="max-height: 80%;">
<div class="tnc">
            <h1>Terms of Use</h1>
<em>Last updated: February 4, 2021</em><br/>
<strong style="font-size: 110%;">These Terms of Use ("Terms") apply to your use of the Digital Earth Viewer. Please read them carefully and let us know if you have any questions.<br>
By using the Digital Earth Viewer, you agree to be bound by these Terms. If you are using the Digital Earth Viewer on behalf of an organization, you agree to these Terms on behalf of that organization.
</strong>
<h2>Content</h2>
<p>
Content such as model- or observation data in the Digital Earth Viewer is protected by intellectual property laws.
</p>
<h3>The Digital Earth Viewer Software</h3>
<p>
The Digital Earth Viewer software is protected by copyright and other laws. GEOMAR gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software we provide you to use our service. This license is for the sole purpose of enabling you to use the Digital Earth Viewer as permitted by these Terms. If you violate these Terms, we may terminate this license.
The source code of the Digital Earth Viewer software is licensed under <a href="https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12">EUPL v1.2</a> and is accessible at <a href="https://git.geomar.de/digital-earth/digital-earth-viewer">https://git.geomar.de/digital-earth/digital-earth-viewer</a>
</p>
<h2>
Prohibited Activities
</h2><p>
We require you to respect these limitations, and we may restrict your access to the Digital Earth Viewer if you do not follow them.
</p>
<ul>
    <li>
    Do not use the Digital Earth Viewer in a manner that violates any laws, regulations, ordinances, or directives.
    </li>
    <li>
    Do not use the Digital Earth Viewer contrary to our policies.
    </li>
    <li>
    Do not use the Digital Earth Viewer to do anything threatening, abusive, harassing, defamatory, tortious, or invasive of another person's privacy.
    </li>
    <li>
    Do not interfere with the proper functioning of any software, hardware, or equipment of the Digital Earth Viewer.
    </li>
    <li>
    Do not engage in any conduct that inhibits anyone else's use of our services, or which we determine may harm the Digital Earth Viewer, GEOMAR or our users.
    </li>
    <li>
    Do not monitor or copy any material on the Digital Earth Viewer, either manually or through automated means (i.e. scraping), without prior written consent.
    </li>
</ul>
<h2>
Our Rights
</h2>
<p>
We are always working to improve the Digital Earth Viewer and make our services better, so we do reserve some rights. In our sole discretion, we may, at any time &mdash; with or without notice &mdash; change, eliminate or restrict access to our services. GEOMAR is not liable for any damages as a result of these actions.
</p>
<h2>
Other Sites and Services
</h2>
<p>
The Digital Earth Viewer contains links to websites and services that we neither own nor control. GEOMAR does not endorse or assume responsibility for any third-party sites, information, materials, products, or services.
</p>
<h2>
Privacy
</h2><p>
Please refer to <a href="https://www.geomar.de/datenschutz">GEOMAR's Privacy Policy</a>.
</p>
<h2>
Disclaimers
</h2>
<p>
The Digital Earth Viewer is provided "as is" without any warranties, expressed or implied.
</p>
<h2>
Limitation of Liability
</h2>
<p>
To the fullest extent allowed by law, GEOMAR shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from <ol><li> your access to, use of, inability to access, or inability to use the Digital Earth Viewer; </li><li> any third party conduct or content on the Digital Earth Viewer, including any defamatory, offensive, or illegal conduct of third parties; </li><li> any unauthorized access, use, or alteration of your content. </li></ol>
</p>
<h2>
Modification
</h2>
<p>
The Terms may be modified from time to time. The date of the most recent revisions will always be shown on this page. If we make changes that we believe will substantially alter your rights, we will notify you. You will agree to accept any changes or revisions to the Terms by continuing to use the Digital Earth Viewer.
</p><h2>
Contact
</h2>
<p>
We welcome all questions, concerns, and feedback you might have about these terms. If you have suggestions for us, let us know at <a href="mailto:digitalearthviewer@geomar.de">digitalearthviewer@geomar.de</a>.
</p></div></ScrollBox>
            <div style="text-align: right;">
                <button @click="hide()" id="acceptbutton" style="width: 10em; margin-bottom: 1em; font-weight: bold;">Accept</button>
            </div>
            <br/>
        </div>
</template>

<style lang="scss" scoped>
    @import '../_guikit.scss';
    #about-dialogue{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: transparentize($color: $bg-color, $amount: 0.5%);
        z-index: 1000010;
    }

    .tnc {
        text-align: justify;
        padding: 2em;
    }

    #about-container{
        position: relative;
        @extend %generic_box;
        color: $fg-color;
        background-color: transparentize($color: $bg-color, $amount: 0.1%);
        height: 66vh;
        max-width: 50vw;
        //overflow-y: scroll;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.5em;
        line-height: 150%;
        font-size: 110%;
        text-align: center;
    }

    #acceptbutton{
        background-color: mix($bg-color, $hl-color, 0.5%);
    }

</style>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import ScrollBox from './guikit/scrollbox.vue';
import { Services } from '../services/Services';

@Component({
    components: {
        SvgIcon,
        ScrollBox
    }
})
export default class AboutDialogue extends Vue{
    hide() {
        Services.DialogBoxService.remove("About");
        window.localStorage.setItem("license_displayed", "true");
    }    
}
</script>