<template>
    <div id="logo">
        <img id="smalllogo" ondragstart="return false;" @click="openabout();" class="fadein" src="static/digitalearth.png" />
    </div>
</template>

<style lang="scss">
    @import "../_guikit.scss";
    #smalllogo{
        position:fixed;
        top: 0.8rem;
        right: 0.8rem;
        height: 4rem;
    }
    #debug{
    position:fixed;
    color: #fff;
    background-color: rgba(0, 0, 0, 25%);
    top: 0px;
    right: 15px;
    font-family: monospace;
    font-size: 80%;
    opacity: 0.8;
    z-index: 100000;
}

#debug-switch{
    position:fixed;
    color: #fff;
    background-color: rgba(0, 0, 0, 25%);
    top: 0px;
    right: 0px;
    font-size: 80%;
    font-family: monospace;
    opacity: 0.5;
    z-index: 100001;
}
</style>

<script lang="ts">
import { DialogBox } from '../services/DialogBoxService';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Services } from '../services/Services';
import SvgIcon from './guikit/svgIcon.vue';
@Component({
    components: {
        SvgIcon
    }
})
export default class LogoComponent extends Vue{

     openabout() {
        Services.DialogBoxService.insert("About", DialogBox.centered("AboutDialogue", "About the Digital Earth Viewer"));
    }
}
</script>

