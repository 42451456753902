<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="type-selection-container" @click.stop="">
        <fold-group name="Normal" :expanded="true">
            <div class="type-row" @click="add_layer('image-ao')">
                <div class="type-image">
                    <SvgIcon name="checker"></SvgIcon>
                </div>
                <div class="type-info">
                    <span class="type-label">Color Image</span>
                    <span class="type-description">Displays georeferenced images.</span>
                </div>
            </div>
            <div class="type-row" @click="add_layer('scalar')">
                <div class="type-image">
                    <SvgIcon name="grid"></SvgIcon>
                </div>
                <div class="type-info">
                    <span class="type-label">Data Grid</span>
                    <span class="type-description">Displays a raster of scalar data values using a colormap.</span>
                </div>
            </div>
            <div class="type-row" @click="add_layer('vector2arrow')">
                <div class="type-image">
                    <SvgIcon name="tracers"></SvgIcon>
                </div>
                <div class="type-info">
                    <span class="type-label">Vector Fields</span>
                    <span class="type-description">Displays a vector field as an animation of arrows</span>
                </div>
            </div>
            <div class="type-row" @click="add_layer('pointcloud')">
                <div class="type-image">
                    <SvgIcon name="points"></SvgIcon>
                </div>
                <div class="type-info">
                    <span class="type-label">Data Pointcloud</span>
                    <span class="type-description">Displays a pointcloud with a scalar data attribute for each point.</span>
                </div>
            </div>
            <div class="type-row" @click="add_layer('colorpointcloud')">
                <div class="type-image">
                    <SvgIcon name="points"></SvgIcon>
                </div>
                <div class="type-info">
                    <span class="type-label">Color Pointcloud</span>
                    <span class="type-description">Displays a pointcloud with color information for each point.</span>
                </div>
            </div>
        </fold-group>
        <fold-group name="Special">
            <div class="type-row" @click="add_layer('lines')">
                <div class="type-image">
                    <SvgIcon name="paths"></SvgIcon>
                </div>
                <div class="type-info">
                    <span class="type-label">Lines Layer</span>
                    <span class="type-description">Displays lines with scalar data values using a colormap.</span>
                </div>
            </div>
            <div class="type-row" @click="add_layer('difference')">
                <div class="type-image">
                    <SvgIcon name="grid-difference"></SvgIcon>
                </div>
                <div class="type-info">
                    <span class="type-label">Difference Layer</span>
                    <span class="type-description">Displays the difference between two scalar layers.</span>
                </div>
            </div>
            <div class="type-row" @click="add_layer('vec2points')">
                <div class="type-image">
                    <SvgIcon name="points"></SvgIcon>
                </div>
                <div class="type-info">
                    <span class="type-label">2-Component Vector Points Layer</span>
                    <span class="type-description">Displays points with two values using a colormap and an arrow.</span>
                </div>
            </div>
            <div class="type-row" @click="add_layer('piechartpoints')">
                <div class="type-image">
                    <SvgIcon name="points"></SvgIcon>
                </div>
                <div class="type-info">
                    <span class="type-label">Pie Chart Points Layer</span>
                    <span class="type-description">Displays pie charts from point data.</span>
                </div>
            </div>
        </fold-group>
    </div>
</template>

<style lang="scss" scoped>
    @import '../_guikit.scss';

    #type-selection-container{
        @extend %generic_box;
        color: $fg-color;
        background-color: transparentize($color: $bg-color, $amount: 0.1);
        //overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .type-row{
        @extend %generic_button;
        //border: 2px solid $fg_color;
        display: flex;
        flex-direction: row;
    }
    .type-row:not(:last-child) {
        margin-bottom: calc($grid / 2);
    }

    .type-image{
        @extend %generic_element;
        overflow: hidden;
        font-size: 200%;
        background: none;
    }

    .type-info{
        @extend %generic_element;
        margin-left: 0.5rem;
        display: flex;
        flex-direction: column;
        background: none;
    }

    .type-label{
        font-size: 120%;
        font-weight: bold;
        margin-bottom: 0.5em;
    }

    .foldgroup-container:not(:last-child){
        margin-bottom: calc($grid / 2);
    }
</style>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component';
import { Services } from '../services/Services';
import SvgIcon from './guikit/svgIcon.vue';
import { DialogBox } from '../services/DialogBoxService';
import { SourceLayerInfo } from '../services/SourceInfoService';
import { RenderLayerFactory } from '../modules/RenderLayer';
import FoldGroup from './guikit/foldGroup.vue';

@Component ({
    components: {
        SvgIcon,
        FoldGroup
    }
})
export default class TypeSelectionDialog extends Vue{
    mounted(){
    }


    add_layer(type){
        let data = {sourceSelectionCallback: (_: SourceLayerInfo) => {console.error("Unimplemented layer type.")}, typeFilter: "", needs_source: true};
        let title = "Select Source";
        switch(type){
            case "scalar":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {
                    let new_layer = RenderLayerFactory.createColormapScalarLayer(sli);                            
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ScalarTiles", needs_source: true};
            break;
            case "image":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {
                    let new_layer = RenderLayerFactory.createImageLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ColorTiles", needs_source: true};
            break;
            case "image-ao":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {
                    let new_layer = RenderLayerFactory.createDomeLightImageLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ColorTiles", needs_source: true};
            break;
            case "pointcloud":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {                            
                    let new_layer = RenderLayerFactory.createPointCloudLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ScalarPointCloud", needs_source: true};
            break;
            case "colorpointcloud":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {                            
                    let new_layer = RenderLayerFactory.createColorPointCloudLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ColorPointCloud", needs_source: true};
            break;
            case "lines":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {
                    let new_layer = RenderLayerFactory.createLinesLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ScalarLines", needs_source: true};
            break;
            case "vec2points":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {
                    let new_layer = RenderLayerFactory.createVec2PointsLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "Vector2DPoints", needs_source: true};
            break;
            case "vector2arrow":
                data = {sourceSelectionCallback: (sli1: SourceLayerInfo) => {
                    let sli2;
                    if(sli1.layer_name.includes("U")){
                        let other_sli_name = sli1.layer_name.replace("U", "V");
                        sli2 = Services.SourceInfoService.get_all_source_layer_infos().filter(s => s.instance_name == sli1.instance_name && s.layer_name == other_sli_name)[0];
                    } else if (sli1.layer_name.includes("u")){
                        let other_sli_name = sli1.layer_name.replace("u", "v");
                        sli2 = Services.SourceInfoService.get_all_source_layer_infos().filter(s => s.instance_name == sli1.instance_name && s.layer_name == other_sli_name)[0];
                    }
                    if(sli2){
                        let new_layer = RenderLayerFactory.createVector2ArrowLayer(sli1, sli2);
                        Services.RenderLayerService.addLayer(new_layer);
                        Services.RenderLayerService.selectLayer(new_layer);
                        return;
                    }
                    Services.DialogBoxService.insert("Select Source", DialogBox.centered("SourceSelectionDialog", "Select Meridional Velocity Layer", {
                        sourceSelectionCallback: (sli2: SourceLayerInfo) => {
                            let new_layer = RenderLayerFactory.createVector2ArrowLayer(sli1, sli2);
                            Services.RenderLayerService.addLayer(new_layer);
                            Services.RenderLayerService.selectLayer(new_layer);
                        }, typeFilter: "ScalarTiles", needs_source: true}));
                    }, typeFilter: "ScalarTiles", needs_source: true};
                title = "Select Zonal Velocity Layer"
            break;
            case "difference":
                data = {sourceSelectionCallback: (sli1: SourceLayerInfo) => {
                    Services.DialogBoxService.insert("Select Source", DialogBox.centered("SourceSelectionDialog", "Select Offset Value Layer", {
                    sourceSelectionCallback: (sli2: SourceLayerInfo) => {
                        let new_layer = RenderLayerFactory.createColormapDifferenceLayer(sli1, sli2);
                        Services.RenderLayerService.addLayer(new_layer);
                        Services.RenderLayerService.selectLayer(new_layer);
                        }, typeFilter: "ScalarTiles"}));
                    }, typeFilter: "ScalarTiles", needs_source: true};
                title = "Select Base Value Layer";
            break;
            case "piechartpoints":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {                            
                    let new_layer = RenderLayerFactory.createPieChartPointsLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ScalarPoints", needs_source: true};
            break;
            default:
                throw("Unimplemented.");
            break;
        }
        Services.DialogBoxService.remove("New Layer");
        if(data.needs_source){
            Services.DialogBoxService.insert("Select Source", DialogBox.centered("SourceSelectionDialog", title, data));
        }else{
            data.sourceSelectionCallback(null);
        }
    }

}
</script>