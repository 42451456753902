<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="overlays">
        <div
            v-for="overlay in overlays" :key="overlay.id"
            :style="{left: position_from_overlay(overlay).x, top: position_from_overlay(overlay).y, zIndex: position_from_overlay(overlay).z}"
            class="overlay-anchor" 
        >
            <div class="overlay-pointer"></div>
            <div class="overlay"
                v-on:click="move_to(overlay)"
                v-on:mousedown="mousedown_evt()"
                v-on:mousemove="mouseenter(overlay)"
                v-on:mouseleave="mouseleave(overlay)"
            >
                <div class="overlay-title">{{overlay.layer.name}}</div>
                <component class="overlay-val" :is="overlay.component" :overlay="overlay"></component>
                <div class="overlay-coords">{{coordinates(overlay)}}</div>
                <div class="close-button" v-on:click.stop="remove_overlay(overlay)">
                    <SvgIcon name="close"></SvgIcon>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '../_guikit.scss';

    #overlays{
        top: 0;
        left: 0;
        position: fixed;
    }

    .close-button{
        @extend %generic_element;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 150%;
        color: $warn_color;
        padding: 0.1em;
    }

    .overlay-anchor{
        position:absolute;
        width: 0;
        height: 0;
    }

    .overlay{
        position: absolute;
        @extend %generic_box;
        font-size: 100%;
        bottom: calc(0.5 * $grid);
        padding-right: 2em; //TODO FIX THIS
        left: -$grid;
    }

    .overlay-pointer{
        @extend %pointer_down;
    }

    .overlay-title{
        color: rgb(189, 189, 189);
    }

    .overlay-val{
        font-size: 1rem;
    }

    .overlay-color{
        width: 1.5em;
        height: 1.5em;
        border-radius: 1em;
        margin-top: calc($grid / 2);
        margin-bottom: calc($grid / 2);
    }

    .overlay-coords{
        color: rgb(189, 189, 189);
    }

</style>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import { Coord } from '../modules/tile';
import { FormattingService as formatter } from '../services/FormattingService';
import { Overlay } from '../services/OverlayService';
import { Services } from '../services/Services';

/*
 * Import and register your custom overlays here.
 */ 
import ScalarOverlay from './overlays/scalarOverlay.vue';
import ColorOverlay from './overlays/colorOverlay.vue';
import TurbidityOverlay from './overlays/turbidityOverlay.vue';
//import MeasurementOverlay from './overlays/measurementOverlay.vue';
import ScaleOverlay from './overlays/scaleOverlay.vue';
@Component({
    components: {
        SvgIcon,
        ScalarOverlay,
        ColorOverlay,
        TurbidityOverlay,
        //MeasurementOverlay,
        ScaleOverlay
    }
})
export default class Overlays extends Vue{

    overlays: Overlay[] = [];

    position_from_overlay(overlay: Overlay): {x:string, y: string, z: string}{
        let position = {
            x: overlay.screen_position.x1 + "px",
            y: overlay.screen_position.x2 + "px",
            z: overlay.hovered ? "2000" : overlay.screen_position.x3 + ""
        };
        return position;
    }

    remove_overlay(overlay: Overlay) {
        Services.OverlayService.removeOverlay(overlay);
    }

    coordinates(overlay: Overlay): string{
        return formatter.UEC_to_coord_string(overlay.location, 5) + ", " + formatter.num_to_string_unit_si(overlay.height, "m", 3);
    }

    move_to(overlay: Overlay) {
        let coord = Coord.from_UEC(overlay.location);
        let old_position =  Services.PositionService.getCameraPosition();
        Services.PositionService.setCameraPosition({
            Latitude: coord.lat,
            Longitude: coord.lon,
            Elevation: old_position.Elevation,
            Azimuth: old_position.Azimuth,
            Distance: old_position.Distance,
            VerticalPosition: overlay.layer.source.applyOffset(overlay.height)
        });
    }

    mousedown_evt(){
        Services.InteractionService.mousedown("overlay");
    }

    async mounted(){
        this.overlays = Services.OverlayService.getOverlays();
        Services.OverlayService.addEventListener("OverlaysChanged", () => this.$forceUpdate());
    }

    mouseenter(overlay){
        overlay.hovered = true;
    }

    mouseleave(overlay){
        overlay.hovered = false;
    }
}
</script>