<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="loading-indicator-box" :style="{opacity: (loading ? '1.0' : '0.0')}">
        <div class="text-container">
            {{label_text}}
        </div>
        <div id="loading-indicator-bar" :style="{width: (100 * ratio[0] / ratio[1]) + '%'}">
            <div class="text-container">
                {{label_text}}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../_guikit.scss';

    $width: 20vw;

    #loading-indicator-box{
        @extend %generic_box;
        position: absolute;
        padding: 0;
        width: $width;
        left: calc((100vw - $width) / 2);
        top: $grid;
        transition: opacity ease-in-out 0.25s;
    }

    #loading-indicator-bar{
        @extend %generic_box;
        background-color: $hl_color;
        color: $bg_color;
        overflow: hidden;
        position: absolute;
        padding: 0;
        left: 0;
        top: 0;
        transition: width ease-in-out 0.25s;
    }

    .text-container{
        text-align: center;
        font-size: 80%;
        left: 0;
        top: 0;
        width: $width;
        padding: calc($grid / 4);
    }
</style>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { FormattingService } from '../services/FormattingService';
import { Services } from '../services/Services';
@Component({
    components: {
    },
    props: {
    }
})
export default class LoadingIndicator extends Vue{
    ratio = [0.0, 0.0];
    label_text = "100.0%";
    loading = false;

    async mounted() {
        Services.GLService.addEventListener("FrameDone", (e) => {
            this.update_ratio();
            this.update_label_text();
        })
    }

    update_ratio() {
        this.ratio = Services.TileCacheService.ratio;
        this.loading = Services.TileCacheService.loading;
    }

    update_label_text() {
        this.label_text = FormattingService.num_to_string_unit(this.ratio[0] / this.ratio[1] * 100, "%", 0)
    }
}
</script>