//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { ServiceBarrier, Services } from './Services';

export class MouseMoveEvent extends Event{
    x: number;
    y: number;
    constructor(x: number, y: number){
        super("MouseMove");
        this.x = x;
        this.y = y;
    }
}

export class KeyDownEvent extends Event{
    key: string;
    keycode: string;
    event: KeyboardEvent;
    constructor(event: KeyboardEvent){
        super("KeyDown");
        this.event = event;
        this.key = event.key;
        this.keycode = event.code;
    }
}

export class MouseDownEvent extends Event{
    x: number;
    y: number;
    left: boolean;
    right: boolean;
    constructor(x: number, y: number, left: boolean, right: boolean){
        super("MouseDown");
        this.x = x;
        this.y = y;
        this.left = left;
        this.right = right;
    }
}

export class InteractionStateChangedEvent extends Event{
    old: InteractionState;
    state: InteractionState;
    constructor(old: InteractionState, state: InteractionState) {
        super("InteractionStateChanged");
        this.old = old;
        this.state = state;
    }
}

export enum InteractionState{
    Move,
    Overlay,
    Measure
}

export class InteractionService extends EventTarget{

    private target: string;
    public mouseMoveHandlers: Map<string, (e: MouseEvent)=>void> = new Map();
    public mouseUpHandlers: Map<string, (e: MouseEvent)=>void> = new Map();
    public mouseDownHandlers: Map<string, (e: MouseEvent)=>void> = new Map();
    public keyDownHandlers: Map<string, (e: KeyboardEvent)=>void> = new Map();
    public lastMousePosition: {x: number, y: number} = {x: 0, y: 0};

    public touchStartHandlers: Map<string, (e: TouchEvent) => void> = new Map();
    public touchEndHandlers: Map<string, (e: TouchEvent) => void> = new Map();
    public touchMoveHandlers: Map<string, (e: TouchEvent) => void> = new Map();
    public lastTouches: TouchList;
    public touchMoveHappened: boolean;

    public gamepadid: number;

    private interactionState: InteractionState = InteractionState.Move

    constructor(){
        super();
        document.addEventListener("mousemove", (e) => {
            this.lastMousePosition = {x: e.clientX, y: e.clientY};
            this.dispatchEvent(new MouseMoveEvent(e.clientX,e.clientY));
            if(this.mouseMoveHandlers.has(this.target)) this.mouseMoveHandlers.get(this.target)(e)
            else this.defaultHandler(e);
            Services.AdaptivePerformanceService.RequestRerender();
        });
        document.addEventListener("mouseup", (e) => {
            if(this.mouseUpHandlers.has(this.target)) this.mouseUpHandlers.get(this.target)(e)
            else this.defaultHandler(e);
        });
        document.addEventListener("mousedown", e => {
            if(this.mouseDownHandlers.has(this.target)) this.mouseDownHandlers.get(this.target)(e)
            this.dispatchEvent(new MouseDownEvent(e.clientX, e.clientY, e.buttons == 1, e.buttons==2));
        });
        document.addEventListener("keydown", e => {
            if(this.keyDownHandlers.has(this.target)) this.keyDownHandlers.get(this.target)(e)
            this.dispatchEvent(new KeyDownEvent(e));
        });
        document.addEventListener("touchstart", e => {
            if(this.touchStartHandlers.has(this.target)) this.touchStartHandlers.get(this.target)(e)
            else this.defaultTouchHandler(e);
        });
        document.addEventListener("touchend", e => {
            if(this.touchEndHandlers.has(this.target)) this.touchEndHandlers.get(this.target)(e)
            else this.defaultTouchHandler(e);
        });
        document.addEventListener("touchmove", e => {
            if(this.touchMoveHandlers.has(this.target)) this.touchMoveHandlers.get(this.target)(e)
            else this.defaultTouchHandler(e);
        });


        this.touchEndHandlers.set("threedee", (e) => {
            if(!this.touchMoveHappened){
                let me = new MouseEvent("mousedown", {
                    buttons: 1
                });
                this.mouseDownHandlers.get("threedee")(me);
            }
        });

        this.touchMoveHandlers.set("threedee", (e) => {
            if(this.interactionState == InteractionState.Move){
                let dist = (x1, y1, x2, y2) => Math.sqrt(Math.pow(x1 - x2,2) + Math.pow(y1+y2, 2));
                if(e.touches.length == 1){
                    //One touch detected, only update movement
                    let currentTouch = e.touches[0];
                    let previousTouches = this.lastTouches[0];
                    let movementX = (currentTouch.clientX - previousTouches.clientX) / 10;
                    let movementY = (currentTouch.clientY - previousTouches.clientY) / 10;
                    Services.DebugService.putDebug(`Movement X ${movementX}\nMovement Y ${movementY}`);
                    Services.DebugService.commitDebug();
                    Services.PositionService.moveCamera(movementX, movementY);
                    this.touchMoveHappened = true;
                }else if(e.touches.length == 2){
                    //Two touches detected, update Movement, Zoom and Rotation
                    let currentTouch = e.touches[0];
                    let previousTouches = this.lastTouches[0];
                    let movementX = (currentTouch.clientX - previousTouches.clientX) / 10;
                    let movementY = (currentTouch.clientY - previousTouches.clientY) / 10;

                    let old_touch_dist = dist(this.lastTouches[0].clientX, this.lastTouches[0].clientY, this.lastTouches[1].clientX, this.lastTouches[1].clientY);
                    let new_touch_dist = dist(e.touches[0].clientX, e.touches[0].clientY, e.touches[1].clientX, e.touches[1].clientY);

                    let zoom = (new_touch_dist - old_touch_dist) / 10;

                    let old_angle = Math.atan2(this.lastTouches[1].clientY - this.lastTouches[0].clientY, this.lastTouches[1].clientX - this.lastTouches[0].clientX);
                    let new_angle = Math.atan2(e.touches[1].clientY - e.touches[0].clientY, e.touches[1].clientX - e.touches[0].clientX);

                    let angle_diff = new_angle - old_angle;

                    Services.PositionService.zoomCamera(zoom * 0.3);
                    Services.PositionService.tiltCamera(angle_diff, 0);

                    Services.DebugService.putDebug(`Movement X ${movementX}\nMovement Y ${movementY}\nZoom: ${new_touch_dist - old_touch_dist}\n Old Touch Dist ${old_touch_dist}\nNew Touch Dist ${new_touch_dist}\n Angle Diff ${angle_diff}`);
                    Services.DebugService.commitDebug();
                    Services.PositionService.moveCamera(movementX, movementY);
                    this.touchMoveHappened = true;
                    e.preventDefault();
                }else if(e.touches.length >= 3){

                }


                /*if (e.buttons == 1){
                    if(e.ctrlKey){
                        Services.PositionService.tiltCamera(e.movementX, e.movementY)
                    } else {
                        Services.PositionService.moveCamera(e.movementX, e.movementY)
                    }
                }else if (e.buttons == 4){
                    Services.PositionService.tiltCamera(e.movementX, e.movementY)
                } */
            } 
            if(e.touches.length != this.lastTouches.length){
                this.lastTouches = e.touches;
            }
        });

        this.mouseDownHandlers.set("threedee", (e) => {
            if(this.interactionState == InteractionState.Move){
                /*if(e.buttons == 2){
                    Services.OverlayService.addCurrentLayerOverlay();
                }*/
            } else if (this.interactionState == InteractionState.Overlay) {
                if (e.buttons == 1){
                    Services.OverlayService.addCurrentLayerOverlay();
                } 
            } else if (this.interactionState == InteractionState.Measure){
                if (e.buttons == 1){
                    Services.MeasurementService.set_start()
                }
            }
        })
        this.mouseMoveHandlers.set("threedee", (e) => {
            if(this.interactionState == InteractionState.Move){
                if (e.buttons == 1){
                    if(e.ctrlKey){
                        Services.PositionService.tiltCamera(e.movementX, e.movementY)
                    } else {
                        Services.PositionService.moveCamera(e.movementX, e.movementY)
                    }
                }else if (e.buttons == 4){
                    Services.PositionService.tiltCamera(e.movementX, e.movementY)
                } 
            }else if(this.interactionState == InteractionState.Measure){
                if(e.buttons == 1){
                    Services.MeasurementService.set_end();
                }else{
                    
                }
            }
        });
        //Handle new gamepad connections
        window.addEventListener("gamepadconnected", (e: GamepadEvent) => {
            console.log(`Connected gamepad ID ${e.gamepad.id} with ${e.gamepad.buttons.length} buttons and ${e.gamepad.axes.length} axes`);
            //this.gamepadid = e.gamepad.index;
        });
        //Handle gamepad disconnections
        window.addEventListener("gamepaddisconnected", (e: GamepadEvent) => {
            console.log(`Disconnected gamepad with ID ${e.gamepad.id}`);
            if(this.gamepadid == e.gamepad.index){
                this.gamepadid = null;
                //Look for new gamepad
                //@ts-ignore
                for(let gp of Navigator.getGamepads()){
                    if(gp != null){
                        this.gamepadid = gp.index;
                        console.log(`Switching to gamepad ID {} with ${gp.buttons.length} buttons and ${gp.axes.length} axes`);
                    }
                }
            }
        });

        ServiceBarrier.wait().then(() => {
            requestAnimationFrame(() => this.updateGamepad());
        });
    }

    private updateGamepad(){
        let toggle_rotation_lock_energy = 0;
        let gamepad = this.getGamepad();
        if(gamepad != null){
            //See https://luser.github.io/gamepadtest/ for button config
            let left_stick_x = gamepad.axes[0] || 0;
            let left_stick_y = gamepad.axes[1] || 0;
            let right_stick_x = gamepad.axes[2] || 0;
            let right_stick_y = gamepad.axes[3] || 0;

            let speed = 20;
            let threshold = 0.2;
            let zoom_speed = 0.2;

            if((Math.abs(right_stick_x) > threshold) || Math.abs(right_stick_y) > threshold){
                Services.PositionService.tiltCamera(right_stick_x * speed * -1, right_stick_y * speed * -1);
            }
            if((Math.abs(left_stick_x) > threshold) || Math.abs(left_stick_y) > threshold){
                Services.PositionService.moveCamera(left_stick_x * speed * -1, left_stick_y * speed * -1);
            }

            let left_back_trigger = gamepad.buttons[6];
            let right_back_trigger = gamepad.buttons[7];

            if(Math.abs(left_back_trigger?.value || 0) > threshold){
                Services.PositionService.zoomCamera(left_back_trigger.value * zoom_speed);
            }
            
            if(Math.abs(right_back_trigger?.value || 0) > threshold){
                Services.PositionService.zoomCamera(-1 * right_back_trigger.value * zoom_speed);
            }

            let button_a = gamepad.buttons[0];
            let button_b = gamepad.buttons[1];
            let button_c = gamepad.buttons[2];
            let button_d = gamepad.buttons[3];

            if(button_a?.pressed){
                let pos = Services.PositionService.getCameraPosition();
                pos.Azimuth = 0;
                pos.Elevation = 90;
                Services.PositionService.setCameraPosition(pos);
                Services.InteractionService.mousedown("compass");
            }

            toggle_rotation_lock_energy += button_b?.pressed ? 1 : -1;
            if(toggle_rotation_lock_energy < 0){
                toggle_rotation_lock_energy = 0;
            }

            if(toggle_rotation_lock_energy > 2){
                Services.PositionService.rotation_locked = !Services.PositionService.rotation_locked;
                toggle_rotation_lock_energy = -100;
            }

            let button_left_shoulder = gamepad.buttons[4];
            let button_right_shoulder = gamepad.buttons[5];
            let dpad_left = gamepad.buttons[14];
            let dpad_right =gamepad.buttons[15];
            let dpad_up = gamepad.buttons[12];
            let dpad_down = gamepad.buttons[13];

            if(button_left_shoulder?.pressed){Services.TimeService.moveTimeStart();}
            if(button_right_shoulder?.pressed){Services.TimeService.moveTimeEnd();}
            if(dpad_left?.pressed){Services.TimeService.moveTimeEarlier();}
            if(dpad_right?.pressed){Services.TimeService.moveTimeLater();}
            if(dpad_up?.pressed){Services.TimeService.increaseTimeRange();}
            if(dpad_down?.pressed){Services.TimeService.reduceTimeRange();}


            //console.log(`A0=${gamepad.axes[0]} A1=${gamepad.axes[1]}`);
        }
        requestAnimationFrame(() => this.updateGamepad());
    }

    public getGamepad(): Gamepad{
        if(this.gamepadid == null){
            return null;
        }else{
            return navigator.getGamepads()[this.gamepadid];
        }
    }

    mousedown(target: string, e?: MouseEvent){
        if(e){
            this.lastMousePosition = {x: e.clientX, y: e.clientY}
        }
        this.target = target;
    }

    touchstart(target: string, e?: TouchEvent){
        if(e){
            this.lastTouches = e.touches;
        }
        this.target = target;
        this.touchMoveHappened = false;
    }

    setInteractionState(state: InteractionState){
        if(state != this.interactionState){
            this.dispatchEvent(new InteractionStateChangedEvent(this.interactionState, state));
            this.interactionState = state;
        }
    }

    getInteractionState() {
        return this.interactionState;
    }

    defaultHandler(e: MouseEvent){
    }

    defaultTouchHandler(e: TouchEvent){

    }

    getTarget(): string{
        return this.target;
    }

}