var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:'dbox-'+this.$props.box_id,staticClass:"dialog-box",style:({
        'left': this.$props.dbox.pos_x + 'vw',
        'top': this.$props.dbox.pos_y + 'vh',
        'width': this.$props.dbox.size_x + 'rm',
        'height': this.$props.dbox.size_y + 'rm',
        'z-index': this.$props.dbox.pos_z + 2001,
        'transform': this.$props.dbox.referenceTransform
    }),on:{"mousedown":_vm.dialog_mousedown}},[_c('div',{staticClass:"dialog-box-title",on:{"mousedown":function($event){$event.stopPropagation();return _vm.move_start.apply(null, arguments)}}},[_c('span',[_vm._v(" "+_vm._s(this.$props.dbox.title)+" ")]),_c('div',{staticClass:"dialog-close-button",on:{"click":function($event){$event.stopPropagation();return _vm.closeDialog.apply(null, arguments)}}},[_c('SvgIcon',{attrs:{"name":"close"}})],1)]),_c('div',{staticClass:"content"},[_vm._t("default")],2),_c('div',{staticClass:"border-left border-top",on:{"mousedown":function($event){$event.stopPropagation();return ((e) => _vm.resize_start(e, 5)).apply(null, arguments)}}}),_c('div',{staticClass:"horizontal-edge border-top",on:{"mousedown":function($event){$event.stopPropagation();return ((e) => _vm.resize_start(e, 4)).apply(null, arguments)}}}),_c('div',{staticClass:"border-right border-top",on:{"mousedown":function($event){$event.stopPropagation();return ((e) => _vm.resize_start(e, 6)).apply(null, arguments)}}}),_c('div',{staticClass:"border-right vertical-edge",on:{"mousedown":function($event){$event.stopPropagation();return ((e) => _vm.resize_start(e, 2)).apply(null, arguments)}}}),_c('div',{staticClass:"border-right border-bottom",on:{"mousedown":function($event){$event.stopPropagation();return ((e) => _vm.resize_start(e, 10)).apply(null, arguments)}}}),_c('div',{staticClass:"horizontal-edge border-bottom",on:{"mousedown":function($event){$event.stopPropagation();return ((e) => _vm.resize_start(e, 8)).apply(null, arguments)}}}),_c('div',{staticClass:"border-left border-bottom",on:{"mousedown":function($event){$event.stopPropagation();return ((e) => _vm.resize_start(e, 9)).apply(null, arguments)}}}),_c('div',{staticClass:"border-left vertical-edge",on:{"mousedown":function($event){$event.stopPropagation();return ((e) => _vm.resize_start(e, 1)).apply(null, arguments)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }