<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div id="settings-grid">
        <fold-group name="Display Settings" :expanded="true">
            <div class="settings-grid">
            <span title="Pixels rendered per pixel displayed">Render Resolution</span>
            <select v-model="ScalingFactor" @change="scalingFactorChanged" title="Pixels rendered per pixel displayed">
                <option value="2.0">200%</option>
                <option value="1.5">150%</option>
                <option selected="selected" value="1">100%</option>
                <option value="0.75">75%</option>
                <option value="0.66">66%</option>
                <option value="0.5">50%</option>
                <option value="0.33">33%</option>
                <option value="0.25">25%</option>
            </select>
            <span title="Data samples to load per pixel">Data Resolution</span>
            <select v-model="TargetTileCount" @change="targetTileCountChanged" title="Data samples to load per pixel">
                <option value="32">400%</option>
                <option value="16">200%</option>
                <option selected="selected" value="8">100%</option>
                <option value="4">50%</option>
                <option value="2">25%</option>
                <option value="1">12%</option>
            </select>
            <span title="Redraw even when nothing has changed">Always Rerender</span>
            <input type="checkbox" @change="setAlwaysRerender" v-model="AlwaysRerender" title="Redraw even when nothing has changed"/>
            <span title="Background color for the 3D area">Background color</span>
            <input type="color" v-model="ClearColor" @change="setClearColor" title="Background color for the 3D area"/>
            <div class="button" @click="hideUI">Hide All UI</div><span>Press H to show it again.</span>
            </div>
        </fold-group>
        <br/>
        <fold-group name="Application Settings">
            <div class="settings-grid">
                <span title="Slow down playback if data is still loading">Wait For Data During Playback</span>
                <input type="checkbox" @change="setWaitForLoad" v-model="WaitForLoad" title="Slow down playback if data is still loading"/>
                <span title="Font size for menus and buttons">Font size</span> 
                <div><button @click="FontSize--; fontSizeChanged();">-</button>&nbsp;{{FontSize}}&nbsp;<button @click="FontSize++; fontSizeChanged();">+</button></div>
                <button @click="clearSettings" title="Reset saved user settings">Clear Settings</button>
                <button @click="clearCache" title="Clear cached data">Clear Cache</button>
                <span title="Select Gamepad for control of the viewer">Controller</span> 
                <select v-model="SelectedGamepad" title="Select Gamepad for control of the viewer" @change="selectGamepad">
                    <option :value="null">(no gamepad)</option>
                    <option v-for="(gamepad, index) in Gamepads" :key="gamepad.id" :value="gamepad">{{"Controller " + index + " (" + gamepad.id + ")"}}</option>
                </select>
            </div>
        </fold-group>
        <br/>
        <fold-group name="Special Dome Settings">
            <!--TODO: Make these only appear when relevant modes are active. -->
            <div class="settings-grid">
                <span title="Uncheck to hide all UI elements">UI Visible</span>
                <input type="checkbox" @change="setUIVisible" v-model="UIVisible" title="Uncheck to hide all UI elements"/>
                <span title="Projection mode to use">Projection Mode</span>
                <select v-model="DomeMode" title="Projection mode to use" @change="selectDomeMode">
                    <option value="normal">Regular</option>
                    <option value="domemaster">Fisheye Equidistant (Dome Master)</option>
                    <option selected="selected" value="normal_red_blue">Stereo Anaglyph (R/B)</option>
                    <option v-if="DisplayIds.length > 0" value="single_projector">Offset Projector</option>
                </select>
                <span v-show="DomeMode == 'single_projector'" title="Display Region">Display Region</span>
                <select v-show="DomeMode == 'single_projector'" v-model="DisplayRegion" title="Display region to use for this projector" @change="selectDisplayRegion">
                    <option v-for="id in DisplayIds" :key="id" :value="id">{{id}}</option>
                </select>
                <span v-show="DomeMode == 'single_projector'" title="Rotation Axis Order in Projector Configuration">Rotation Order</span>
                <select v-show="DomeMode == 'single_projector'" v-model="AxisOrder" @change="selectAxisOrder">
                    <option v-for="id in AxisOrders" :key="id" :value="id">{{AxisOrderHumanReadable(id)}}</option>
                </select>
                <span v-show="DomeMode == 'single_projector' || DomeMode =='domemaster'" title="Dome Tilt Angle">Tilt Angle</span>
                <NumberInput v-show="DomeMode == 'single_projector' || DomeMode =='domemaster'" style="width: 100%" :min="0" :max="360" :decimals="1" :step="5" unit="°" v-model="DomeTilt" @change="setDomeTilt"/>
                <span title="Synchronize state with other viewers on the same link">Synchronize</span>
                <input type="checkbox" @change="setSynchronized" v-model="Synchronized" title="Synchronize state with other viewers on the same link"/>
                <button @click="syncScene">Sync scene</button>
            </div>
        </fold-group>
        <br/>
        <fold-group name="Version Information">
            <div class="settings-grid">
                <template v-for="version in Versions">
                    <span style="font-weight: bold;"  :key="version.attribute">{{version.attribute}}</span>
                    <span contenteditable="contenteditable" style="max-width: 10em; overflow: hidden; overflow-x: scroll; whitespace: nowrap; font-family: monospace; font-size: 80%;" :key="version.version">{{version.version}}</span>
                </template>        
            </div>
        </fold-group>
        <span style="color: #ff0000" title="Something went wrong. Maybe send this error message to digitalearthviewer@geomar.de?">{{SceneError}}</span>
    </div>
</template>

<style lang="scss">
    @import '../_guikit.scss';

    button{
        margin: 0;
    }

    .button.copy{
        transition: background-color 0.5s;
    }

    .button.copy[copySuccessful]{
        background-color: $guikit_lightgreen;
    }

    h2{
        font-size: 120%;
        grid-column: 1 / span 2;
        margin: 0;
        margin-top: 0.25em;
    }

    #settings-button{
        @extend %symbol_button;
        position: absolute;
        top: calc($grid / 2);
        left: calc($grid / 2);
        z-index: 1000000;
        font-size: 150%;
        font-weight: bold;
        transition: color 0.25s;
        line-height: 100%;
        width: 1.2em;
        height: 1.2em;
    }


    #settings-card{
        @extend %generic_box;
        position: absolute;
        top: calc($grid / 2);
        left: calc($grid / 2);
        z-index: 99999999;
        max-height: 66vh;
        transition: right 0.5s, opacity 3s;
        overflow-x: hidden;
    }

    #settings-card>h1{
        font-size: 1em;
        border-bottom: 1px solid $fg_color;
    }

    .settings-grid{
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 0.5em;
        column-gap: 0.3em;
        justify-items: baseline;
        align-items: baseline;
    }

    #settings-grid hr{
        height: 1px;
    }

</style>

<script lang="ts">
import { KeyDownEvent } from '../services/InteractionService';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Vec3 } from '../modules/vecmat';
import { FormattingService } from '../services/FormattingService';
import { GLBarrier, Services } from '../services/Services';
import SvgIcon from './guikit/svgIcon.vue';
import NumberInput from './guikit/numberinput.vue';
import FoldGroup from './guikit/foldGroup.vue';
import { Parameter } from '../modules/Parameter';
import { SettingsChangedEvent } from '@/services/SettingsService';
import { ProjectorConfigurationService } from '../services/ProjectorConfigurationService';
@Component({
    components: {
        SvgIcon,
        NumberInput,
        FoldGroup
    }
})
export default class Settings extends Vue{
    private shown = false;
    stupid = true;
    private SceneError = "";
    private SceneName = "scene";

    //ScalingFactor (Render Resolution)
    private ScalingFactor: number = null;
    private scalingFactorChanged(){
        Services.SettingsService.getSetting("ScalingFactor").value = this.ScalingFactor;
        Services.AdaptivePerformanceService.RequestRerender();
    }

    //Target Tile Count (Data Resolution)
    private TargetTileCount: number = null;
    private targetTileCountChanged(){
        Services.SettingsService.getSetting("TargetTileCount").value = this.TargetTileCount;
        Services.AdaptivePerformanceService.RequestRerender();
    }

    //Always Rerender
    private AlwaysRerender: boolean = null;
    setAlwaysRerender(e){
        Services.SettingsService.getSetting("AlwaysRerender").value = e.target.checked;
    }

    //ClearColor (Background Color)
    private ClearColor: string = null;
    private setClearColor(){
        console.log(this.ClearColor);
        let color = FormattingService.color_hex_to_vec3(this.ClearColor);
        Services.SettingsService.getSetting("ClearColor").value = color;
    }

    //WaitForLoad (Slow down playback)
    private WaitForLoad: boolean = null;
    setWaitForLoad(){
        Services.SettingsService.getSetting("WaitForLoad").value = this.WaitForLoad;
    }

    //Font Size
    private FontSize: number = null;
    private fontSizeChanged(){
        let fontsize = Services.SettingsService.getSetting("FontSize");
        let size = this.FontSize | 0;
        fontsize.value = Math.min(Math.max(fontsize.range[0], size), fontsize.range[1]);
        document.querySelector("html").style["font-size"] = fontsize.value + "pt";
        this.FontSize = fontsize.value;
    }

    //Clearing settings and cache
    async clearCache(){
        let renderlayers = Services.SerializationService.getSceneString();
        try{
            let result = await fetch(`create_scene/${encodeURIComponent(this.SceneName)}`, {
                method: "POST",
                body: renderlayers
            }).then(r => r.text());
            window.indexedDB.deleteDatabase("data");
            window.location.search = result;
        }catch(e){
            this.SceneError = e.message;
        }
    }
    async clearSettings(){
        localStorage["digitalearthviewer_settings"] = [];
        Services.SettingsService.skip_storing = true;
        let renderlayers = Services.SerializationService.getSceneString();
        try{
            let result = await fetch(`create_scene/${encodeURIComponent(this.SceneName)}`, {
                method: "POST",
                body: renderlayers
            }).then(r => r.text());
            window.indexedDB.deleteDatabase("data");
            window.location.search = result;
        }catch(e){
            this.SceneError = e.message;
        }
    }

    //Gamepad
    private Gamepads: Gamepad[] = null;
    private SelectedGamepad: Gamepad = null;
    selectGamepad(){
        Services.InteractionService.gamepadid = this.SelectedGamepad?.index;
        console.log("Set gamepad to ", this.SelectedGamepad?.id)

    }

    

    //UIVisible
    private UIVisible: boolean = null;
    private setUIVisible(){
        Services.SettingsService.getSetting("UIVisible").value = this.UIVisible;
        Services.AdaptivePerformanceService.RequestRerender();
    }
    public hideUI(){
        this.UIVisible = false;
        this.setUIVisible();
    }

    //Dome Mode
    private DomeMode: string = null;
    selectDomeMode(){
        Services.SettingsService.getSetting("DomeMode").value = this.DomeMode;
        console.log("Setting Dome Mode to: ", this.DomeMode);
        Services.RenderService.forceDomeModeUpdate();
        this.stupid = !this.stupid;
    }

    //Display Region
    private DisplayIds: string[] = null;
    private DisplayRegion: string = null;
    selectDisplayRegion(){
        Services.SettingsService.getSetting("DisplayRegion").setValue(this.DisplayRegion);
        Services.ProjectorConfigurationService.setSelectedId(this.DisplayRegion);
        this.stupid = !this.stupid;
    }

    //Tilt angle
    private DomeTilt: number = null;
    private setDomeTilt(){
        Services.SettingsService.getSetting("DomeTilt").setValue(this.DomeTilt);
    }

    private AxisOrders = ProjectorConfigurationService.genRotationOrders();
    private AxisOrderHumanReadable(n: string): string {
        let lut = {
            "NH": "-Yaw",
            "PH": "+Yaw",
            "NP": "-Pitch",
            "PP": "+Pitch",
            "NR": "-Roll",
            "PR": "+Roll"
        };
        return lut[n.substr(0, 2)] + " · " + lut[n.substr(2, 2)] + " · " + lut[n.substr(4, 2)];
    }
    private AxisOrder: string = null;
    private AxisOrderChanged(){
        Services.ProjectorConfigurationService.setRotationOrderString(this.AxisOrder);
        this.AxisOrder = Services.ProjectorConfigurationService.getRotationOrderString();
        this.stupid = !this.stupid;
    }
    selectAxisOrder(){
        console.log("Select Axis Order is unimplemented");
    }

    //Synchronization
    private Synchronized: boolean = null;
    private setSynchronized(){
        if(this.Synchronized){
            if(Services.InitializationService.SceneName == ""){
                Services.InitializationService.SceneName = "default";
            }
            Services.SettingsService.getSetting("Synchronized").value = true;
            Services.SynchronizationService.tryStart();
        }else{
            Services.SynchronizationService.stop();
            Services.SettingsService.getSetting("Synchronized").value = false;
        }
    }

    //Version information
    private Versions = (() => Services.InitializationService.getVersionInfo())();
    
    //Sync scene
    private syncScene(){
        Services.SynchronizationService.syncScene();
    }

    //Constructor
    created(){
        this.registerProperty("ScalingFactor", () => Services.SettingsService.getValueOrDefault("ScalingFactor", 1));
        this.registerProperty("TargetTileCount", () => Services.SettingsService.getValueOrDefault("TargetTileCount", 8));
        this.registerProperty("AlwaysRerender", () => Services.SettingsService.getSetting("AlwaysRerender").value);
        this.registerProperty("ClearColor",  () => FormattingService.color_vec3_to_hex(Services.SettingsService.getSetting("ClearColor").value));
        this.registerProperty("WaitForLoad", () => Services.SettingsService.getSetting("WaitForLoad").value);
        this.registerProperty("FontSize", () => Services.SettingsService.getValueOrDefault("FontSize", 11));
        this.registerProperty("Gamepads", () => new Array(...navigator.getGamepads()).filter(e => !!e));
        this.registerProperty("SelectedGamepad", () => Services.InteractionService.getGamepad());
        this.registerProperty("UIVisible", () => Services.SettingsService.getSetting("UIVisible").value);
        this.registerProperty("DomeMode", () => Services.SettingsService.getValueOrDefault("DomeMode", "normal"));
        this.registerProperty("DisplayIds", () => Services.ProjectorConfigurationService.getConfigIds());
        this.registerProperty("DisplayRegion", () => Services.ProjectorConfigurationService.getSelectedId() || "");
        this.registerProperty("DomeTilt", () => Services.SettingsService.getValueOrDefault("DomeTilt",0));
        this.registerProperty("Synchronized", () => Services.SettingsService.getSetting("Synchronized").value);
        this.refreshValues();
    }

    //Setup
    private getters: {
        name: string,
        getter: ()=>any
    }[] = [];
    private registerProperty(name: string, getter: ()=>any){
        this.getters.push({
            name,
            getter
        });
    }
    private refreshValues(){
        for(let getter of this.getters){
            this[getter.name] = getter.getter();
        }
    }


    async mounted(){
        //Setup H key functionality
        Services.InteractionService.addEventListener("KeyDown", (k: KeyDownEvent) => {
            if(Services.InteractionService.getTarget() == "threedee"){
                if (k.key == "h"){
                    this.UIVisible = !Services.SettingsService.getSetting("UIVisible").value;
                }
            }
        });
        //Refresh when a setting changes
        Services.SettingsService.addEventListener("SettingsChanged", (e: SettingsChangedEvent) => {
            this.refreshValues();
            this.stupid = !this.stupid;
            this.$forceUpdate();
            });
        let gamepadhandler = (e: GamepadEvent) => {
            this.refreshValues();
            this.stupid = !this.stupid;
            this.$forceUpdate();
        };
        //Gamepads
        window.addEventListener("gamepaddisconnected",gamepadhandler);
        window.addEventListener("gamepadconnected", gamepadhandler);
        //Display Region
        GLBarrier.wait().then(() => {
            this.refreshValues();
        });
    }

    downloadLayerConfig(){
        let renderlayers = Services.SerializationService.getSceneString();
        let renderlayertext = "data:application/json;base64," + btoa(renderlayers);
        let a_elem = document.getElementById('downloadlink');
        a_elem.setAttribute("href", renderlayertext);
        a_elem.click();
    }

    uploadLayerConfig(){
        document.getElementById('inputlayerfile').click();
    }

    async handleLayerConfig(e){
        let input: any = document.getElementById('inputlayerfile');
        let file: File = input.files[0];
        if(file){
            let resp = await new Response(file).json();
            try{
                await Services.SerializationService.deserializeScene(resp);
            }catch(e){
                alert("Layer config could not be loaded: " + e);
            }
        }
    }

    
}
</script>