<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="scrollbox-outer">
        <div class="scrollbox-inner">
                <slot>
                </slot>
        </div>
    </div>
</template>
<style lang="scss">
$scrollbar_hider_space: 100px;

.scrollbox-inner{
    overflow-y: scroll;
    //width: calc(100% + $scrollbar_hider_space);
    padding-right: $scrollbar_hider_space;
    margin-right: - $scrollbar_hider_space;
}

.scrollbox-outer{
    overflow: hidden;
    display: flex;
}

</style>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
})
export default class ScrollBox extends Vue {

    innerwidth = "0px";
    mounted() {
        //this.innerwidth = this.$el.getBoundingClientRect().width + "px";
    }

    beforeUpdate(){
        //this.innerwidth = this.$el.getBoundingClientRect().width + "px";
    }

}
</script>