//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

export class ProgressReportingDownloadService{
    
    public downloadArrayBuffer(url: string, progress_callback: (progress: number)=>void, method: string="GET"): Promise<ArrayBuffer>{
        return new Promise((res, rej) =>{
            let xhr = new XMLHttpRequest();
            xhr.open(method,url);
            xhr.responseType = "arraybuffer";
            xhr.onprogress = (e) => progress_callback(e.loaded / (e.total || 1));
            xhr.onerror = (_e) => rej("Error status " + xhr.status);
            xhr.onreadystatechange = (_e) => {
                if(xhr.readyState === XMLHttpRequest.DONE) {
                    let status = xhr.status;
                    if (status === 0 || (status >= 200 && status < 400)) {
                      res(xhr.response);
                    } else {
                        rej("Error status " + xhr.status);
                    }
                  }
            };
            xhr.send();
        });
    }
}