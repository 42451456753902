<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="badge-style" :variant="variant">
        {{name}}
        <span class="badge-layer-remove" @click.stop="clearSlot">
            <SvgIcon name="close2"></SvgIcon>
        </span>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {SourceLayerInfo} from '../services/SourceInfoService';
import { RenderSourceSlot } from '../modules/rendersources/RenderSource';
import SvgIcon from './guikit/svgIcon.vue';
import { Services } from '@/services/Services';

@Component({
    components: {
        SvgIcon
    },
    props:['source', 'layer', 'slotname']
})
export default class SourceLayerBadge extends Vue{

    clearSlot(){
        console.log(this.$props);
        Services.RenderLayerService.setSourceOnLayer(this.$props.layer, this.$props.slotname, null);
        //this.$props.source.source = undefined;
    }

    get name(){
        if(this.$props.source.source){
            return this.$props.source.source.layer_name;
        }else{
            return "none";
        }
    }

    get variant(){
        if(!this.$props.source || !this.$props.source.source || !this.$props.source.source.layer){
            return "empty";
        }
        switch(this.$props.source.source.layer.layer_type){
            case "ColorTiles": return "image";
            case "ScalarTiles": return "scalar";
            case "ScalarPoints": return "points";
            case "ScalarLines": return "lines";
            default: return "none"
        }
    }
}
</script>

<style lang="scss">
    @import '../_guikit.scss';

    .badge-layer-remove{
        position: absolute;
        display: inline-block;
        right: 0.5em;
        width: 1em;
        height: 1em;
    }

    .badge-style[variant="image"]{
        background-color: $guikit_darkyellow;
        color: $fg_color;
    }

    .badge-style[variant="scalar"]{
        background-color: $guikit_darkblue;
        color: $fg_color;
    }

    .badge-style[variant="points"]{
        background-color: $guikit_darkgreen;
        color: $fg_color;
    }

    .badge-style[variant="lines"]{
        background-color: $guikit_darkred;
        color: $fg_color;
    }

    .badge-style[variant="none"]{
        background-color: $guikit_lightgrey;
    }

    .badge-style{
        background-color: $fg_color;
        color: $bg_color;
    }

    .badge-style{
        @extend %generic_box;
        position: relative;

    }

</style>