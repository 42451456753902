<!-- This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer -->
<template>
    <div class="number-input-container">
        <div class="number-input-slider" :style="{'opacity': editEnabled ? '0.0' : '1.0'}">
            <div class="number-input-progress" :style="{'left': ((progress - 1) * 100) + '%'}">
                <div class="number-input-text-container" :style="{'left': ((1 - progress) * 100) + '%', 'position': 'relative'}">
                    {{raw_value}}
                </div>
            </div>
            <div class="number-input-text-container">
                {{raw_value}}
            </div>
        </div>
        <input class="number-input-input" :style="{'opacity': editEnabled ? '1.0' : '0.0'}" @blur="editDone" @keypress="checkEnter" @focus="gotFocus" :value="this.$props.value">
        <div class="number-input-focus-captor" v-show="!editEnabled" @mousedown.stop="mousedown"></div>
        <div class="number-input-button-left" @mousedown.stop="decrease" v-show="!editEnabled">
            <SvgIcon name="left"></SvgIcon>
        </div>
        <div class="number-input-button-right" @mousedown.stop="increase" v-show="!editEnabled">
            <SvgIcon name="right"></SvgIcon>
        </div>
    </div>
</template>

<style lang="scss">
    @import '../../_guikit.scss';

    .number-input-focus-captor{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .number-input-slider{
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .number-input-text-container{
        @extend %generic_element;
        text-align: center;
        //padding: 0.3rem;
    }

    .number-input-container{
        @extend %generic_box_shadowed;
        background-color: $control_color;
        //height: 2rem;
        overflow: hidden;
        padding: 0;
        position: relative;
    }

    .number-input{
        display: none;
        width: 100%;
        max-width: 100% !important;
        background-color: transparent !important;
    }

    .number-input-input{
        @extend %generic_input;
        position: absolute;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        text-align: center;
        left: 0;
        top: 0;
        bottom: 0;
        }

    .number-input-progress{
        background-color: $hl_color;
        color: $bg_color;
        overflow: hidden;
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%;
        border-radius: $box_radius;
    }



    .number-input-button-left{
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        //padding: 1em;
        //padding-left: 0.6em;
        //padding-right: 0.6em;
        z-index: 100000000;
    }

    .number-input-button-left:hover{
        background-color: transparentize($color: white, $amount: 0.66);
        border: none;
    }

    .number-input-button-right{
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 10000000;
    }

    .number-input-button-right:hover{
        background-color: transparentize($color: white, $amount: 0.66);
        border: none;
    }

</style>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './svgIcon.vue';
import { Services } from '../../services/Services';
import { FormattingService } from '../../services/FormattingService';
import { InteractionService } from '../../services/InteractionService';
@Component({
    components: {
        SvgIcon
    },
    props:{
        value: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            required: false
        },
        max: {
            type: Number,
            required: false
        },
        step: { 
            type: Number,
            required: false
        },
        unit: {
            type: String,
            required: false
        },
        decimals: {
            type: Number,
            required: false
        }
    }
})
export default class NumberInput extends Vue{
    uid;
    travelledDistance: number;
    editEnabled = false;

    get progress(){
        if(this.$props.min != null && this.$props.max != null)
            return (this.$props.value - this.$props.min) / (this.$props.max - this.$props.min);
        return 0;
    }

    get raw_value(){
        return FormattingService.num_to_string_unit_si(this.$props.value, this.$props.unit, 3);
    }

    mounted(){
        this.uid = Services.UIDService.getUid();
        Services.InteractionService.mouseMoveHandlers.set(this.uid, this.mousemove);
        Services.InteractionService.mouseUpHandlers.set(this.uid, this.mouseup);
    }

    checkEnter(e){
        if(e.keyCode == 13){
            this.editDone(e);
        }
    }

    editDone(e){
        let v = parseFloat(e.target.value);
        if(!isNaN(v)){
            this.setValue(v);
        }
        this.editEnabled = false;
        return false;
    }

    gotFocus(e){
        this.editEnabled = true;
        e.target.setSelectionRange(0, e.target.value.length);
    }

    destroyed(){
        Services.InteractionService.mouseMoveHandlers.delete(this.uid);
        Services.InteractionService.mouseUpHandlers.delete(this.uid);
    }

    mousedown(e){
        Services.InteractionService.mousedown(this.uid);
        this.travelledDistance = 0;
    }

    setValue(v) {
        if(this.$props.min != null)
            v = Math.max(v, this.$props.min);
        if(this.$props.max != null)
            v = Math.min(v, this.$props.max);
        this.$emit("input", v);
    }

    mousemove(e: MouseEvent){
        if(e.buttons == 1){
            if(!this.editEnabled){
                this.travelledDistance += Math.abs(e.movementX);
                let tw = this.$el.getBoundingClientRect().width;
                let mscale;
                if(this.$props.min != null && this.$props.max != null){
                    mscale = this.$props.max - this.$props.min;
                } else {
                    mscale = Math.max(1, Math.abs(this.$props.value / 10));
                }
                this.setValue(this.$props.value + e.movementX / tw * mscale);
            }
        }
    }

    mouseup(e: MouseEvent){
        if(this.travelledDistance == 0){
            this.editEnabled = true;
            (this.$el.getElementsByClassName("number-input-input")[0] as HTMLInputElement).focus();
        }
    }

    decrease(e){
        if(this.$props.step){
            this.setValue(this.$props.value - this.$props.step) + "";
        }else{
            this.setValue(this.$props.value - 1) + "";
        }
    }

    increase(e){
        if(this.$props.step){
            this.setValue(this.$props.value + this.$props.step) + "";
        }else{
            this.setValue(this.$props.value + 1) + "";
        }
    }


}
</script>