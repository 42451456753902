//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

/*
The GLService initializes and provides a GL context which is initialized from a canvas (usually threedee.vue)
It loads extensions, creates meshes and does some other initialization
*/

import {Services} from './Services';
import { Shaders } from '../shaders/Shaders';

export type Shader = {
    name: string,
    program: WebGLProgram,
    uniforms: {[name: string]: WebGLUniformLocation},
    attributes: {[name: string]: number}
}

const STITCHING_PYRAMID_SLOPE_WIDTH = 1.0;
const STITCHING_PYRAMIT_SLOPE_HEIGHT = 1.0;

export class GLService extends EventTarget{
    //the rendering context of the canvas
    public GL: WebGLRenderingContext;
    //which gl extensions to load
    public REQUIRED_EXTENSIONS = [
        "WEBGL_draw_buffers",
        "OES_texture_float",
        "OES_texture_float_linear",
        "EXT_frag_depth",
        "WEBGL_depth_texture",
        "WEBGL_color_buffer_float",
        "ANGLE_instanced_arrays"
    ];
    //The gl context as property "gl" and all loaded extensions under their respective names
    public Extensions: {[name: string]: WebGLRenderingContext | any}
    //The programs, organized as modules from the "shaders" directory (built by createShaders.js)
    public Modules: {[module: string]: {[program: string]: Shader}} = {};
    //The geometries
    public Geometries: {[name: string]: {buffer: WebGLBuffer, length: number, start: number, mode: number}};
    
    constructor(gl: WebGLRenderingContext){
        super();
        this.GL = gl;
        this.init_extensions();
        this.loadShaders();
        this.create_geometry();
    }

    //Raises the FrameDone event which triggers a few actions
    public raiseFrameDoneEvent(){
        this.dispatchEvent(new Event("FrameDone"));
    }

    //Returns the GL context with it's extensions
    public getContext(){
        return this.Extensions;
    }

    private createVertexShader(source): WebGLShader {
        var vs = this.GL.createShader(this.GL.VERTEX_SHADER);
        this.GL.shaderSource(vs, source);
        this.GL.compileShader(vs);
        if(!this.GL.getShaderParameter(vs, this.GL.COMPILE_STATUS)){
            throw(this.GL.getShaderInfoLog(vs) + "\n" + source.split("\n").map((x, i) => (i + 1) + ":\t" + x).join("\n"));
        }
        return vs;
    }

    private createFragmentShader(source): WebGLShader {
        var fs = this.GL.createShader(this.GL.FRAGMENT_SHADER);
        this.GL.shaderSource(fs, source);
        this.GL.compileShader(fs);
        if(!this.GL.getShaderParameter(fs, this.GL.COMPILE_STATUS)){
            throw(this.GL.getShaderInfoLog(fs) + "\n" + source.split("\n").map((x, i) => (i + 1) + ":\t" + x).join("\n"));
        }
        return fs;
    }

    private createProgram(vshader, fshader) {
        var program = this.GL.createProgram();
        this.GL.attachShader(program, vshader);
        this.GL.attachShader(program, fshader);
        this.GL.linkProgram(program);
        if(!this.GL.getProgramParameter(program, this.GL.LINK_STATUS)){
            throw(this.GL.getProgramInfoLog(program));
        }
        this.GL.detachShader(program, vshader);
        this.GL.detachShader(program, fshader);
        //Clean up
        this.GL.deleteShader(vshader);
        this.GL.deleteShader(fshader);
        //Get locations
        var uniforms = {}
        for(var i = 0; i < this.GL.getProgramParameter(program, this.GL.ACTIVE_UNIFORMS); i++){
            var o = this.GL.getActiveUniform(program, i);
            uniforms[o.name] = this.GL.getUniformLocation(program, o.name);
        }
        var attributes = {}
        for(var i = 0; i < this.GL.getProgramParameter(program, this.GL.ACTIVE_ATTRIBUTES); i++){
            var o = this.GL.getActiveAttrib(program, i);
            attributes[o.name] = this.GL.getAttribLocation(program, o.name);
        }
        //Return program
        return {
            program: program,
            name: "",
            uniforms: uniforms,
            attributes: attributes
        };
    }
    
    ///Loads the shaders
    private loadShaders(){
        this.Modules = {};
        for(var module_name in Shaders){
            this.Modules[module_name] = {};
            let module = Shaders[module_name];
            for(var program_name in module){
                let program_source = module[program_name];
                try{
                    if(module_name == "sources"){
                        //For shader polymorphism related to projection styles.
                            //@ts-ignore
                        this.Modules[module_name][program_name] = {};
                        ["SPHERE", "EQUIRECT", "POLAR"].forEach((p) => {
                            var vs = this.createVertexShader("#define " + p + "\n" + program_source.vertex);
                            var fs = this.createFragmentShader("#define " + p + "\n" + program_source.fragment);
                            var prog = this.createProgram(vs, fs);
                            prog.name = program_name + "_" + p;
                            this.Modules[module_name][program_name][p] = prog;
                        })
                    } else {
                        var vs = this.createVertexShader(program_source.vertex);
                        var fs = this.createFragmentShader(program_source.fragment);
                        var prog = this.createProgram(vs, fs);
                        prog.name = module_name
                        this.Modules[module_name][program_name] = prog;
                    }
                } catch (e) {
                    console.error("Shader error:", module_name + ":" + program_name + ":", e);
                }
            }
        }
    }

    //Initializes the required extensions and adds the gl context
    private init_extensions(){
        this.Extensions = {};
        for(var extension of this.REQUIRED_EXTENSIONS){
            this.Extensions[extension] = this.GL.getExtension(extension);
        }
        this.Extensions["gl"] = this.GL;
        this.Extensions["Gl"] = this.GL;
        this.Extensions["GL"] = this.GL;
    }

    //Creates all geometries
    private create_geometry(){
        this.Geometries = {
            tile: this.create_tile_geometry(),
            quad: this.create_quad_geometry(),
            arrow: this.create_arrow_geometry(),
            arrow_normals: this.create_arrow_normals(),
            tracers: this.create_tracer_geometry(),
            lines: this.create_line_segment_geometry(),
            selection_box: this.create_selection_box_geometry(),
            stitching: this.create_stitching_pyramid_geometry()
        };
    }

    //Creates a subdivided rectangle as a WebGLBuffer, with enough divisions to support displacement with mesh resolution
    //Range is from [-1, -1] to [1, 1], to be rendered as GL.TRIANGLES
    private create_tile_geometry(){
        var geometry = {buffer: this.GL.createBuffer(), length: 0, start: 0, mode: this.GL.TRIANGLES};
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, geometry.buffer);
        const divs = Services.InitializationService.getTileWidth() / 4;
        let gb = new Float32Array(divs * divs * 2 * 2 * 3);
        const stepsize = 2 / divs;
        geometry.length = 0;
        for(var i = 0; i < divs * divs; i++){
            var x = (i % divs) / divs * 2 - 1;
            var y = Math.floor(i / divs) / divs * 2 - 1;
            var i2 = i * 12;
            //upper left triangle
            gb[i2] = x;
            gb[i2 + 1] = y;

            gb[i2 + 2] = x;
            gb[i2 + 3] = y + stepsize

            gb[i2 + 4] = x + stepsize;
            gb[i2 + 5] = y;
            //lower right triangle
            gb[i2 + 6] = x + stepsize;
            gb[i2 + 7] = y;

            gb[i2 + 8] = x;
            gb[i2 + 9] = y + stepsize;

            gb[i2 + 10] = x + stepsize;
            gb[i2 + 11] = y + stepsize;
            //
            geometry.length += 6;
        }
        this.GL.bufferData(this.GL.ARRAY_BUFFER, gb, this.GL.STATIC_DRAW);
        return geometry;
    }

    private create_selection_box_geometry(){
        var geometry = {buffer: this.GL.createBuffer(), length: 0, start: 0, mode: this.GL.TRIANGLES};
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, geometry.buffer);
        const divs = Services.InitializationService.getTileWidth() / 4;
        let gb = new Float32Array((divs * 2 + 4) * divs * 2 * 3 * 3);
        const stepsize = 2 / divs;
        geometry.length = 0;
        let gi = 0;
        for(var i = 0; i < divs; i++){
            let ifrac = i / divs * 2 - 1;
            for(var k = 0; k < 2; k++){
                //top and bottom
                for(var j = 0; j < divs; j++){
                    let jfrac = j / divs * 2 - 1;
                    gb[gi++] = ifrac;
                    gb[gi++] = jfrac;
                    gb[gi++] = k;
        
                    gb[gi++] = ifrac;
                    gb[gi++] = jfrac + stepsize
                    gb[gi++] = k;
        
                    gb[gi++] = ifrac + stepsize;
                    gb[gi++] = jfrac;
                    gb[gi++] = k;
                    //lower right triangle
                    gb[gi++] = ifrac + stepsize;
                    gb[gi++] = jfrac;
                    gb[gi++] = k;
        
                    gb[gi++] = ifrac;
                    gb[gi++] = jfrac + stepsize;
                    gb[gi++] = k;
        
                    gb[gi++] = ifrac + stepsize;
                    gb[gi++] = jfrac + stepsize;
                    gb[gi++] = k;

                    geometry.length += 6;
                }

                //front and back (x)
                gb[gi++] = 2 * k - 1;
                gb[gi++] = ifrac;
                gb[gi++] = 1;
        
                gb[gi++] = 2 * k - 1;
                gb[gi++] = ifrac;
                gb[gi++] = 0;

                gb[gi++] = 2 * k - 1;
                gb[gi++] = ifrac + stepsize
                gb[gi++] = 1;
                    //lower right triangle
                gb[gi++] = 2 * k - 1;
                gb[gi++] = ifrac;
                gb[gi++] = 0;
        
                gb[gi++] = 2 * k - 1;
                gb[gi++] = ifrac + stepsize;
                gb[gi++] = 0;
        
                gb[gi++] = 2 * k - 1;
                gb[gi++] = ifrac + stepsize;
                gb[gi++] = 1;

                geometry.length += 6;

                //left and right (y)

                gb[gi++] = ifrac;
                gb[gi++] = 2 * k - 1;
                gb[gi++] = 1;
        
                gb[gi++] = ifrac;
                gb[gi++] = 2 * k - 1;
                gb[gi++] = 0;
        
                gb[gi++] = ifrac + stepsize;
                gb[gi++] = 2 * k - 1;
                gb[gi++] = 1;
                    //lower right triangle
                gb[gi++] = ifrac + stepsize;
                gb[gi++] = 2 * k - 1;
                gb[gi++] = 0;
        
                gb[gi++] = ifrac;
                gb[gi++] = 2 * k - 1;
                gb[gi++] = 0;
        
                gb[gi++] = ifrac + stepsize;
                gb[gi++] = 2 * k - 1;
                gb[gi++] = 1;

                geometry.length += 6;

            }
        }
        this.GL.bufferData(this.GL.ARRAY_BUFFER, gb, this.GL.STATIC_DRAW);
        return geometry;
    }

    //Creates a new quad geometry as a WebGLBuffer, usually used for copying over one image to another
    //Range is from [-1, -1] to [1, 1], to be rendered with GL.TRIANGLE_STRIP
    private create_quad_geometry(){
        var geometry = {buffer: this.GL.createBuffer(), length: 4, start: 0, mode: this.GL.TRIANGLE_STRIP};
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, geometry.buffer);
        let gb = new Float32Array([
            -1, 1,
            1, 1,
            -1, -1,
            1, -1
        ]);
        this.GL.bufferData(this.GL.ARRAY_BUFFER, gb, this.GL.STATIC_DRAW);
        return geometry;
    }

    private create_arrow_geometry(){
        var geometry = {buffer: this.GL.createBuffer(), length: 0, start: 0, mode: this.GL.TRIANGLES};
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, geometry.buffer);
        let gb = new Float32Array(require("../static/arrow.stl.json").vertices);
        geometry.length = gb.length / 3;
        this.GL.bufferData(this.GL.ARRAY_BUFFER, gb, this.GL.STATIC_DRAW);
        return geometry;
    }

    private create_arrow_normals(){
        var geometry = {buffer: this.GL.createBuffer(), length: 0, start: 0, mode: this.GL.TRIANGLES};
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, geometry.buffer);
        let gb = new Float32Array(require("../static/arrow.stl.json").normals);
        geometry.length = gb.length / 3;
        this.GL.bufferData(this.GL.ARRAY_BUFFER, gb, this.GL.STATIC_DRAW);
        return geometry;
    }

    private create_tracer_geometry(){
        var geometry = {buffer: this.GL.createBuffer(), length: 0, start: 0, mode: this.GL.POINTS};
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, geometry.buffer);
        let gb = new Float32Array(256 * 256 * 2);
        geometry.length = gb.length / 2;
        for(var i = 0; i < 256; i++){
            for(var j = 0; j < 256; j++){
                let index = (i * 256 + j) * 2;
                gb[index] = (1 + 2 * i) / 256 - 1;
                gb[index + 1] = (1 + 2 * j) / 256 - 1;
            }
        }
        this.GL.bufferData(this.GL.ARRAY_BUFFER, gb, this.GL.STATIC_DRAW);
        return geometry;
    }

    private create_line_segment_geometry(){
        var geometry = {buffer: this.GL.createBuffer(), length: 0, start: 0, mode: this.GL.TRIANGLE_STRIP};
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, geometry.buffer);
        const GEO_COUNT = 6;
        let gb = new Float32Array(3 * (GEO_COUNT + 1) * 2);
        for(var i = 0; i < GEO_COUNT + 1; i++){
            gb[(i * 6)] = Math.cos(2 * Math.PI *i / GEO_COUNT);
            gb[(i * 6) + 1] = Math.sin(2 * Math.PI * i / GEO_COUNT);
            gb[(i * 6) + 2] = 0.0;
            gb[(i * 6) + 3] = Math.cos(2 * Math.PI * i / GEO_COUNT);
            gb[(i * 6) + 4] = Math.sin(2 * Math.PI * i / GEO_COUNT);
            gb[(i * 6) + 5] = 1.0;
            geometry.length += 2;
        }
        this.GL.bufferData(this.GL.ARRAY_BUFFER, gb, this.GL.STATIC_DRAW);
        return geometry;
    }

    private create_tracer_line_geometry(){
        var geometry = {buffer: this.GL.createBuffer(), length: 0, start: 0, mode: this.GL.TRIANGLE_STRIP};
        const GEO_COUNT = 6;
        let gb = new Float32Array(3 * 2 * 17 * (GEO_COUNT + 1));
        for(var j = 0; j < 17; j++){
            for(var i = 0; i < GEO_COUNT + 1; i++){
                let a_index = j * GEO_COUNT + i * 6;
                gb[a_index] = Math.cos(2 * Math.PI * i / GEO_COUNT);
                gb[a_index + 1] = Math.sin(2 * Math.PI * i / GEO_COUNT);
                gb[a_index + 2] = j;
                gb[a_index + 3] = Math.cos(2 * Math.PI * i / GEO_COUNT);
                gb[a_index + 4] = Math.sin(2 * Math.PI * i / GEO_COUNT);
                gb[a_index + 5] = j + 1;
                geometry.length += 2;
            }
        }
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, geometry.buffer);
        this.GL.bufferData(this.GL.ARRAY_BUFFER, gb, this.GL.STATIC_DRAW);
        return geometry;
    }

    private create_stitching_pyramid_geometry(){
        var geometry = {buffer: this.GL.createBuffer(), length: 0, start: 0, mode: this.GL.TRIANGLES}
        let gb = new Float32Array([
            //TOP SLOPE
            -1 - STITCHING_PYRAMID_SLOPE_WIDTH, 1 + STITCHING_PYRAMID_SLOPE_WIDTH, 1,
            -1, 1, 0,
            1 + STITCHING_PYRAMID_SLOPE_WIDTH, 1 + STITCHING_PYRAMID_SLOPE_WIDTH, 1,

            -1, 1, 0,
            1, 1, 0,
            1 + STITCHING_PYRAMID_SLOPE_WIDTH, 1 + STITCHING_PYRAMID_SLOPE_WIDTH, 1,
            //LEFT SLOPE
            -1 - STITCHING_PYRAMID_SLOPE_WIDTH, -1 - STITCHING_PYRAMID_SLOPE_WIDTH, 1,
            -1, -1, 0,
            -1 - STITCHING_PYRAMID_SLOPE_WIDTH, 1 + STITCHING_PYRAMID_SLOPE_WIDTH, 1,

            -1, -1, 0,
            -1, 1, 0,
            -1 - STITCHING_PYRAMID_SLOPE_WIDTH, 1 + STITCHING_PYRAMID_SLOPE_WIDTH, 1,
            //ACTUAL TILE
            -1, 1, 0,
            -1, -1, 0,
            1, 1, 0,

            1, 1, 0,
            -1, -1, 0,
            1, -1, 0,
            //RIGHT SLOPE
            1, 1, 0,
            1, -1, 0,
            1 + STITCHING_PYRAMID_SLOPE_WIDTH, -1 - STITCHING_PYRAMID_SLOPE_WIDTH, 1,

            1 + STITCHING_PYRAMID_SLOPE_WIDTH, -1 - STITCHING_PYRAMID_SLOPE_WIDTH, 1,
            1 + STITCHING_PYRAMID_SLOPE_WIDTH, 1+ STITCHING_PYRAMID_SLOPE_WIDTH, 1,
            1, 1, 0,
            //BOTTOM SLOPE
            -1, 1, 0,
            -1 - STITCHING_PYRAMID_SLOPE_WIDTH, -1 -STITCHING_PYRAMID_SLOPE_WIDTH, 1,
            1, -1, 0,

            1, -1, 0,
            -1 - STITCHING_PYRAMID_SLOPE_WIDTH, -1 - STITCHING_PYRAMID_SLOPE_WIDTH, 1,
            1 + STITCHING_PYRAMID_SLOPE_WIDTH, -1 - STITCHING_PYRAMID_SLOPE_WIDTH, 1

        ]);
        geometry.length = gb.length / 3;
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, geometry.buffer);
        this.GL.bufferData(this.GL.ARRAY_BUFFER, gb, this.GL.STATIC_DRAW);
        return geometry;
    }
}