import { create } from "@/d3";
import { EARTH_RADIUS } from "@/modules/rendersources/RenderSource";
import { Coord, UEC } from "@/modules/tile";
import { Vec2, Vec3 } from "@/modules/vecmat";
import { FormattingService } from "./FormattingService";
import { ScreenPosition } from "./PositionService";
import { Services } from "./Services";
import {screen_pos_of_uec_and_height_earth_radii, create_svg_line, create_svg_text, move_svg_line, move_svg_text} from "./ScaleIndicatorService"

function pos_height_to_geocentric(pos: UEC, height: number): Vec3{
    let coord = Coord.from_UEC(pos);
    let lat = coord.lat / 180.0 * Math.PI;
    let lon = coord.lon / 180.0 * Math.PI;
    let r = height + EARTH_RADIUS;
    return new Vec3(
            r * Math.cos(lat) * Math.cos(lon),
            r * Math.cos(lat) * Math.sin(lon),
            r * Math.sin(lat),
    );
}

function euclidean_distance(pos1: UEC, height1: number, pos2: UEC, height2: number): number{
    let geo_coord1 = pos_height_to_geocentric(pos1, height1);
    let geo_coord2 = pos_height_to_geocentric(pos2, height2);
    return geo_coord1.sub(geo_coord2).abs();
}

export class MeasurementService{
    measurement_visible: boolean = false;
    start_uec: UEC;
    end_uec: UEC;
    start_height: number;
    end_height: number;
    start_pixels: Vec2;
    end_pixels: Vec2;

    measure_line_black: SVGLineElement;
    measure_line_white: SVGLineElement;
    measure_label: SVGTextElement;

    svg_element: HTMLElement;
    center_circle: SVGCircleElement;

    constructor(){
        this.svg_element = document.getElementById("measure-svg");
        this.measure_line_black = create_svg_line(this.svg_element, "rgba(0.0,0.0,0.0,0.8)",  "6px", "measureline_black");
        this.measure_line_white = create_svg_line(this.svg_element, "rgba(255,255,255,0.8)",  "3px", "measureline_white");
        this.measure_line_black.style.strokeLinecap = "round";
        this.measure_line_white.style.strokeLinecap = "round";
        this.measure_line_white.setAttributeNS(null, "stroke-dasharray", "3,7");
        this.measure_label = create_svg_text(this.svg_element, "", "rgba(0.0,0.0,0.0,0.8)", "measurelabel");
        this.measure_label.style.fill = "rgba(255,255,255,1)";
        this.measure_label.style.fontWeight = "bold";
        this.measure_label.style.paintOrder = "stroke";
        this.measure_label.setAttributeNS(null, "stroke-width", "4px");
        this.set_visibility(false);
        Services.GLService.addEventListener("FrameDone", () => {
            if(!this.measurement_visible){
                return;
            }
            if (this.start_uec != null && this.end_uec != null){
                let s = Services.RenderLayerService.getSelectedLayer()?.source;
                if(!s)return;
                
                this.start_pixels = screen_pos_of_uec_and_height_earth_radii(this.start_uec, s.applyScaling(this.start_height));
                this.end_pixels = screen_pos_of_uec_and_height_earth_radii(this.end_uec, s.applyScaling(this.end_height));
                move_svg_line(this.measure_line_black, this.start_pixels, this.end_pixels);
                move_svg_line(this.measure_line_white, this.start_pixels, this.end_pixels);
                let len = FormattingService.num_to_string_unit_si(euclidean_distance(this.start_uec, this.start_height, this.end_uec, this.end_height), "m", 3);
                this.measure_label.textContent = len;
                move_svg_text(this.measure_label, this.end_pixels.add(new Vec2(20, 20)));
                //console.log(this.start_pixels, this.end_pixels);
            }
        });
        Services.RenderLayerService.addEventListener("SelectedLayerChanged", () => {
            this.set_visibility(false);
        });
    }

    public set_start(){
        this.set_visibility(true);
        this.start_uec = Services.LayerMetadataService.coord;
        this.start_height = Services.LayerMetadataService.z;
        this.set_end()
    }

    public set_end(){
        this.end_uec = new UEC(Services.LayerMetadataService.coord.x, Services.LayerMetadataService.coord.y);
        this.end_height = Services.LayerMetadataService.z;
    }

    

    set_visibility(visible: boolean){
        this.measurement_visible = visible;
        if(visible){
            this.svg_element.style.opacity="1";
        }else{
            this.svg_element.style.opacity="0";
            //this.start_uec = null;
            //this.end_uec = null;
        }
    }
}