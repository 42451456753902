//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { Services, ServiceBarrier } from './Services';
import { LayersChangedEvent } from './RenderLayerService';
import { KeyDownEvent } from './InteractionService';
import { SourceLayerInfo } from './SourceInfoService';
import { map } from '@/d3';

export class GlobalTimeRangeChangedEvent extends Event{
    public globalMin: number;
    public globalMax: number;
    public renderLayerRanges: {name: string, min: number, max: number}[];
    constructor(min: number, max: number, rlr: {name: string, min: number, max: number}[]){
        super("GlobalTimeRangeChanged");
        this.globalMin = min;
        this.globalMax = max;
        this.renderLayerRanges = rlr;
    }
}

export class CurrentTimeChangedEvent extends Event{
    public time_min: number;
    public time_max: number;
    /** True if the time change came from outside the application (via sync)    */
    public external: boolean;
    /** True if the time change was a result of a drag of the timeslider */
    public secondary: boolean;
    constructor(min: number, max: number, secondary = false, external = false){
        super("CurrentTimeChanged");
        this.time_min = min;
        this.time_max = max;
        this.external = external;
        this.secondary = secondary;
    }
}

const TIMESTEP_COUNT = 1024;

export class TimeService extends EventTarget{

    render_layer_ranges: {name: string, min: number, max: number}[] = [];
    overall_range: [number, number] = [Infinity, -Infinity];
    value_min: number = 0;
    value_max: number = 0;

    constructor(){
        super();
        ServiceBarrier.wait().then(() => {
            Services.RenderLayerService.addEventListener("LayersChanged", (e: LayersChangedEvent) => {
                this.render_layer_ranges = Services.RenderLayerService.get_visible_renderlayers().map((l) => {
                    let range = l.getTimeRange();
                    if(!range) range = [Infinity, -Infinity];
                    return {name: l.name, min: range[0], max: range[1], layer: l};
                });
                this.overall_range = this.render_layer_ranges.reduce((a, v) => [Math.min(v.min, a[0]), Math.max(v.max, a[1])], [Infinity, -Infinity]);
                if(!isFinite(this.overall_range[0]) || !isFinite(this.overall_range[1]))this.overall_range = [0, 0];
                this.value_min = Math.max(this.overall_range[0], this.value_min);
                this.value_max = Math.max(this.overall_range[0], this.value_max);
                this.value_min = Math.min(this.overall_range[1], this.value_min);
                this.value_max = Math.min(this.overall_range[1], this.value_max);
                this.dispatchEvent(new GlobalTimeRangeChangedEvent(this.overall_range[0], this.overall_range[1], this.render_layer_ranges));
            });
            Services.InteractionService.keyDownHandlers.set("threedee", (e: KeyboardEvent) => {
                switch(e.key){
                    case "Home":
                        Services.PlaybackService.stop_playing();
                        this.moveTimeStart();
                        Services.PlaybackService.begin_playing();
                        break;
                    case "ArrowLeft":
                        this.moveTimeEarlier();
                        break;
                    case "ArrowRight":
                        this.moveTimeLater();
                        break;
                    case "End":
                        Services.PlaybackService.stop_playing();
                        this.moveTimeEnd();
                        break;
                    case "ArrowUp":
                        Services.PlaybackService.stop_playing();
                        this.increaseTimeRange();
                        Services.PlaybackService.begin_playing();
                        break;
                    case "ArrowDown":
                        Services.PlaybackService.stop_playing();
                        this.reduceTimeRange();
                        Services.PlaybackService.begin_playing();
                        break;
                    case "Space":
                        Services.PlaybackService.togglePlaying();
                        break;
                }
            });
        });
    }

    reset(){
        this.setCurrentTimeRange(this.overall_range[0], this.overall_range[0]);
    }

    moveTimeEarlier(){
        let time_distance = this.value_max - this.value_min;
        let time_range_step_amount = (this.overall_range[1] - this.overall_range[0]) / TIMESTEP_COUNT;
        let time_step_min_new = Math.max((this.value_min - time_range_step_amount), this.overall_range[0]);
        let time_step_max_new = time_step_min_new + time_distance;
        this.setCurrentTimeRange(time_step_min_new, time_step_max_new);
    }

    moveTimeLater(){
        let time_distance = this.value_max - this.value_min;
        let time_range_step_amount = (this.overall_range[1] - this.overall_range[0]) / TIMESTEP_COUNT;
        let time_step_max_new = Math.min((this.value_max + time_range_step_amount), this.overall_range[1]);
        let time_step_min_new = time_step_max_new - time_distance;
        this.setCurrentTimeRange(time_step_min_new, time_step_max_new);
    }

    moveTimeStart(){
        let time_distance = this.value_max - this.value_min;
        this.setCurrentTimeRange(this.overall_range[0], this.overall_range[0] + time_distance);
    }

    moveTimeEnd(){
        let time_distance = this.value_max - this.value_min;
        this.setCurrentTimeRange(this.overall_range[1] - time_distance, this.overall_range[1]);
    }

    increaseTimeRange(){
        let time_distance = this.value_max - this.value_min;
        let time_range_step_amount = (this.overall_range[1] - this.overall_range[0]) / TIMESTEP_COUNT;
        let time_distance_new = Math.min(time_distance + time_range_step_amount, this.overall_range[1] - this.overall_range[0]);
        let time_center = this.getMeanTime();
        let time_min_new = time_center - (time_distance_new / 2);
        let time_max_new = time_center + (time_distance_new / 2);
        if(time_min_new < this.overall_range[0]){
            let distance = this.overall_range[0] - time_min_new;
            time_min_new += distance;
            time_max_new += distance;
        }
        if(time_max_new > this.overall_range[1]){
            let distance = time_max_new - this.overall_range[1];
            time_max_new -= distance;
            time_min_new -= distance;
        }
        this.setCurrentTimeRange(time_min_new, time_max_new);
    }

    reduceTimeRange(){
        let time_distance = this.value_max - this.value_min;
        let time_range_step_amount = (this.overall_range[1] - this.overall_range[0]) / TIMESTEP_COUNT;
        let time_distance_new = Math.max(time_distance - time_range_step_amount, 0);
        let time_center = this.getMeanTime();
        this.setCurrentTimeRange(time_center - (time_distance_new / 2), time_center + (time_distance_new / 2));
    }

    getMeanTime(): number{
        return (this.value_max + this.value_min) / 2;
    }

    setCurrentTimeRange(min: number, max: number, secondary = false, external = false){
        let real_min = Math.min(min, max);
        let real_max = Math.max(min, max);
        this.value_min = real_min;
        this.value_max = real_max;
        Services.AdaptivePerformanceService.RequestRerender();
        this.dispatchEvent(new CurrentTimeChangedEvent(this.value_min, this.value_max, secondary, external));
        //this.dispatchEvent(new GlobalTimeRangeChangedEvent(this.overall_range[0], this.overall_range[1], this.render_layer_ranges));
    }

    getOverallTimeRange(): [number, number]{
        return [this.overall_range[0], this.overall_range[1]];
    }

    getCurrentTimeRange(): [number, number]{
        return [this.value_min, this.value_max];
    }

    getTimeTileForLayer(selection: [number, number], layer: SourceLayerInfo): number[]{
        if(!selection || layer.layer.timerange == null){
            return [0];
        }

        selection[1] = Math.min(selection[1], layer.layer.timerange[1]);
        selection[0] = Math.max(selection[0], layer.layer.timerange[0]);

        function split_3(time: [number, number]): [[number, number],[number, number],[number, number]] {
            let range = time[1] - time[0];
            let one_third = range / 3;
            let first:[number, number] = [time[0], time[0] + one_third];
            let second:[number, number] = [first[1], first[1] + one_third];
            let third:[number, number] = [second[1], time[1]];
            return [first, second, third]
        }

        function range_len(range: [number, number]): number{
            return range[1] - range[0];
        }

        function range_contains(outer: [number, number], inner: [number, number]): boolean{
            return outer[0] <= inner[0] && outer[1] >= inner[1];
        }

        function range_intersects(a: [number, number], b: [number, number]): boolean{
            return (a[1] > b[0]) && (a[0] < b[1])
        }

        function encode_timetile(selection: [number, number], total: [number, number], iteration_limit: number): number{
            let selected_range = total;
            let path = 0;
            for(let i = 0; i < iteration_limit; i++){
                if(range_len(selected_range) < 3){
                    return path;
                }
                let ranges = split_3(selected_range)
                let index = ranges.findIndex((subrange, index) => range_contains(subrange, selection));
                let range = ranges[index];
                if(index != -1){
                    selected_range = range;
                    path += (index + 1) * Math.pow(4,i);
                }else{
                    return path;
                }
            }
            return path
        }

        function get_timetiles_by_limit(selection: [number, number], total: [number, number], iteration_limit: number, tilecount_limit: number): number[]{
            let ranges: {range: [number, number], path: number}[] = [{range: total, path: 0}];
            for(let i = 0; i < iteration_limit; i++){
                let new_ranges = ranges.map(r => {
                    let split = split_3(r.range).map((range, index) => ({range, index}));
                    split = split.filter(sr => range_len(sr.range) > 3 && range_intersects(selection, sr.range));
                    let mapped = split.map(sr => ({range: sr.range, path: r.path + (sr.index + 1 * Math.pow(4,i))}));
                    return mapped;
                }).flat();
                
                if(new_ranges.length == 0){//No ranges fit
                    break;
                }else if(new_ranges.length > tilecount_limit){ //More ranges than we want
                    //console.log("new ranges too much", new_ranges);
                   break;
                }else{
                    ranges = new_ranges;
                }
            }
            return ranges.map(r => r.path);
        }

        let max_time_level = layer.layer.get_parameter_int("max_time_level") || 1;

        //let path = encode_timetile(selection, layer.layer.timerange, max_time_level);
        /*if(path != 0){
            console.log("Path: ", path);
        }*/
        //return [path];
        let paths = get_timetiles_by_limit(selection, layer.layer.timerange, max_time_level, 2);
        //console.log("Paths", paths);
        if(paths.length == 0){
            return [0];
        }else{
            return paths;
        }
    }
}